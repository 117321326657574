import { Component,OnInit } from '@angular/core';
import { PresumptiveCumulativeDto } from '../../../../dto/presumptive-cumulative-dto';
import { ReportService } from '../../../../service/report.service';
import { GeneralUtil } from '../../../../util/general-util';
import { State } from '../../../../entity/state';
import { UserService } from '../../../../service/user.service';
import { LocationService } from '../../../../service/location.service';
import { FilterReportsDto } from '../../../../dto/filter-reports-dto';
import { Facility } from 'src/app/entity/facility';


@Component({
  selector: 'app-facility-presumptive-cumulative-reports',
  templateUrl: './facility-presumptive-cumulative-reports.component.html',
  styleUrls: ['./facility-presumptive-cumulative-reports.component.css']
})
export class FacilityPresumptiveCumulativeReportsComponent implements OnInit {
  public showPreloader = false;
  public presumptiveCumulative = new PresumptiveCumulativeDto();
  public facilities: Facility[] = [];
  public filterReports = new FilterReportsDto();

  constructor(
    public reportService: ReportService,
    public userService: UserService,
    public locationService: LocationService
  ) { }

  ngOnInit(): void {
    this.getCumulativeReportForObjective1(this.filterReports.endDate,this.filterReports.startDate);
    this.getFacilitiesForFacilityAdmin()
  }

  clearFilter() {
    this.filterReports = new FilterReportsDto();
    this.getCumulativeReportForObjective1(this.filterReports.endDate,this.filterReports.startDate);
  }

  getFacilitiesForFacilityAdmin() {
    this.facilities = [];
    this.locationService.getFacilitiesForFacilityAdmin()
      .subscribe(data => {
        const facilityArray = data;
        if (facilityArray.length > 0) {
          facilityArray.forEach(f => {
            const facil = new Facility();
            facil.mapToFacility(f);
            this.facilities.push(facil);
          });
        }
      },error1 => {
        this.errorHandler(error1);
      })
  }


  getCumulativeReportForObjective1(endDate,startDate) {
    this.showPreloader = true;
    this.reportService.facilityCumulativeReportForObjective1(endDate,startDate,this.filterReports.selectedFacility)
      .subscribe((data) => {
        this.showPreloader = false;
        return this.presumptiveCumulative.mapToDto(data);
      },
        (error) => {
          this.showPreloader = false;
          this.errorHandler(error);
        });
  }

  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }

}