import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../../../service/report.service';
import { GeneralUtil } from '../../../../util/general-util';
import { LtfuCumulativeDto } from '../../../../dto/ltfu-cumulative-dto';
import { State } from '../../../../entity/state';
import { UserService } from '../../../../service/user.service';
import { LocationService } from '../../../../service/location.service';
import { FilterReportsDto } from '../../../../dto/filter-reports-dto';
import { Facility } from 'src/app/entity/facility';

@Component({
  selector: 'app-facility-ltfu-cumulative-reports',
  templateUrl: './facility-ltfu-cumulative-reports.component.html',
  styleUrls: ['./facility-ltfu-cumulative-reports.component.css']
})
export class FacilityLtfuCumulativeReportsComponent implements OnInit {

  public filterDto = new FilterReportsDto();
  public showPreloader = false;
  public ltfuCumulative = new LtfuCumulativeDto();
  public facilities: Facility[] = [];

  constructor(
    public reportService: ReportService,
    public userService: UserService,
    public locationService: LocationService
  ) { }


  ngOnInit(): void {
    this.getCumulativeReportForObjective3(this.filterDto.endDate, this.filterDto.startDate);
    this.getFacilitiesForFacilityAdmin();
  }

  clearFilter() {
    this.filterDto = new FilterReportsDto();
    this.getCumulativeReportForObjective3(this.filterDto.endDate, this.filterDto.startDate);
  }

  getFacilitiesForFacilityAdmin() {
    this.facilities = [];
    this.locationService.getFacilitiesForFacilityAdmin()
      .subscribe(data => {
        const facilityArray = data;
        if (facilityArray.length > 0) {
          facilityArray.forEach(f => {
            const facil = new Facility();
            facil.mapToFacility(f);
            this.facilities.push(facil);
          });
        }

      }, error1 => {
        this.errorHandler(error1);
      })
  }

  getCumulativeReportForObjective3(endDate, startDate) {
    this.showPreloader = true;
    this.reportService.facilityCumulativeReportForObjective3(endDate, startDate, this.filterDto.selectedFacility)
      .subscribe((data) => {
        this.showPreloader = false;
        return this.ltfuCumulative.mapToDto(data);
      },
        (error) => {
          this.showPreloader = false;
          this.errorHandler(error);
        });
  }

  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }

}
