import { GeneralUtil } from '../util/general-util';

export class LtfuCumulativeDto2 {
  public totalPatients: number;
  public totalUssdActivation: number;
  public totalBarriersElicited: number;
  public totalNumbersContacted: number;
  public totalPatientsOnTreatment: number;
  public totalPatientsEvaluated: number;

  constructor() { }

  public mapToLtfuDto2(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.totalPatients = data.totalPatients.toLocaleString();
      this.totalUssdActivation = data.totalUssdActivation.toLocaleString();
      this.totalBarriersElicited = data.totalBarriersElicited.toLocaleString();
      this.totalNumbersContacted = data.totalNumbersContacted.toLocaleString();
      this.totalPatientsOnTreatment = data.totalPatientsOnTreatment.toLocaleString();
      this.totalPatientsEvaluated = data.totalPatientsEvaluated.toLocaleString();
    }
  }


}
