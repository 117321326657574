import { Component,OnInit,ViewChild,ViewChildren,QueryList,ElementRef,} from '@angular/core';
import { UserDto } from './../../../../dto/user-dto';
import { GeneralUtil } from './../../../../util/general-util';
import { User } from './../../../../entity/user';
import { UserService } from './../../../../service/user.service';
import { RoleEnum } from './../../../../constant/role-enum';
import { Facility } from './../../../../entity/facility';
import { State } from './../../../../entity/state';
import { Role } from './../../../../entity/role';
import { LocationService } from '../../../../service/location.service';
import { PaginationDataDto } from '../../../../dto/pagination-data-dto';
import { GeneralEnum } from '../../../../constant/general-enum.enum';

declare function successToast(msg): any;
declare function failureToast(msg): any;

@Component({
  selector: 'app-facility-level-admin',
  templateUrl: './facility-level-admin.component.html',
  styleUrls: ['./facility-level-admin.component.css']
})
export class FacilityLevelAdminComponent implements OnInit {
  public users: User[] = [];
  public viewUser = new User();
  public showPreloader = false;
  public facilities: Facility[] = [];
  public states: State[] = [];
  public userDto = new UserDto();
  public role = new Role();
  public selectedFacilities = [];
  public paginationData = new PaginationDataDto();  // pagination object
  public currentPage: number;
  public defaultLimit: number;

  @ViewChild('close') close: ElementRef;
  @ViewChild('closeModal') closeModal: ElementRef;
  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;


  constructor(public userService: UserService,
    public locationService: LocationService) { }

  ngOnInit(): void {
    this.initializePaginationData();
    this.getFacilityAdmin();
    this.getFacilities();
    this.getAllStates();
    this.getRoles();
  }

  initializePaginationData() {
    this.paginationData.currentPage = 1;
    this.paginationData.defaultLimit = GeneralEnum.paginationLimit;
    this.paginationData.totalSize = 100;
  }

  removeUnderscore(mystring: string): string {
    return GeneralUtil.removeUnderscore(mystring)
  }

  getFacilities() {
    this.facilities = [];
    this.locationService.getAllFacilities()
      .subscribe(data => {
        const facilityArray = data;
        if (facilityArray.length > 0) {
          facilityArray.forEach(f => {
            const fac = new Facility();
            fac.mapToFacility(f);
            this.facilities.push(fac);
          });
        }
      },error1 => {
        this.errorHandler(error1);
      })
  }

  getAllStates() {
    this.states = [];
    this.locationService.getActiveStates()
      .subscribe(data => {
        const stateArray = data;
        if (stateArray.length > 0) {
          stateArray.forEach(s => {
            const sta = new State();
            sta.mapToState(s);
            this.states.push(sta);
          });
        }
      },error1 => {
        this.errorHandler(error1);
      })
  }

  getFacilityAdmin() {
    this.defaultLimit = this.paginationData.defaultLimit;
    this.currentPage = this.paginationData.currentPage;
    this.showPreloader = true;
    this.userService.getUsers(RoleEnum.FACILITY_LEVEL_ADMIN,(this.currentPage - 1),this.defaultLimit)
      .subscribe(data => {
        this.showPreloader = false;
        this.users = [];
        this.paginationData.totalSize = data.total;
        if (data.total > 0) {
          const facilityUserArray = data.entities;
          facilityUserArray.forEach(r => {
            const state = new User();
            state.mapToUser(r);
            this.users.push(state);
          });
          return this.users;
        }
      },error1 => {
        this.showPreloader = false;
        this.errorHandler(error1);
      });
  }

  getRoles() {
    this.role = new Role();
    this.userService.getAllRoles()
      .subscribe(data => {
        const roleArray = data;
        if (roleArray.length > 0) {
          this.role = roleArray.find(role => {
            return role.name == RoleEnum.FACILITY_LEVEL_ADMIN;
          })
          this.userDto.roleId = this.role.id;
        }
      },error1 => {
        this.errorHandler(error1);
      })
  }

  createUser() {
    if (this.userDto.facilityIdsForFacilityAdmin.length < 1) {
      failureToast('At least one facility is required');
    } else {
      this.showPreloader = true;
      this.userService.createUser(this.userDto)
        .subscribe(data => {
          successToast('User created successfully');
          setTimeout(() => {
            this.showPreloader = false;
            this.userDto = new UserDto();
            this.close.nativeElement.click();
            this.checkboxes.forEach((element) => {
              element.nativeElement.checked = false;
            });
            this.userDto.roleId = this.role.id;
            this.getFacilityAdmin();
          },1000)
        },error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
    }
  }

  getSingleUser(userId: number) {
    this.selectedFacilities = []

    this.userService.getUserById(userId)
      .subscribe(data => {
        this.viewUser = new User();
        setTimeout(() => {
          this.viewUser.mapToUser(data);
        },1000)
      },error1 => {
        this.errorHandler(error1);
      })
  }

  //  Edit user details
  editUser(userId: number) {
    this.selectedFacilities = [];
    this.userService.getUserById(userId)
      .subscribe(data => {
        this.viewUser.mapToUser(data);
        // get facilities that are checked
        this.viewUser.facilityIdsForFacilityAdmin.forEach(f => {
          if (!this.selectedFacilities.includes(f.id)) {
            this.selectedFacilities.push(f.id)
          }
        });
        this.facilities.forEach(facility => this.viewUser.facilityIdsForFacilityAdmin.forEach(f => {
          if (f.id === facility.id) {
            facility.selected = true;
          }
        }))
      },error1 => {
        this.errorHandler(error1);
      })
  }


  // Update user's information
  updateUser() {
    this.viewUser.facilityIdsForFacilityAdmin = this.selectedFacilities;

    if (this.viewUser.facilityIdsForFacilityAdmin.length < 1) {
      failureToast('At least one facility is required');
    } else {
      this.showPreloader = true;
      this.userService.updateUser(this.viewUser)
        .subscribe(data => {
          successToast('User updated successfully');
          setTimeout(() => {
            this.showPreloader = false;
            this.closeModal.nativeElement.click();
            this.getFacilityAdmin();
          },1000)

        },error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        })
    }
  }

  onCheck(event) {
    // selected checkbox
    if (event.checked) {
      if (this.userDto.facilityIdsForFacilityAdmin.includes(Number(event.value)) === false) {
        this.userDto.facilityIdsForFacilityAdmin.push(Number(event.value))
      }
      // unselected checkbox
    } else {
      this.userDto.facilityIdsForFacilityAdmin.forEach(f => {
        if (f == event.value) {
          const index = this.userDto.facilityIdsForFacilityAdmin.indexOf(f);
          if (index > -1) {
            this.userDto.facilityIdsForFacilityAdmin.splice(index,1);
          }
        }
      });
    }
  }

  numbersOnly(event) {
    return GeneralUtil.numbersOnly(event);
  }

  onCheckBoxChange(event,facility) {
    // selected checkboxes
    if (event.checked) {
      facility.selected = true;
      if (this.selectedFacilities.includes(Number(event.value)) === false) {
        this.selectedFacilities.push(Number(event.value))
      }
      // unselected checkboxes
    } else {
      facility.selected = false;
      this.selectedFacilities.forEach(f => {
        if (f == event.value) {
          const index = this.selectedFacilities.indexOf(f);
          if (index > -1) {
            this.selectedFacilities.splice(index,1)
          }
        }
      })
    }
  }

  toggleUserStatus(id,status: string) {
    this.showPreloader = true;
    if (status == 'ACTIVE') {
      status = 'INACTIVE'
    } else {
      status = 'ACTIVE'
    }
    this.userService.toggleUserStatus(id,status)
      .subscribe(data => {
        this.showPreloader = false;
        successToast('User status updated successfully');
        this.getFacilityAdmin();
      },
        error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        })
  }


  // Error handling
  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
