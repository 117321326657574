import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../../../service/report.service';
import { GeneralUtil } from '../../../../util/general-util';
import { InCareCumulativeDto } from '../../../../dto/in-care-cumulative-dto';
import { State } from '../../../../entity/state';
import { UserService } from '../../../../service/user.service';
import { LocationService } from '../../../../service/location.service';
import { FilterReportsDto } from '../../../../dto/filter-reports-dto';
import { Facility } from 'src/app/entity/facility';

declare function successToast(msg): any;


@Component({
  selector: 'app-facility-in-care-cumulative-reports',
  templateUrl: './facility-in-care-cumulative-reports.component.html',
  styleUrls: ['./facility-in-care-cumulative-reports.component.css']
})
export class FacilityInCareCumulativeReportsComponent implements OnInit {
  public showPreloader = false;
  public inCareCumulativeDto = new InCareCumulativeDto();
  public filterReports = new FilterReportsDto();
  public facilities: Facility[] = [];

  constructor(
    public reportService: ReportService,
    public userService: UserService,
    public locationService: LocationService
  ) { }

  ngOnInit(): void {
    this.getCumulativeReportForObjective2(this.filterReports.endDate, this.filterReports.startDate);
    this.getFacilitiesForFacilityAdmin();
  }

  getFacilitiesForFacilityAdmin() {
    this.facilities = [];
    this.locationService.getFacilitiesForFacilityAdmin()
      .subscribe(data => {
        const facilityArray = data;
        if (facilityArray.length > 0) {
          facilityArray.forEach(f => {
            const facil = new Facility();
            facil.mapToFacility(f);
            this.facilities.push(facil);
          });
        }

      }, error1 => {
        this.errorHandler(error1);
      })
  }

  clearFilter() {
    this.filterReports = new FilterReportsDto();
    this.getCumulativeReportForObjective2(this.filterReports.endDate, this.filterReports.startDate);
  }

  getCumulativeReportForObjective2(endDate, startDate) {
    this.showPreloader = true;
    this.reportService.facilityCumulativeReportForObjective2(endDate, this.filterReports.selectedFacility, startDate)
      .subscribe((data) => {
        this.showPreloader = false;
        return this.inCareCumulativeDto.mapToDto(data);
      },
        (error) => {
          this.showPreloader = false;
          this.errorHandler(error);
        });
  }

  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }

}
