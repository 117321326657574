import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../service/auth/auth.service';
import { PatientStatusEnum } from './../../../constant/patient-status-enum';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {

  public patientStatus = PatientStatusEnum;

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
  }

}
