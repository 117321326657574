<div class="main-content">
  <div class="content-body">
    <div class="container-fluid">

      <div class="row page-titles pt-2 mx-0">
        <div class="col-sm-6 p-md-0">
          <div class="welcome-text">
            <h4>In-Care USSD Reports</h4>
          </div>
        </div>
      </div>


      <div class="row mx-0">
          <div class="form-group col-md-2 mb-3 p-2">
            <label for="state" class="form-label pl-2">State</label>
            <div id="state">
              <select name="states" class="form-select" id="states"
                      [(ngModel)]="filterReports.selectedState" (change)="changeFacility()" required>
                <option value="" selected >All States</option>
                <option *ngFor="let state of states" value="{{state.name}}">{{state.name}}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group col-md-2 mb-3 p-2">
            <label for="facilities" class="form-label pl-2">Facility</label>
            <div id="facilities">
              <select name="facility" class="form-select" id="facilities1"
                      [(ngModel)]="filterReports.selectedFacility" required>
                <option value="" selected>All Facilities</option>
                <option *ngFor="let facility of facilities" value="{{facility.id}}">{{facility.name}}
                </option>
              </select>
            </div>
          </div>


          <div class="form-group col-md-3 mb-3 p-2">
            <label for="start-date-input" class="form-label pl-2">Start Date</label>
            <div>
              <input [(ngModel)]="filterReports.startDate"  class="form-control"
                     type="date" value="{{filterReports.startDate}}" id="start-date-input">
            </div>
          </div>

          <div class="form-group col-md-5 mb-3 p-2">
            <label for="end-date-input" class="form-label pl-2">End Date</label>
            <div class="d-flex">
              <input [(ngModel)]="filterReports.endDate" min="{{filterReports.startDate}}"
                class="form-control mr-3" type="date" value="{{filterReports.endDate}}" id="end-date-input">
              <a (click)="getUssdReportForObjective2(this.filterReports.endDate, this.filterReports.startDate)"
                class="btn btn-ddanger text-white transition-3d-hover hover-shadow-danger mr-1 px-3">Filter</a>
              <a (click)="clearFilter()" class="btn btn-outline-danger
                            transition-3d-hover hover-shadow-danger">Clear Filter</a>
            </div>
          </div>

        <!-- </div> -->

      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card border-top border-gray-200 pt-md-4">
            <div class="card-header d-flex justify-content-between">
              <h5 class="font-weight-semibold opensans-font mt-3 pb-md-4">Routine Report</h5>
              <div class="input-group w-50">
                <input type="tel" maxlength="13" class="form-control" placeholder="Search by phone number" [(ngModel)]="filterReports.phoneNumber"
                  value="{{filterReports.phoneNumber}}" (keypress)="numbersOnly($event)"
                  (keyup.enter)="getUssdReportForObjective2('', '')"
                  (blur)="getUssdReportForObjective2('', '')">
                <div class="input-group-append" style="height: 41px;">
                  <button class="btn btn-ddanger" type="button"
                    (click)="getUssdReportForObjective2('', '')">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search" width="20" height="20"
                      viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="10" cy="10" r="7" />
                      <line x1="21" y1="21" x2="15" y2="15" />
                    </svg>
                  </button>
                </div>
              </div>
              <a (click)="exportToCsv()" class="btn btn-outline-danger mr-2 transition-3d-hover hover-shadow-danger">Export CSV</a>
            </div>
            <div class="card-body">
              <div style="text-align: center; margin: 0 ;" *ngIf="inCareUssdArray.length < 1 && showPreloader === false">
                <img src="assets/images/empty-data.png" height="200px">
                <div>NO DATA AVAILABLE</div>
              </div>

              <div class="table-responsive">
                <div *ngIf="showPreloader" class="card-body d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <table class="table table-borderless card-table table-nowrap">
                  <thead *ngIf="inCareUssdArray.length > 0">
                  <tr>
                    <th><strong>#</strong></th>
                    <th><strong>DATE DIALLED</strong></th>
                    <th><strong>PHONE NUMBER</strong></th>
                    <th><strong>GENDER</strong></th>
                    <th><strong>AGE</strong></th>
                    <th><strong>STATE</strong></th>
                    <th><strong>LGA</strong></th>
                    <th><strong>FACILITY</strong></th>
                    <th (click)="sortOrders()">
                      <svg *ngIf="sortReverse" xmlns="http://www.w3.org/2000/svg"
                           class="icon icon-tabler icon-tabler-caret-up" width="20" height="20" viewBox="0 0 24 24"
                           stroke-width="1.5" stroke="#dc3545" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M18 15l-6 -6l-6 6h12" />
                      </svg>
                      <svg *ngIf="!sortReverse" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-down" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#dc3545" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M18 15l-6 -6l-6 6h12" transform="rotate(180 12 12)" />
                      </svg>
                      <strong>ADHERENCE TO MEDICATION?</strong></th>
                    <th><strong>BARRIERS TO ADHERENCE</strong></th>
                  </tr>
                  </thead>
                  <tbody *ngIf="exporting === false">
                  <tr *ngFor="let ussdArray of inCareUssdArray | slice: (page-1) * pageSize : page * pageSize; index as i">
                    <td><strong>{{i + 1}}</strong></td>
                    <td>{{ussdArray.timeStamp}}</td>
                    <td>{{ussdArray.patientNumber}}</td>
                    <td>{{ussdArray.gender}}</td>
                    <td>{{ussdArray.age}}</td>
                    <td>{{ussdArray.state}}</td>
                    <td>{{ussdArray.lga}}</td>
                    <td>{{ussdArray.facility}}</td>
                    <td>{{ussdArray.feedback}}</td>
                    <td>{{ussdArray.barrier}}</td>
                  </tr>
                  </tbody>

                  <tbody *ngIf="exporting === true">
                    <tr *ngFor="let ussdArray of inCareUssdArray; index as i">
                      <td><strong>{{i + 1}}</strong></td>
                      <td>{{ussdArray.timeStamp}}</td>
                      <td>{{ussdArray.patientNumber}}</td>
                      <td>{{ussdArray.gender}}</td>
                      <td>{{ussdArray.age}}</td>
                      <td>{{ussdArray.state}}</td>
                      <td>{{ussdArray.lga}}</td>
                      <td>{{ussdArray.facility}}</td>
                      <td>{{ussdArray.feedback}}</td>
                      <td>{{ussdArray.barrier}}</td>
                    </tr>
                    </tbody>
                </table>
              </div>

              <div  *ngIf="exporting === false" class="mt-4">
                <ngb-pagination (pageChange)="page = $event" [(page)]="page" [pageSize]="pageSize"
                  [collectionSize]="inCareUssdArray.length">
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
