<div class="main-content">
  <div class="content-body">
    <div class="container-fluid">

      <div class="row page-titles pt-2 mx-0">
        <div class="col-sm-6 p-md-0">
          <div class="welcome-text">
            <h4>Patient Line List</h4>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card border-top border-gray-200 pt-md-4">
            <form #uploadExcel="ngForm">
              <div class="card-header d-flex justify-content-between">
                <h5 class="font-weight-semibold opensans-font mt-3 pb-md-4 mx-auto">Select the
                  Patient List
                  to
                  Upload</h5>
              </div>
              <div class="row pt-4">
                <div class="row mx-0 col-md-6">
                  <div class="form-group">
                    <div id="patient">
                      <select name="patient" aria-label="Default select example" #patient="ngModel"
                        [(ngModel)]="selectedTemplate" class="form-select" id="patient"
                        (change)="changeTemplate()" required>
                        <option value="">Select a list type</option>
                        <option *ngFor="let patient of patientList" value="{{patient.id}}">
                          {{patient.name}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row mx-0 col-md-6">
                  <div class="form-group">
                    <div class="input-group">
                      <input type="file" name="file" required #file ngModel
                        [disabled]="!patient.valid" (change)="onFileSelect($event)"
                        accept=".xlsx" class="form-control" id="inputGroupFile04"
                        aria-describedby="inputGroupFileAddon04" aria-label="Upload">
                      <button [disabled]="!uploadExcel.valid" (click)="submitForm()"
                        class="btn btn-danger" type="button" id="inputGroupFileAddon04">Upload
                        File</button>
                        <div *ngIf="showPreloader" class="d-flex justify-content-center">
                        <div class="spinner-border">
                          <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row p-1">


                <!-- Download link -->
                <a *ngIf="selectedTemplate == '1'" [ngStyle]="{display: 'none'}"
                  href="./../../../../assets/excel-templates/Presumptive_TB_Directory_of_client.xlsx"
                  #downloadLink download (click)="showListDownload()" class="btn btn-primary"
                  data-bs-dismiss="modal">
                  Download
                </a>
                <a *ngIf="selectedTemplate == '2'" [ngStyle]="{display: 'none'}"
                  href="./../../../../assets/excel-templates/Incare_TB_Directory_of_client.xlsx"
                  #downloadLink download (click)="showListDownload()" class="btn btn-primary"
                  data-bs-dismiss="modal">
                  Download
                </a>
                <a *ngIf="selectedTemplate == '3'" [ngStyle]="{display: 'none'}"
                  href="./../../../../assets/excel-templates/LTFU_TB_Directory_of_client.xlsx"
                  #downloadLink download (click)="showListDownload()" class="btn btn-primary"
                  data-bs-dismiss="modal">
                  Download
                </a>
                <!-- Button trigger modal -->
                <button type="button" #modal class="btn btn-primary" [ngStyle]="{display: 'none'}"
                  data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                  modal button
                </button>

                <!-- Modal -->
                <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static"
                  data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel"
                  aria-hidden="true">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h6 class="modal-title alert-title" id="staticBackdropLabel">* Please follow the guidelines to successfully upload a line list.</h6>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                          aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        <p>
                          <li> <b>The file must be an .xlsx format.</b> </li>
                          <li>Ensure that there are no missing fields in the spreadsheet file e.g. phone number, facility name, etc.</li>
                          <li>Ensure that the column titles correspond with the default template.</li>
                          <li>Ensure that there are no wrong data types or characters in the number column.</li>
                          <li>Ensure you have a stable internet connection.</li>
                          <li>Use the template in the download prompt and provide an accurate data.</li>
                      </p>
                        <div>
                          <label for="dontshow">
                            <input type="checkbox" name="popMessage" #popMessage="ngModel" ngModel
                              (change)="popMethod(popMessage)" id="dontshow"> Don't show
                            me this
                            message again.
                          </label>

                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-outline-danger text-danger"
                          data-bs-dismiss="modal">Close</button>
                        <button (click)="showListDownload()" class="btn btn-danger"
                          data-bs-dismiss="modal">
                          Download
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <ng-container>
              <a *ngIf="selectedTemplate == '1'"
                href="./../../../../assets/excel-templates/Presumptive_TB_Directory_of_client.xlsx"
                class="px-4" #downloadLink download>
                Download Template
              </a>
            </ng-container>
            <ng-container>
              <a *ngIf="selectedTemplate == '2'"
                href="./../../../../assets/excel-templates/Incare_TB_Directory_of_client.xlsx"
                class="px-4" #downloadLink download>
                Download Template
              </a>
            </ng-container>
            <ng-container>
              <a *ngIf="selectedTemplate == '3'"
                href="./../../../../assets/excel-templates/LTFU_TB_Directory_of_client.xlsx"
                class="px-4" #downloadLink download>
                Download Template
              </a>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>