<div class="main-content">
  <div class="px-2 p-xxl-5">
    <div class="container d-flex justify-content-center" *ngIf="showPreloader">
      <div class="spinner-border ">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div class="container-fluid px-0" >
      <div>
        <div class="row mx-0">
          <div class="form-group col-md-3 mb-3 p-2" *ngIf="stateAdmin || nationalAdmin">
            <label for="state" class="form-label pl-2">States</label>
            <div id="state">
              <select name="state" class="form-select" id="state" [(ngModel)]="selectedState"
                (change)=" getFacilityInState()"  (mouseenter)="clearFacility()">
                <option value="" selected>All</option>
                <ng-container *ngFor="let state of states">
                  <option [value]="state.name">{{state.name}}</option>
                </ng-container>
              </select>
            </div>
          </div>

          <div class="form-group col-md-3 mb-3 p-2" *ngIf="facilityAdmin || nationalAdmin">
            <label for="facility" class="form-label pl-2">Facilities</label>
            <div id="facility">
              <select name="facility" class="form-select" id="facility" [(ngModel)]="facilityId"
                 >
                <ng-container *ngFor="let facility of facilities">
                  <option [value]="facility.id">{{facility.name}}</option>
                </ng-container>
              </select>
            </div>
          </div>

          <div class="form-group col-md-3 mb-3 pt-3 filter">
            <div class="d-flex mt-4">
              <div class="mx-1" (click)="getDashboardStat()">
                <button [disabled]="facilityId == ''"
                  class="btn btn-sm bg-dark text-white ">Filter</button>
              </div>
              <a (click)="clearFilter()" class="btn btn-sm btn-danger text-white">Clear Filter</a>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row mx-0 border-gray-200 border-bottom mb-1">
        <div *ngIf="states.length > 0" class="form-group col-md-2 mb-1 p-1">
          <label for="state" class="form-label pl-2">States</label>
          <div id="state">
            <select name="states" class="form-select" id="states"
              [(ngModel)]="filterReports.selectedState" (change)="getFilteredReports()" required>
              <option *ngIf="states.length > 1" value="" selected>All States</option>
              <option *ngFor="let state of states" value="{{state.name}}">{{state.name}}
              </option>
            </select>
          </div>
        </div>
        <div *ngIf="facilities.length > 0" class="form-group col-md-2  mb-1 p-1">
          <label for="facilities" class="form-label pl-2">Facilities</label>
          <div id="facilities">
            <select name="facilities" (change)="getFilteredReports()" class="form-select"
              id="states" [(ngModel)]="filterReports.selectedFacility" required>
              <option *ngIf="facilities.length > 1" value="" selected>All Facilities</option>
              <option *ngFor="let facility of facilities" value="{{facility.id}}">{{facility.name}}
              </option>
            </select>
          </div>
        </div>
      </div> -->
      <div class="row mt-2">
        <div title="Change Patient Stage" class="col-lg-4 col-md-6 col-sm-12 patient-card"
          (click)="setPatientStage('Presumptive')">
          <div class="card mb-4 rounded-12 shadow active-card"
            [ngClass]="{'selected-card': patientStage == 'Presumptive'}">
            <div class="card-body p-3 p-xl-3 p-xxl-4">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="badge badge-size-xl rounded-24 py-2 bg-pink-50 text-gray-600"><svg
                      class="icon icon-tabler icon-tabler-arrow-big-right mt-2" fill="none"
                      height="44" stroke="#DD5598" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="1.5" viewBox="0 0 24 24" width="44"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 0h24v24H0z" fill="none" stroke="none" />
                      <path
                        d="M4 9h8v-3.586a1 1 0 0 1 1.707 -.707l6.586 6.586a1 1 0 0 1 0 1.414l-6.586 6.586a1 1 0 0 1 -1.707 -.707v-3.586h-8a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1z" />
                    </svg></span>
                </div>
                <div class="col-5 col-xxl-6">
                  <span class="caption text-gray-600 d-block mb-1">General population reached</span>
                  <span class="h3 mb-0">{{presumptiveCount.toLocaleString()}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div title="Change Patient Stage" class="col-lg-4 col-md-6 col-sm-12 patient-card"
          (click)="setPatientStage('In-Care')">
          <div class="card mb-4 rounded-12 shadow active-card"
            [ngClass]="{'selected-card': patientStage == 'In-Care'}">
            <div class="card-body p-3 p-xl-3 p-xxl-4">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="badge badge-size-xl rounded-24 py-2 bg-cyan-50 text-gray-600"><svg
                      class="icon icon-tabler icon-tabler-report-medical mt-2" fill="none"
                      height="44" stroke="#2FAACA" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="1.5" viewBox="0 0 24 24" width="44"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 0h24v24H0z" fill="none" stroke="none" />
                      <path
                        d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                      <rect height="4" rx="2" width="6" x="9" y="3" />
                      <line x1="10" x2="14" y1="14" y2="14" />
                      <line x1="12" x2="12" y1="12" y2="16" />
                    </svg></span>
                </div>

                <div class="col-5 col-xxl-6">
                  <span class="caption text-gray-600 d-block mb-1">In-Care Patients</span>
                  <span class="h3 mb-0">{{incareCount.toLocaleString()}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div title="Change Patient Stage" class="col-lg-4 col-md-6 col-sm-12 patient-card"
          (click)="setPatientStage('Lost To Follow Up')">
          <div class="card mb-4 rounded-12 shadow active-card"
            [ngClass]="{'selected-card': patientStage == 'Lost To Follow Up'}">
            <div class="card-body p-3 p-xl-3 p-xxl-4">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="badge badge-size-xl rounded-24 py-2 bg-yellow-50 text-gray-600"><svg
                      class="icon icon-tabler icon-tabler-file-search mt-2" fill="none" height="44"
                      stroke="#F77E29" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="1.5" viewBox="0 0 24 24" width="44"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 0h24v24H0z" fill="none" stroke="none" />
                      <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                      <path d="M12 21h-5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v4.5" />
                      <circle cx="16.5" cy="17.5" r="2.5" />
                      <line x1="18.5" x2="21" y1="19.5" y2="22" />
                    </svg></span>
                </div>

                <div class="col-5 col-xxl-6">
                  <span class="caption text-gray-600 d-block mb-1">Lost To Follow Up Patients</span>
                  <span class="h3 mb-0">{{ltfuCount.toLocaleString()}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="p-3 p-xxl-5 border-bottom border-gray-200">
    <div class="container-fluid px-0">
      <div class="col-auto d-flex flex-column my-2 my-sm-0 border-bottom border-gray-200">
        <div class="d-flex align-self-center">
          <h2 *ngIf="patientStage == 'Presumptive'" class="p-2"> General Population</h2>
          <h2 *ngIf="patientStage == 'In-Care' || patientStage == 'Lost To Follow Up' " class="p-2">{{patientStage}} Patients</h2>
        </div>
      </div>

      <div class="row p-4 p-xxl-5 border-bottom border-gray-200">
        <div class="col-12 card mb-4 rounded-12 shadow">

          <div class="col-auto d-flex flex-column my-sm-0  py-3">
            <div class="d-flex align-self-center">
              <h6>Patient Statistics</h6>
            </div>
            <div *ngIf="showPreloader" class="card-body d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
          <div class="row mx-3"
            *ngIf="patientStage === 'Presumptive' && presumptiveBarChartData[0]?.data.length > 0">
            <div class="col-md-4">
              <div (click)="routeToTotal()"
                class="bg-presumptive text-white rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 cursor-pointer card-fixed">
                <div class="px-0 px-md-3 py-0 py-md-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users"
                    width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                  <h5 class="font-weight-medium mb-0 pt-md-3 text-white">TOTAL REACHED</h5>
                  <h2 class="h1 d-flex align-items-center text-white">
                    {{presumptiveCumulative.totalPatients.toLocaleString()}}</h2>
                </div>
              </div>
            </div>
            <div class="col-md-4" (click)="routeToUssdActivation()">
              <div
                class="bg-dark text-white rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                <div class="px-0 px-md-3 py-0 py-md-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users"
                    width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                  <h5 class="font-weight-medium mb-0 pt-md-3 text-white">TOTAL SCREENED</h5>
                  <h2 class="h1 text-white d-flex align-items-center">
                    {{presumptiveCumulative.totalUssdActivation}}</h2>

                </div>
              </div>
            </div>
            <div class="col-md-4" (click)="routeToPositiveResponse()">
              <div
                class="shadow border border-dark rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                <div class="px-0 px-md-3 py-0 py-md-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users"
                    width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                  <h5 class="font-weight-medium mb-0 pt-md-3">TOTAL PRESUMPTIVES</h5>

                  <h2 class="h1 d-flex align-items-center">
                    {{presumptiveCumulative.totalPositivePresumptiveScreening}}
                  </h2>

                </div>
              </div>
            </div>
            <div class="col-md-4" (click)="routeToEvaluated()">
              <div
                class="shadow border border-dark rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                <div class="px-0 px-md-3 py-0 py-md-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users"
                    width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                  <h5 class="font-weight-medium mb-0 pt-md-3 text-black">TOTAL EVALUATED</h5>
                  <h2 class="h1 text-black d-flex align-items-center">
                    {{presumptiveCumulative.totalEvaluated}}</h2>
                </div>
              </div>
            </div>
            <div class="col-md-4" (click)="routeToDiagnosed()">
              <div
                class="bg-presumptive text-white rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                <div class="px-0 px-md-3 py-0 py-md-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users"
                    width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                  <h5 class="font-weight-medium mb-0 pt-md-3 text-white">TOTAL DIAGNOSED</h5>

                  <h2 class="h1 text-white d-flex align-items-center">
                    {{presumptiveCumulative.totalDiagnosed}}</h2>

                </div>
              </div>
            </div>
            <div class="col-md-4" (click)="routeToOnTreatment()">
              <div
                class="bg-dark text-white rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                <div class="px-0 px-md-3 py-0 py-md-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users"
                    width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                  <h5 class="font-weight-medium mb-0 pt-md-3 text-white">TOTAL PATIENTS PLACED ON
                    TREATMENT</h5>

                  <h2 class="h1 text-white d-flex align-items-center">
                    {{presumptiveCumulative.totalOnTreatment}}</h2>

                </div>
              </div>
            </div>
            <canvas [chartType]="barChartType" [datasets]="presumptiveBarChartData"
              [labels]="patientBarChartLabels" [legend]="barChartLegend" [options]="barChartOptions"
              [plugins]="barChartPlugins" [colors]="presumptiveColors" baseChart>
            </canvas>
          </div>
          <div class="row mx-3"
            *ngIf="patientStage === 'In-Care' &&  inCareBarChartData[0]?.data.length > 0">
            <div class="col-md-4">
              <div (click)="routeToTotal()"
                class="bg-incare text-white rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                <div class="px-0 px-md-3 py-0 py-md-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users"
                    width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                  <h5 class="font-weight-medium mb-0 pt-md-3 text-white">TOTAL PATIENTS</h5>
                  <h2 class="h1 d-flex align-items-center text-white">
                    {{incareCumulative.totalPatients}}</h2>

                </div>
              </div>
            </div>
            <div class="col-md-4" (click)="routeToUssdActivation()">
              <div
                class="bg-dark text-white rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                <div class="px-0 px-md-3 py-0 py-md-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users"
                    width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                  <h5 class="font-weight-medium mb-0 pt-md-3 text-white">TOTAL USSD ACTIVATION</h5>
                  <h2 class="h1 text-white d-flex align-items-center">
                    {{incareCumulative.totalUssdActivation}}</h2>

                </div>
              </div>
            </div>
            <div class="col-md-4" (click)="routeToMedicationAdherent()">
              <div
                class="shadow border border-dark rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                <div class="px-0 px-md-3 py-0 py-md-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users"
                    width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                  <h5 class="font-weight-medium mb-0 pt-md-3">TOTAL ADHERENT TO MEDICATIONS</h5>
                  <h2 class="h1 d-flex align-items-center">
                    {{incareCumulative.totalAdherenceToMedication}}</h2>

                </div>
              </div>
            </div>
            <div class="col-md-4" (click)="routeToNonMedicationAdherent()">
              <div
                class="shadow border border-dark rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                <div class="px-0 px-md-3 py-0 py-md-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users"
                    width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                  <h5 class="font-weight-medium mb-0 pt-md-3">TOTAL NON-ADHERENT TO MEDICATIONS</h5>
                  <h2 class="h1 text-black d-flex align-items-center">
                    {{incareCumulative.totalNonAdherenceToMedication}}
                  </h2>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div
                class="bg-incare text-white rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                <div class="px-0 px-md-3 py-0 py-md-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users"
                    width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                  <h5 class="font-weight-medium mb-0 pt-md-3  text-white">TOTAL NON-ADHERENT
                    BARRIERS</h5>

                  <h2 class="h1 text-white d-flex align-items-center ">
                    {{incareCumulative.totalNonAdherenceBarriers}}
                  </h2>

                </div>
              </div>
            </div>
            <div class="col-md-4" (click)="routeToTreatmentCompleted()">
              <div
                class="bg-dark text-white rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                <div class="px-0 px-md-3 py-0 py-md-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users"
                    width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                  <h5 class="font-weight-medium mb-0 pt-md-3 text-white">TOTAL TREATMENT COMPLETED
                    PATIENTS</h5>

                  <h2 class="h1 text-white d-flex align-items-center">
                    {{incareCumulative.totalTreatmentCompleted}}</h2>

                </div>
              </div>
            </div>

            <canvas [chartType]="barChartType" [datasets]="inCareBarChartData"
              [labels]="patientBarChartLabels" [legend]="barChartLegend" [options]="barChartOptions"
              [plugins]="barChartPlugins" [colors]="inCareColors" baseChart>
            </canvas>
          </div>
          <div class="row mx-3"
            *ngIf="patientStage === 'Lost To Follow Up' && ltfuBarChartData[0]?.data.length > 0">
            <div class="col-md-4">
              <div (click)="routeToTotal()"
                class="bg-ltfu text-white rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                <div class="px-0 px-md-3 py-0 py-md-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users"
                    width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                  <h5 class="font-weight-medium mb-0 pt-md-3 text-white">TOTAL PATIENTS</h5>
                  <h2 class="h1 d-flex align-items-center text-white">
                    {{ltfuCumulative.totalPatients}}</h2>

                </div>
              </div>
            </div>
            <div class="col-md-4" (click)="routeToUssdActivation()">
              <div
                class="bg-dark text-white rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                <div class="px-0 px-md-3 py-0 py-md-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users"
                    width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                  <h5 class="font-weight-medium mb-0 pt-md-3 text-white">TOTAL USSD ACTIVATION</h5>
                  <h2 class="h1 text-white d-flex align-items-center">
                    {{ltfuCumulative.totalUssdActivation}}</h2>

                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div
                class="shadow border border-dark rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                <div class="px-0 px-md-3 py-0 py-md-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users"
                    width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                  <h5 class="font-weight-medium mb-0 pt-md-3">TOTAL BARRIERS ELICITED</h5>
                  <h2 class="h1 d-flex align-items-center">{{ltfuCumulative.totalBarriersElicited}}
                  </h2>

                </div>
              </div>
            </div>
            <div class="col-md-4" (click)="routeToPatientContacted()">
              <div
                class="shadow border border-dark rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                <div class="px-0 px-md-3 py-0 py-md-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users"
                    width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                  <h5 class="font-weight-medium mb-0 pt-md-3">TOTAL PATIENTS CONTACTED</h5>
                  <h2 class="h1 text-black d-flex align-items-center">
                    {{ltfuCumulative.totalNumbersContacted}}</h2>
                </div>
              </div>
            </div>
            <div class="col-md-4" (click)="routeToPatientEvaluated()">
              <div
                class="bg-ltfu text-white rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                <div class="px-0 px-md-3 py-0 py-md-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users"
                    width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                  <h5 class="font-weight-medium mb-0 pt-md-3  text-white">TOTAL PATIENTS EVALUATED
                  </h5>

                  <h2 class="h1 text-white d-flex align-items-center ">
                    {{ltfuCumulative.totalPatientsEvaluated}}</h2>

                </div>
              </div>
            </div>
            <div class="col-md-4" (click)="routeToPatientBackOnTreatment()">
              <div
                class="bg-dark text-white rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                <div class="px-0 px-md-3 py-0 py-md-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users"
                    width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                  <h5 class="font-weight-medium mb-0 pt-md-3 text-white">TOTAL PATIENTS BACK ON
                    TREATMENT</h5>

                  <h2 class="h1 text-white d-flex align-items-center">
                    {{ltfuCumulative.totalPatientsOnTreatment}}</h2>

                </div>
              </div>
            </div>
            <canvas [chartType]="barChartType" [datasets]="ltfuBarChartData"
              [labels]="patientBarChartLabels" [legend]="barChartLegend" [options]="barChartOptions"
              [plugins]="barChartPlugins" [colors]="ltfuColors" baseChart>
            </canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>