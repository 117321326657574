import {GeneralUtil} from '../util/general-util';

export class InCareCumulativeDto2 {
  public totalPatients: number = 0;
  public totalAdherenceToMedication: number;
  public totalNonAdherenceToMedication: number;
  public totalNonAdherenceBarriers: number;
  public totalUssdActivation: number;
  public totalTreatmentCompleted: number;

  constructor() { }

  public mapToInCareCumulativeDto2(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.totalPatients = data.totalPatients.toLocaleString();
      this.totalAdherenceToMedication = data.totalAdherenceToMedication.toLocaleString();
      this.totalNonAdherenceToMedication = data.totalNonAdherenceToMedication.toLocaleString();
      this.totalNonAdherenceBarriers = data.totalNonAdherenceBarriers.toLocaleString();
      this.totalUssdActivation = data.totalUssdActivation.toLocaleString();
      this.totalTreatmentCompleted = data.totalTreatmentCompleted.toLocaleString();
    }
  }


}
