import {
  Component,
  OnInit,
} from '@angular/core';

import { FilterReportsDto } from '../../../../dto/filter-reports-dto';
import { InCareUssdDto } from '../../../../dto/in-care-ussd-dto';
import { Facility } from '../../../../entity/facility';
import { State } from '../../../../entity/state';
import { LocationService } from '../../../../service/location.service';
import { ReportService } from '../../../../service/report.service';
import { UserService } from '../../../../service/user.service';
import { GeneralUtil } from '../../../../util/general-util';

@Component({
  selector: 'app-ussd-reports',
  templateUrl: './in-care-ussd-reports.component.html',
  styleUrls: ['./in-care-ussd-reports.component.css']
})
export class InCareUssdReportsComponent implements OnInit {

  public showPreloader = false;
  public inCareUssdArray: InCareUssdDto[] = [];
  public d = new Date();
  public states: State[] = [];
  public facilities: Facility[] = [];
  public page = 1;
  public pageSize = 30;
  public exporting = false;
  public filterReports = new FilterReportsDto();
  public sortReverse = false;

  constructor(
    public reportService: ReportService,
    public userService: UserService,
    public locationService: LocationService) {
  }

  ngOnInit(): void {
    this.d.setMonth(this.d.getMonth() - 1);
    this.getUssdReportForObjective2(this.filterReports.endDate,this.filterReports.startDate);
    this.getAllStates();
  }

  sortOrders() {
    this.sortReverse = !this.sortReverse;
    this.sortReverse === true ?

      GeneralUtil.arraySorter(this.inCareUssdArray,'feedbackSorter','normal') :
      GeneralUtil.arraySorter(this.inCareUssdArray,'feedbackSorter','reverse');
  }

  numbersOnly(event) {
    return GeneralUtil.numbersOnly(event);
  }

  clearFilter() {
    this.filterReports = new FilterReportsDto();
    this.getUssdReportForObjective2(this.filterReports.endDate,this.filterReports.startDate);
  }

  getAllStates() {
    this.states = [];
    this.locationService.getActiveStates()
      .subscribe(data => {
        const stateArray = data;
        if (stateArray.length > 0) {
          stateArray.forEach(s => {
            const sta = new State();
            sta.mapToState(s);
            this.states.push(sta);
          });
        }
      },error1 => {
        this.errorHandler(error1);
      });
  }

  getFacilitiesByState(stateId) {
    this.facilities = [];
    this.locationService.retrieveFacilityByState(stateId)
      .subscribe(data => {
        const facilityArray = data;
        if (facilityArray.length > 0) {
          facilityArray.forEach(f => {
            const fac = new Facility();
            fac.mapToFacility(f);
            this.facilities.push(fac);
          });
        }
      },error1 => {
        this.errorHandler(error1);
      });
  }

  changeFacility() {
    if (this.filterReports.selectedState === '') {
      return;
    } else {
      let filteredStateId;
      this.states.forEach(s => {
        if (s.name === this.filterReports.selectedState) {
          filteredStateId = s.id;
        }
      });

      this.getFacilitiesByState(filteredStateId);
    }

  }


  exportToCsv() {
    this.exporting = true;
    setTimeout(() => {
      GeneralUtil.export2csv('in-care-ussd-report.csv');
    },200);
    setTimeout(() => {
      this.exporting = false;
    },300);
  }

  getUssdReportForObjective2(endDate,startDate) {
    this.showPreloader = true;
    this.reportService.ussdReportForObjective2
      (100,0,endDate,startDate,this.filterReports.selectedState,this.filterReports.phoneNumber,this.filterReports.selectedFacility,this.filterReports.ussdActivated)
      .subscribe((data) => {
        this.showPreloader = false;

        this.inCareUssdArray = [];
        const tempInCareUssdArray = data;
        if (tempInCareUssdArray !== null && tempInCareUssdArray.length > 0) {
          tempInCareUssdArray.forEach(p => {
            const inCareUssd = new InCareUssdDto();
            inCareUssd.mapToDto(p);
            this.inCareUssdArray.push(inCareUssd);
          });
        }
        // arrange the USSD by Latest sent
        GeneralUtil.arraySorter(this.inCareUssdArray,'timeStamp','normal');

        // remove timezone from ussd timestamps
        this.inCareUssdArray.forEach(ussd => {
          ussd.timeStamp = GeneralUtil.removeTimezoneFromDateString(ussd.timeStamp);
        });
        return this.inCareUssdArray;
      },
        (error) => {
          this.showPreloader = false;
          this.errorHandler(error);
        });
  }

  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }
}
