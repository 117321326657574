import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GeneralEnum } from '../constant/general-enum.enum';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class RoleService {
  constructor(public httpClient: HttpClient) { }

  public getRoles() {
    const url = GeneralEnum.baseUrl + '/auth/roles';

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public toggleStatus(roleId: number) {
    const url = GeneralEnum.baseUrl + '/auth/roles/toggle/' + roleId;

    return this.httpClient.put<any>(url, roleId)
      .pipe(catchError(this.errorHandler));
  }

  // Error Handler
  public errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
