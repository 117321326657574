import {
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { GeneralEnum } from '../constant/general-enum.enum';
import { PatientStageEnum } from '../constant/patient-stage-enum';
import { PatientDto } from '../dto/patient-dto';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  constructor(public httpClient: HttpClient) {}

  // create patient
  createPatient(patient: PatientDto) {
    const url = GeneralEnum.baseUrl + '/auth/create/patient';

    return this.httpClient
      .post<any>(url, patient)
      .pipe(catchError(this.errorHandler));
  }

  // Get All patients
  public getPatients(limit, page, patientPhoneNumber) {
    const url = GeneralEnum.baseUrl + '/auth/view/patients?limit=' + limit +
      '&page=' + page + '&patientPhoneNumber=' + patientPhoneNumber;

    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }

  // Get number of patients
  public countPatients(patientType: PatientStageEnum, state: string) {
    const url = GeneralEnum.baseUrl + '/auth/patient/' + patientType + '/count?state=' + state;

    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }

  // Get number of patients for facility user
  public countPatientsForFacilities(patientType: PatientStageEnum, facilityId: any) {
    const url = GeneralEnum.baseUrl + '/auth/patient/' + patientType + '/count/facility?facilityId=' + facilityId;

    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }

  // Get number of patients for state user
  public countPatientsForStates(patientType: PatientStageEnum, state: string) {
    const url = GeneralEnum.baseUrl + '/auth/patient/' + patientType + '/count/state?state=' + state;

    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }

  // Get stat reports for patients
  public patientReport(patientType: PatientStageEnum,stateName?:any,facilityId?:any) {
    let url

    if (stateName) {
      url = GeneralEnum.baseUrl + `/auth/patient/${patientType}/report?state=${stateName} `

    }
    else if(facilityId){
      url = GeneralEnum.baseUrl +` /auth/patient/'${patientType}/report?facilityId=${facilityId} `

    }else{
      url = GeneralEnum.baseUrl + '/auth/patient/' + patientType + '/report'

    }
    // const url = GeneralEnum.baseUrl + '/auth/patient/' + patientType + '/report';

    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }

  // Get stat reports for patients in a facility
  public patientReportForFacilities(patientType: PatientStageEnum, faciiltyId: any) {
    const url = GeneralEnum.baseUrl + '/auth/patient/' + patientType + '/report/facility?facilityId=' + faciiltyId;

    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }

  // Get stat reports for patients in a state
  public patientReportForStates(patientType: PatientStageEnum, state: any) {
    const url = GeneralEnum.baseUrl + '/auth/patient/' + patientType + '/report/state?state=' + state;

    return this.httpClient.get<any>(url).pipe(catchError(this.errorHandler));
  }

  // getPatientList (presumptive, incare, ltfu)
  public getPatientList(limit, page, phone, type, stage, state) {
    const url = `${GeneralEnum.baseUrl}/auth/patient/${type}/list?limit=${limit}&page=${page}&phoneNumber=${phone}&stage=${stage}&state=${state}`;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  // getPatientList for state admin (presumptive, incare, ltfu)
  public getPatientListForStateAdmin(limit, page, type, phone, stage, state) {
    const url = GeneralEnum.baseUrl + '/auth/patient/' + type + '/' + 'list/state?&limit=' + limit + '&page=' +
    page + '&phoneNumber=' + phone + '&stage=' + stage + '&state=' + state;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  // getPatientList for facility admin (presumptive, incare, ltfu)
  public getPatientListForFacilityAdmin(limit, page, type, facilityId, phone, stage, state) {
    const url = GeneralEnum.baseUrl + '/auth/patient/' + type + '/' + 'list/facility?&facilityId=' + facilityId + '&limit=' + limit + '&page=' + page +
    '&phoneNumber=' + phone + '&stage=' + stage + '&state=' + state;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  // change patient status for presumptive
  changePatientStatusPresumptive(patientId: number, patientStatus: string) {
    const body = { 'patientId': patientId, 'patientStatus': patientStatus }
    const url = GeneralEnum.baseUrl + '/auth/patient' + '/' + patientId + '/' + 'presumptive?status=' + patientStatus;
    return this.httpClient.patch<any>(url, body)
      .pipe(catchError(this.errorHandler));
  }

  // change patient status for Incare
  changePatientStatusIncare(patientId: number, patientStatus: string) {
    const body = { 'patientId': patientId, 'patientStatus': patientStatus }
    const url = GeneralEnum.baseUrl + '/auth/patient' + '/' + patientId + '/' + 'inCare?status=' + patientStatus;
    return this.httpClient.patch<any>(url, body)
      .pipe(catchError(this.errorHandler));
  }

  // change patient status for lost to follow up
  changePatientStatusLtfu(patientId: number, patientStatus: string) {
    const body = { 'patientId': patientId, 'patientStatus': patientStatus }
    const url = GeneralEnum.baseUrl + '/auth/patient' + '/' + patientId + '/' + 'ltfu?status=' + patientStatus;
    return this.httpClient.patch<any>(url, body)
      .pipe(catchError(this.errorHandler));
  }

  // upload patient line list
  uploadLineList(formData: FormData, patientType) {
    const url = GeneralEnum.baseUrl + '/auth/patient/upload?&patientType=' + patientType;

    return this.httpClient.post<any>(url, formData)
      .pipe(catchError(this.errorHandler));
  }


// Error Handler
   public errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
