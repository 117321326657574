import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../service/auth/auth.service';
import {GeneralUtil} from '../../../util/general-util';
import {UserService} from '../../../service/user.service';
import {ChangeYourPasswordDto} from '../../../dto/change-your-password-dto';

declare function successToast(msg): any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  // variables
  public username: string;
  public fullname: string;
  public role: string;
  public greeting;
  public showPreloader = false;
  public changeYourPasswordDto = new ChangeYourPasswordDto();
  @ViewChild('hamburger') hamburger: ElementRef;
  @ViewChild('close') close: ElementRef;

  constructor(public authService: AuthService,
              public userService: UserService,
              public router: Router) { }

  ngOnInit(): void {
    this.greetingUser();
    this.username = this.authService.getUserDetailItem('KNCV-USER-DETAILS-USERNAME');
    this.fullname = this.authService.getUserDetailItem('KNCV-USER-DETAILS-FULL-NAME');
    this.role = GeneralUtil.removeUnderscore(this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE'));
  }

  logout() {
    this.authService.resetUserDetails();
    this.router.navigate(['/login']);
    window.location.reload();
  }

  changePassword() {
    this.showPreloader = true;
    this.changeYourPasswordDto.email = this.authService.getUserDetailItem('KNCV-USER-DETAILS-EMAIL');
    this.userService.changeYourPassword(this.changeYourPasswordDto)
      .subscribe((data) => {
          this.showPreloader = false;
          this.changeYourPasswordDto = new ChangeYourPasswordDto();
          successToast(`Password updated successfully`);
          this.close.nativeElement.click();
          return;
        },

        (error) => {
          this.showPreloader = false;
          this.errorHandler(error);
        }
      );

  }

  greetingUser() {
    const time = new Date().getHours();
    if (time < 12) {
      this.greeting = 'Good morning,';
    } else if (time < 18) {
      this.greeting = 'Good afternoon,';
    } else {
      this.greeting = 'Good evening,';
    }
  }

  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }
}
