import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GeneralEnum } from '../constant/general-enum.enum';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserDto } from './../dto/user-dto';
import { User } from './../entity/user';
import {ChangeYourPasswordDto} from '../dto/change-your-password-dto';




@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(public httpClient: HttpClient) { }

  // Get All users
  public getUsers(roleName: string, page: number, limit: number) {
    const url = GeneralEnum.baseUrl + '/auth/users/all?page=' + page + '&limit=' + limit + '&roleName=' + roleName;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  // Create User
  public createUser(user: UserDto){
    const url = GeneralEnum.baseUrl + '/auth/users';

    return this.httpClient.post<any>(url, user)
      .pipe(catchError(this.errorHandler));

  }

  // Update User
  public updateUser(user: User){
    const url = GeneralEnum.baseUrl + '/auth/users';

    return this.httpClient.put<any>(url, user)
      .pipe(catchError(this.errorHandler));

  }

  // Get User By Id
 public getUserById(id: number){
    const url = GeneralEnum.baseUrl + '/auth/users/' + id;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));

  }

  // Get All Roles
  public getAllRoles(){
    const url = GeneralEnum.baseUrl + '/auth/roles';

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public toggleUserStatus(userId: number, status: string){
    const statusBody = {status, userId};
    const url = GeneralEnum.baseUrl + '/auth/users/toggle/status';

    return this.httpClient.patch<any>(url, statusBody)
      .pipe(catchError(this.errorHandler));
  }

  // change existing password
  public changeYourPassword(passwordBody: ChangeYourPasswordDto) {

    const url = GeneralEnum.baseUrl + '/auth/users/password/change';

    return this.httpClient.post<any>(url, passwordBody)
      .pipe(catchError(this.errorHandler));
  }

  // Error Handler
  public errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
