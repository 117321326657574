<!-- main side menu -->
<nav class="navbar navbar-vertical navbar-expand-lg navbar-light">
  <div class="navbar-brand mx-auto d-none d-lg-block m-4" href="#">
    <!-- main logo -->
    <img alt="KNCV" src="../../../../assets/images/svg/login-kncv-logo1.svg">
    <!-- smaller logo -->
    <img alt="KNCV" class="muze-icon px-3" src="../../../../assets/images/vector.png">
  </div>
  <div class="navbar-collapse">
    <ul class="navbar-nav mb-2" data-simplebar id="accordionExample">
      <li [routerLink]="['/dashboard']" class="nav-item">
        <a class="nav-link collapsed" [routerLinkActive]="['active-link']">
          <svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
            <g data-name="icons/tabler/chart" transform="translate(0)">
              <rect data-name="Icons/Tabler/Chart background" fill="none" height="16" width="16" />
              <path
                d="M.686,13.257a.686.686,0,0,1-.093-1.365l.093-.006H15.314a.686.686,0,0,1,.093,1.365l-.093.006ZM.394,9.535l-.089-.05a.688.688,0,0,1-.24-.863l.05-.088L3.773,3.048a.684.684,0,0,1,.782-.272l.095.039L7.811,4.4,11.121.257a.687.687,0,0,1,.945-.122L12.142.2,15.8,3.858a.686.686,0,0,1-.893,1.036l-.077-.067L11.713,1.712,8.536,5.685a.684.684,0,0,1-.743.225l-.1-.04L4.578,4.313,1.256,9.294a.684.684,0,0,1-.862.24Z"
                fill="#1e1e1e" transform="translate(0 1)" />
            </g>
          </svg>
          &nbsp;<span class="ms-2" id="dashboard">Dashboard</span>

        </a>
      </li>

      <li class="nav-item">
        <div aria-controls="sidebarPages" class="nav-link collapsed" data-bs-toggle="collapse"
          href="#patient-report" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users"
            width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#6c757d"
            fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" style="fill: none;" />
            <circle cx="9" cy="7" r="4" />
            <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" style="fill: none;" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" style="fill: none;" />
            <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" style="fill: none;" />
          </svg> &nbsp;<span class="ms-2">Patient List</span>
        </div>
        <div class="collapse collapse-box" data-bs-parent="#accordionExample" id="patient-report">
          <ul class="nav nav-sm flex-column" id="submenu2">
            <li class="nav-item nav-subtitle">
              <small>Presumptive</small>
            </li>


            <li [routerLink]="['/patient/patient-lists/' + patientStatus.PRESUMPTIVE]"
              class="nav-item">
              <a class="nav-link collapsed" [routerLinkActive]="['active-link']">
                <span class="ms-2" id="ussd1">General Population </span>
              </a>
            </li>

            <li class="nav-item nav-subtitle">
              <small>In-care</small>
            </li>


            <li [routerLink]="['/patient/patient-lists/' + patientStatus.IN_CARE]" class="nav-item">
              <a class="nav-link collapsed" [routerLinkActive]="['active-link']">
                <span class="ms-2" id="ussd3">In-care Patients</span>
              </a>
            </li>

            <li class="nav-item nav-subtitle">
              <small>Lost to follow up</small>
            </li>


            <li [routerLink]="['/patient/patient-lists/' + patientStatus.LOST_TO_FOLLOWUP]"
              class="nav-item">
              <a class="nav-link collapsed" [routerLinkActive]="['active-link']">
                <span class="ms-2" id="ussd2">Lost to follow up Patients</span>
              </a>
            </li>

          </ul>
        </div>
      </li>

      <li class="nav-item">
        <div aria-controls="sidebarPages" class="nav-link collapsed" data-bs-toggle="collapse"
          href="#ussd-reports" role="button">
          <svg data-name="Icons/Tabler/Bolt" height="16" viewBox="0 0 16 16" width="16"
            xmlns="http://www.w3.org/2000/svg">
            <rect data-name="Icons/Tabler/Page background" fill="none" height="16" width="16" />
            <path
              d="M1.975,14A1.977,1.977,0,0,1,0,12.026V1.975A1.977,1.977,0,0,1,1.975,0h5.04a.535.535,0,0,1,.249.069l.007,0h0a.534.534,0,0,1,.109.084l3.574,3.575a.536.536,0,0,1,.163.289h0l0,.013h0l0,.013v0l0,.011v.053s0,.009,0,.014v7.9A1.977,1.977,0,0,1,9.154,14Zm-.9-12.026V12.026a.9.9,0,0,0,.9.9H9.154a.9.9,0,0,0,.9-.9V4.667H7.718a1.255,1.255,0,0,1-1.248-1.12L6.461,3.41V1.077H1.975A.9.9,0,0,0,1.077,1.975ZM7.538,3.41a.179.179,0,0,0,.122.17l.057.01H9.29L7.538,1.838Z"
              fill="#1e1e1e" transform="translate(2 1)" />
          </svg> &nbsp;<span class="ms-2">USSD Reports</span>
        </div>
        <div class="collapse collapse-box" data-bs-parent="#accordionExample" id="ussd-reports">
          <ul class="nav nav-sm flex-column" id="ussd-reports-submenu">
            <li [routerLink]="['/reports/ussd-reports/' + patientStatus.PRESUMPTIVE]"
              class="nav-item">
              <a class="nav-link collapsed" [routerLinkActive]="['active-link']">
                <span class="ms-2" id="presumptive-patient">General Population</span>
              </a>
            </li>
            <li [routerLink]="['/reports/ussd-reports/' + patientStatus.IN_CARE]" class="nav-item">
              <a class="nav-link collapsed" [routerLinkActive]="['active-link']">
                <span class="ms-2" id="incare-patients">In-Care Patients</span>
              </a>
            </li>
            <li [routerLink]="['/reports/ussd-reports/' + patientStatus.LOST_TO_FOLLOWUP]"
              class="nav-item">
              <a class="nav-link collapsed" [routerLinkActive]="['active-link']">
                <span class="ms-2" id="ltfu-patients">Lost To Follow Up Patients</span>
              </a>
            </li>
          </ul>
        </div>

      </li>

      <li class="nav-item" *ngIf="authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE')
      === 'NATIONAL_LEVEL_ADMIN' || authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE')
      === 'SUPER_ADMIN' ">
        <div aria-controls="sidebarPages" class="nav-link collapsed" data-bs-toggle="collapse"
          href="#sidebarPages4" role="button">
          <svg data-name="Icons/Tabler/Paperclip" height="16" viewBox="0 0 16 16" width="16"
            xmlns="http://www.w3.org/2000/svg">
            <rect data-name="Icons/Tabler/Plug background" fill="none" height="16" width="16" />
            <path
              d="M6.7,16a2.378,2.378,0,0,1-2.373-2.234l0-.145V12.541H3.244A3.241,3.241,0,0,1,0,9.47L0,9.3V4.109a.649.649,0,0,1,.561-.643L.649,3.46H1.73V.649A.649.649,0,0,1,3.021.561l.005.088V3.46H6.919V.649A.649.649,0,0,1,8.211.561l.005.088V3.46H9.3a.649.649,0,0,1,.643.561l.006.088V9.3a3.241,3.241,0,0,1-3.071,3.239l-.173,0H5.621v1.081A1.081,1.081,0,0,0,6.593,14.7l.11.005H9.3a.649.649,0,0,1,.088,1.292L9.3,16Zm0-4.757A1.951,1.951,0,0,0,8.644,9.431l0-.134V4.757H1.3V9.3A1.951,1.951,0,0,0,3.11,11.239l.133,0H6.7Z"
              fill="#1e1e1e" transform="translate(3)" />
          </svg> &nbsp;<span class="ms-2">Setup</span>
        </div>
        <div class="collapse collapse-box show" id="sidebarPages4"
          data-bs-parent="#accordionExample">
          <ul class="nav nav-sm flex-column" id="submenu">
            <li class="nav-item">
              <a class="nav-link collapsed" href="#AccountPage" data-bs-toggle="collapse"
                role="button" aria-expanded="true" aria-controls="AccountPage">User Management</a>
              <div class="collapse collapse-box show" id="AccountPage" data-bs-parent="#submenu">
                <ul class="nav nav-sm flex-column">
                  <li [routerLink]="['/setup/user-management/state-level-admin']" class="nav-item">
                    <a class="nav-link" [routerLinkActive]="['active-link']">State Admin</a>
                  </li>
                  <li [routerLink]="['/setup/user-management/national-level-admin']"
                    class="nav-item">
                    <a class="nav-link" [routerLinkActive]="['active-link']">National Admin</a>
                  </li>
                  <li [routerLink]="['/setup/user-management/facility-level-admin']"
                    class="nav-item">
                    <a class="nav-link" [routerLinkActive]="['active-link']">Facility Admin</a>
                  </li>
                </ul>
              </div>
            </li>

            <li [routerLink]="['/setup/patient-template-list']" class="nav-item">
              <a class="nav-link collapsed" [routerLinkActive]="['active-link']">
                <span class="ms-2" id="role">Patient Line List</span>
              </a>
            </li>
            <li [routerLink]="['/setup/role-management']" class="nav-item">
              <a class="nav-link collapsed" [routerLinkActive]="['active-link']">
                <span class="ms-2" id="role">Role Management</span>
              </a>
            </li>
          </ul>
        </div>
      </li>

      <li class="nav-item">
        <hr class="my-0 bg-gray-50 opacity-100">
      </li>
    </ul>

  </div>

</nav>