import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../../../service/report.service';
import { GeneralUtil } from '../../../../util/general-util';
import { InCareUssdDto } from '../../../../dto/in-care-ussd-dto';
import { State } from '../../../../entity/state';
import { UserService } from '../../../../service/user.service';
import { LocationService } from '../../../../service/location.service';
import { Facility } from '../../../../entity/facility';
import { FilterReportsDto } from '../../../../dto/filter-reports-dto';

@Component({
  selector: 'app-facility-in-care-ussd-reports',
  templateUrl: './facility-in-care-ussd-reports.component.html',
  styleUrls: ['./facility-in-care-ussd-reports.component.css']
})
export class FacilityInCareUssdReportsComponent implements OnInit {

  public showPreloader = false;
  public inCareUssdArray: InCareUssdDto[] = [];
  public d = new Date();
  public states: State[] = [];
  public facilities: Facility[] = [];
  public page = 1;
  public pageSize = 30;
  public exporting = false;
  public filterReports = new FilterReportsDto();
  public sortReverse = false;

  constructor(
    public reportService: ReportService,
    public userService: UserService,
    public locationService: LocationService) {
  }

  ngOnInit(): void {
    this.d.setMonth(this.d.getMonth() - 1);
    this.getUssdReportForObjective2(this.filterReports.endDate, this.filterReports.startDate);
    this.getFacilitiesForFacilityAdmin();
  }

  sortOrders() {
    this.sortReverse = !this.sortReverse;
    this.sortReverse === true ?

      GeneralUtil.arraySorter(this.inCareUssdArray, 'feedbackSorter', 'normal') :
      GeneralUtil.arraySorter(this.inCareUssdArray, 'feedbackSorter', 'reverse');
  }

  numbersOnly(event){
    return GeneralUtil.numbersOnly(event);
  }

  clearFilter() {
    this.filterReports = new FilterReportsDto();
    this.getUssdReportForObjective2(this.filterReports.endDate, this.filterReports.startDate);
  }


  getFacilitiesForFacilityAdmin() {
    this.facilities = [];
    this.locationService.getFacilitiesForFacilityAdmin()
      .subscribe(data => {
        const facilityArray = data;
        if (facilityArray.length > 0) {
          facilityArray.forEach(f => {
            const facil = new Facility();
            facil.mapToFacility(f);
            this.facilities.push(facil);
          });
        }
       }, error1 => {
        this.errorHandler(error1);
      })
  }

  exportToCsv() {
    this.exporting = true;
    setTimeout(() => {
      GeneralUtil.export2csv('in-care-ussd-report.csv');
    }, 200);
    setTimeout(() => {
      this.exporting = false;
    }, 300);
  }

  getUssdReportForObjective2(endDate, startDate) {
    this.showPreloader = true;
    this.reportService.facilityUssdReportForObjective2
      (100, 0, endDate, startDate, this.filterReports.phoneNumber, this.filterReports.selectedFacility, this.filterReports.ussdActivated)
      .subscribe((data) => {
        this.showPreloader = false;
        this.inCareUssdArray = [];
        const tempInCareUssdArray = data;
        if (tempInCareUssdArray !== null && tempInCareUssdArray.length > 0) {
          tempInCareUssdArray.forEach(p => {
            const inCareUssd = new InCareUssdDto();
            inCareUssd.mapToDto(p);
            this.inCareUssdArray.push(inCareUssd);
          });
        }
        // arrange the USSD by Latest sent
        GeneralUtil.arraySorter(this.inCareUssdArray, 'timeStamp', 'normal');

        // remove timezone from ussd timestamps
        this.inCareUssdArray.forEach(ussd => {
          ussd.timeStamp = GeneralUtil.removeTimezoneFromDateString(ussd.timeStamp);
        });
        return this.inCareUssdArray;
      },
        (error) => {
          this.showPreloader = false;
          this.errorHandler(error);
        });
  }

  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }
}
