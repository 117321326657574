import { Router } from '@angular/router';
import {
  Component,
  OnInit,
} from '@angular/core';

import {
  ChartDataSets,
  ChartOptions,
  ChartType,
} from 'chart.js';
import {
  Label,
  monkeyPatchChartJsLegend,
  monkeyPatchChartJsTooltip,
  MultiDataSet,
} from 'ng2-charts';
import { PatientStageEnum } from 'src/app/constant/patient-stage-enum';
import { PatientStatusEnum } from 'src/app/constant/patient-status-enum';
import { RoleEnum } from 'src/app/constant/role-enum';
import { FilterReportsDto } from 'src/app/dto/filter-reports-dto';
import { InCareCumulativeDto2 } from 'src/app/dto/in-care-cumulative2-dto';
import { InCareDashboardDto } from 'src/app/dto/in-care-dashboard-dto';
import { LtfuCumulativeDto2 } from 'src/app/dto/ltfu-cumulative2-dto';
import { LtfuDashboardDto } from 'src/app/dto/ltfu-dashboard-dto';
import { PresumptiveCumulativeDto2 } from 'src/app/dto/presumptive-cumulative-reports-2-dto';
import { PresumptiveDashboardDto } from 'src/app/dto/presumptive-dashboard-dto';
import { Facility } from 'src/app/entity/facility';
import { State } from 'src/app/entity/state';
import { AuthService } from 'src/app/service/auth/auth.service';
import { LocationService } from 'src/app/service/location.service';
import { PatientService } from 'src/app/service/patient.service';

import { StageStatsDto } from '../../dto/stage-stats-dto';
import { UssdStatDto } from '../../dto/ussd-stat-dto';
import { ReportService } from '../../service/report.service';
import { GeneralUtil } from '../../util/general-util';
import { PatientTypes } from 'src/app/constant/patient-type.enum';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  // non-chart variables
  public filterReports = new FilterReportsDto();
  public role = RoleEnum;
  public states: State[] = [];
  public facilities: Facility[] = [];
  public patientStatus = PatientStageEnum;
  public stage = PatientStatusEnum;
  public reportType: PatientStageEnum = this.patientStatus.PRESUMPTIVE;
  public patientStage = 'Presumptive';
  public totalCount: number;
  public presumptiveCount = 0;
  public ltfuCount = 0;
  public incareCount = 0;
  public presumptiveDataSet = new StageStatsDto();
  public inCareDataSet = new StageStatsDto();
  public ltfuDataSet = new StageStatsDto();
  public showPreloader = false;
  public presumptiveCumulative = new PresumptiveCumulativeDto2();
  public incareCumulative = new InCareCumulativeDto2();
  public ltfuCumulative = new LtfuCumulativeDto2();
  public selectedState: string = '';
  public facilityId: string = '';
  public patientTypes = PatientTypes;
  public userRole = '';

    //select filter based on admin
    public stateAdmin:boolean=false;
    public facilityAdmin:boolean=false;
    public nationalAdmin:boolean=false;

  public presumptiveColors = [
    {
      backgroundColor: '#DD5598',
      hoverBackgroundColor: '#FADDEE',
    },
  ];
  public inCareColors = [
    {
      backgroundColor: '#2FAACA',
      hoverBackgroundColor: '#DAFBFE',
    },
  ];
  public ltfuColors = [
    {
      backgroundColor: '#F77E29',
      hoverBackgroundColor: '#FAF0CD',
    },
  ];

  // Bar charts
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            // callback: function (val) {
            //   return Number.isInteger(val) ? val : null;
            // }
            beginAtZero: true
          }
        }
      ]
    },
  };

  public smsBarChartLabels: Label[];
  public patientBarChartLabels: Label[];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public smsPresumptiveBarChartData: ChartDataSets[] = [];
  public presumptiveBarChartData: ChartDataSets[] = [];
  public smsInCareBarChartData: ChartDataSets[] = [];
  public inCareBarChartData: ChartDataSets[] = [];
  public smsLtfuBarChartData: ChartDataSets[] = [];
  public ltfuBarChartData: ChartDataSets[] = [];

  // Doughnut

  public dashboardStats = new StageStatsDto();
  public ltfuDashboardDto = new LtfuDashboardDto();
  public inCareDashboardDto = new InCareDashboardDto();
  public presumptiveDashboardDto = new PresumptiveDashboardDto();
  public ussdStats = new UssdStatDto();

  public total = 'Total SMS Broadcast';
  public totalDoughnutChartLabels: Label[] = [this.total];
  public successfulDoughnutChartLabels: Label[] = [
    this.total,
    'Total SMS Successful',
  ];
  public pendingDoughnutChartLabels: Label[] = [
    this.total,
    'Total SMS Pending',
  ];
  public failedDoughnutChartLabels: Label[] = [this.total,'Total SMS Failed'];
  public totalDoughnutChartData: MultiDataSet = [[0]];
  public successfulDoughnutChartData: MultiDataSet = [[0,0]];
  public pendingDoughnutChartData: MultiDataSet = [[0,0]];
  public failedDoughnutChartData: MultiDataSet = [[0,0]];
  public doughnutChartType: ChartType = 'doughnut';





  constructor(
    public reportService: ReportService,
    public authService: AuthService,
    public patientService: PatientService,
    public locationService: LocationService,
    public router: Router,
  ) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit(): void {
    this.initReload();
    this.getPatientReports();
    this.getCount();
    this.getReportFilterByRole();
    this.getAllStates();
    this.getPresumptiveCumulativeReport();
    this.userRole = this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE');

  }



  getCount() {
    switch (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE')) {
      case this.role.STATE_LEVEL_ADMIN:

        // Get presumptive count
        this.patientService
          .countPatientsForStates(
            this.patientStatus.PRESUMPTIVE,
            this.filterReports.selectedState
          )
          .subscribe(
            (data) => {
              this.presumptiveCount = data;
            },
            (error) => this.errorHandler(error)
          );

        // Get In Care count
        this.patientService
          .countPatientsForStates(
            this.patientStatus.IN_CARE,
            this.filterReports.selectedState
          )
          .subscribe(
            (data) => {
              this.incareCount = data;
            },
            (error) => this.errorHandler(error)
          );

        // Get LTFU count
        this.patientService
          .countPatientsForStates(
            this.patientStatus.LOST_TO_FOLLOWUP,
            this.filterReports.selectedState
          )
          .subscribe(
            (data) => {
              this.ltfuCount = data;
            },
            (error) => this.errorHandler(error)
          );


          this.facilityAdmin = false;
          this.stateAdmin=true;
          this.nationalAdmin=false;

        break;

      case this.role.FACILITY_LEVEL_ADMIN:

        // Get presumptive count
        this.patientService
          .countPatientsForFacilities(
            this.patientStatus.PRESUMPTIVE,
            this.filterReports.selectedFacility
          )
          .subscribe(
            (data) => {
              this.presumptiveCount = data;
            },
            (error) => this.errorHandler(error)
          );

        // Get In Care count
        this.patientService
          .countPatientsForFacilities(
            this.patientStatus.IN_CARE,
            this.filterReports.selectedFacility
          )
          .subscribe(
            (data) => {
              this.incareCount = data;
            },
            (error) => this.errorHandler(error)
          );

        // Get LTFU count
        this.patientService
          .countPatientsForFacilities(
            this.patientStatus.LOST_TO_FOLLOWUP,
            this.filterReports.selectedFacility
          )
          .subscribe(
            (data) => {
              this.ltfuCount = data;
            },
            (error) => this.errorHandler(error)
          );

          //

          this.facilityAdmin = true;
          this.stateAdmin=false;
          this.nationalAdmin=false;

        break;

      default:
        // Get presumptive count
        this.patientService
          .countPatients(
            this.patientStatus.PRESUMPTIVE,
            this.filterReports.selectedState
          )
          .subscribe(
            (data) => {
              this.presumptiveCount = data;
            },
            (error) => this.errorHandler(error)
          );

        // Get In Care count
        this.patientService
          .countPatients(
            this.patientStatus.IN_CARE,
            this.filterReports.selectedState
          )
          .subscribe(
            (data) => {
              this.incareCount = data;
            },
            (error) => this.errorHandler(error)
          );

        // Get LTFU count
        this.patientService
          .countPatients(
            this.patientStatus.LOST_TO_FOLLOWUP,
            this.filterReports.selectedState
          )
          .subscribe(
            (data) => {
              this.ltfuCount = data;
            },
            (error) => this.errorHandler(error)
          );

          this.facilityAdmin = false;
          this.stateAdmin=false;
          this.nationalAdmin=true;

        break;
    }
  }

  getSmsStats(patientType) {
    switch (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE')) {
      case this.role.STATE_LEVEL_ADMIN:
        this.reportService
          .dashboardStatisticForState(
            patientType,
            this.selectedState
          )
          .subscribe(
            (data) => {

              this.dashboardStats.mapToStats(data);

              this.createSmsDonutChart();
            },
            (error) => this.errorHandler(error)
          );
        break;

      case this.role.FACILITY_LEVEL_ADMIN:
        this.reportService
          .dashboardStatisticForFacilities(
            patientType,
            this.facilityId
          )
          .subscribe(
            (data) => {
              this.dashboardStats.mapToStats(data);
              this.createSmsDonutChart();
            },
            (error) => this.errorHandler(error)
          );
        break;

      default:
        this.reportService.dashboardStatistic(patientType).subscribe(
          (data) => {
            this.dashboardStats.mapToStats(data);
            this.createSmsDonutChart();
          },
          (error) => this.errorHandler(error)
        );
        break;
    }
  }


  getPatientStats(patientType: PatientStageEnum) {

    this.showPreloader = true;
    switch (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE')) {
      case this.role.STATE_LEVEL_ADMIN:
        this.patientService
          .patientReportForStates(patientType,this.selectedState)
          .subscribe((data) => {
            this.showPreloader = false;
            this.createPatientBarChart(data);
          });
        break;

      case this.role.FACILITY_LEVEL_ADMIN:
        this.patientService
          .patientReportForFacilities(
            patientType,
            this.facilityId
          )
          .subscribe((data) => {
            this.showPreloader = false;
            this.createPatientBarChart(data);
          });
        break;
      default:
        this.patientService.patientReport(patientType,this.selectedState, this.facilityId).subscribe((data) => {

          this.showPreloader = false;

          this.createPatientBarChart(data);

        });
        break;
    }
  }

  /* Get Patient reports based on patient type */
  getPatientReports() {
    switch (this.reportType) {
      case this.patientStatus.PRESUMPTIVE:
        this.getSmsStats(this.patientStatus.PRESUMPTIVE);
        this.getPatientStats(this.patientStatus.PRESUMPTIVE);
        break;

      case this.patientStatus.IN_CARE:
        this.getSmsStats(this.patientStatus.IN_CARE);
        this.getPatientStats(this.patientStatus.IN_CARE);
        break;

      case this.patientStatus.LOST_TO_FOLLOWUP:
        this.getSmsStats(this.patientStatus.LOST_TO_FOLLOWUP);
        this.getPatientStats(this.patientStatus.LOST_TO_FOLLOWUP);
        break;

      default:

        break;
    }
  }

  getFilteredReports() {
    this.getCount();

    switch (this.patientStage) {
      case 'Presumptive':
        this.getSmsStats(this.patientStatus.PRESUMPTIVE);
        break;

      case 'In-Care':
        this.getSmsStats(this.patientStatus.IN_CARE);
        break;

      case 'Lost To Follow Up':
        this.getSmsStats(this.patientStatus.LOST_TO_FOLLOWUP);
        break;

      default:
        break;
    }
  }


  setPatientStage(stage) {
    this.patientStage = stage;
    // this.getFilteredReports();
    // this.facilityId = '';
    // this.selectedState = '';
    switch (this.patientStage) {
      case 'Presumptive':
        this.getSmsStats(this.patientStatus.PRESUMPTIVE);
        this.patientBarChartLabels = [
          'Total Patients Who Activated USSD',
          'Total Positive Presumptive Screening',
          'Total Negative Presumptive Screening',
        ];
        this.getPatientStats(this.patientStatus.PRESUMPTIVE);
        break;
      case 'In-Care':
        this.getSmsStats(this.patientStatus.IN_CARE);
        this.patientBarChartLabels = [
          'Total Patients Who Activated USSD',
          'Total Positive Feedback',
          'Total Negative Feedback',
        ];
        this.getPatientStats(this.patientStatus.IN_CARE);
        break;
      case 'Lost To Follow Up':
        this.getSmsStats(this.patientStatus.LOST_TO_FOLLOWUP);
        this.patientBarChartLabels = ['Contacted','Evaluated','On Treatment'];
        this.getPatientStats(this.patientStatus.LOST_TO_FOLLOWUP);
        break;
      default:
        break;
    }
  }

  createSmsDonutChart() {
    this.totalDoughnutChartData = [[this.dashboardStats.totalSmsBroadCast]];
    this.successfulDoughnutChartData = [
      [
        this.dashboardStats.totalSmsBroadCast,
        this.dashboardStats.totalSmsSuccessful,
      ],
    ];
    this.pendingDoughnutChartData = [
      [
        this.dashboardStats.totalSmsBroadCast,
        this.dashboardStats.totalSmsPending,
      ],
    ];
    this.failedDoughnutChartData = [
      [
        this.dashboardStats.totalSmsBroadCast,
        this.dashboardStats.totalSmsFailed,
      ],
    ];
  }

  createPatientBarChart(data) {
    switch (this.patientStage) {
      case 'Presumptive':
        this.presumptiveDashboardDto.mapToDashboardStats(data);

        this.patientBarChartLabels = [
          'Positive Responses',
          'Evaluated',
          'Diagnosed',
          'Placed On Treatment',
        ];
        this.presumptiveBarChartData = [
          {

            data: [
              this.presumptiveDashboardDto.positiveResponse,
              this.presumptiveDashboardDto.evaluated,
              this.presumptiveDashboardDto.diagnosed,
              this.presumptiveDashboardDto.onTreatment,
            ],
            label: 'No of Patients',
          },
        ];

        break;

      case 'In-Care':
        this.inCareDashboardDto.mapToDashboardStats(data);

        this.patientBarChartLabels = [
          // 'On Treatment',
          'Medication Adherent',
          'Medication Non-Adherent',
          'Treatment Complete',
        ];
        this.inCareBarChartData = [
          {
            data: [
              // this.inCareDashboardDto.onTreatment,
              this.inCareDashboardDto.medicationAdherent,
              this.inCareDashboardDto.nonMedicalAdherent,
              this.inCareDashboardDto.treatmentComplete,
            ],
            label: 'No of Patients',
          },
        ];
        this.getIncareCumulativeReport();
        break;

      case 'Lost To Follow Up':
        this.ltfuDashboardDto.mapToDashboardStats(data);

        this.patientBarChartLabels = ['Total Barriers Elicited','Contacted','Evaluated','Back On Treatment',];
        this.ltfuBarChartData = [
          {
            data: [
              this.ltfuDashboardDto.barriersElicited,
              this.ltfuDashboardDto.contacted,
              this.ltfuDashboardDto.evaluated,
              this.ltfuDashboardDto.onTreatment,
            ],
            label: 'No of Patients',
          },
        ];

        this.getLtfuCumulativeReport();
      default:
        break;
    }
  }

  initReload() {
    if (!localStorage.getItem('load')) {
      localStorage.setItem('load','no load');
      location.reload();
    } else {
      localStorage.removeItem('load');
    }
  }

  getReportFilterByRole() {
    switch (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE')) {
      /* If this user is a state admin, we should get states he is assigned to */
      case this.role.STATE_LEVEL_ADMIN:
        this.states = [];
        this.locationService.getStatesForStateAdmin().subscribe(
          (data) => {
            const stateArray = data;
            if (stateArray.length > 0) {
              stateArray.forEach((s) => {
                const state = new State();
                state.mapToState(s);
                this.states.push(state);
              });
            }
          },
          (error1) => {
            this.errorHandler(error1);
          }
        );
        break;

      case this.role.FACILITY_LEVEL_ADMIN:
        /* If this user is a facility admin, we should get facilities he is assigned to */
        this.facilities = [];
        this.locationService.getFacilitiesForFacilityAdmin().subscribe(
          (data) => {
            const facilityArray = data;
            if (facilityArray.length > 0) {
              facilityArray.forEach((f) => {
                const facil = new Facility();
                facil.mapToFacility(f);
                this.facilities.push(facil);
              });
            }
          },
          (error1) => {
            this.errorHandler(error1);
          }
        );
        break;

      default:
        this.facilities = [];
        this.states = [];
        break;
    }
  }

  clearFilter() {
    this.filterReports = new FilterReportsDto();
    this.getFilteredReports();
    this.facilityId = '';
    this.selectedState = '';
  }
  clearFacility(){
    this.facilityId = '';
  }

  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }

  getPresumptiveCumulativeReport() {
    this.showPreloader = true;
    /* For Facility Admin */
    if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.FACILITY_LEVEL_ADMIN) {
      this.reportService.getCumulativeReportObj1Facility(this.facilityId)
        .subscribe((data) => {
          this.presumptiveCount=data.totalPatients
          this.presumptiveCumulative.mapToPresumptiveCumulative2(data);
          this.showPreloader = false;
          return this.presumptiveCumulative;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });
    }
    //  For state admin
    else if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.STATE_LEVEL_ADMIN) {
      this.reportService.getCumulativeReportObj1State(this.selectedState)
        .subscribe((data) => {
          this.presumptiveCount=data.totalPatients
          this.presumptiveCumulative.mapToPresumptiveCumulative2(data);
          this.showPreloader = false;
          return this.presumptiveCumulative;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });
    }
    else {
      this.reportService.getCumulativeReportForObjective1(this.selectedState,this.facilityId)
        .subscribe((data) => {
          this.presumptiveCount=data.totalPatients
          this.presumptiveCumulative.mapToPresumptiveCumulative2(data);
          this.showPreloader = false;
          return this.presumptiveCumulative;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });
    }
  }

  getIncareCumulativeReport() {
    this.showPreloader = true;
    /* For Facility Admin */
    if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.FACILITY_LEVEL_ADMIN) {
      this.reportService.getCumulativeReportObj2Facility(this.facilityId)
        .subscribe((data) => {
          this.incareCount=data.totalPatients
          this.incareCumulative.mapToInCareCumulativeDto2(data);
          this.showPreloader = false;
          return this.incareCumulative;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });
    }

    //  For state admin
    else if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.STATE_LEVEL_ADMIN) {
      this.reportService.getCumulativeReportObj2State(this.selectedState)
        .subscribe((data) => {
          this.incareCount=data.totalPatients
          this.incareCumulative.mapToInCareCumulativeDto2(data);
          this.showPreloader = false;
          return this.incareCumulative;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });
    }

    else {
      this.reportService.getCumulativeReportForObjective2(this.selectedState,this.facilityId)
        .subscribe((data) => {
          this.incareCount=data.totalPatients
          this.incareCumulative.mapToInCareCumulativeDto2(data);
          this.showPreloader = false;
          return this.incareCumulative;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });
    }
  }


  getLtfuCumulativeReport() {
    this.showPreloader = true;
    /* For Facility Admin */
    if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.FACILITY_LEVEL_ADMIN) {
      this.reportService.getCumulativeReportObj3Facility(this.facilityId)
      .subscribe((data) => {
          this.ltfuCount = data.totalPatients
          this.ltfuCumulative.mapToLtfuDto2(data);
          this.showPreloader = false;
          return this.ltfuCumulative;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });
    }
    //  For state admin
    else if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.STATE_LEVEL_ADMIN) {
      this.reportService.getCumulativeReportObj3State(this.selectedState)
        .subscribe((data) => {
          this.ltfuCount = data.totalPatients
          this.ltfuCumulative.mapToLtfuDto2(data);
          this.showPreloader = false;
          return this.ltfuCumulative;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });
    }

    else {
      this.reportService.getCumulativeReportForObjective3(this.selectedState,this.facilityId)
        .subscribe((data) => {

          this.ltfuCount = data.totalPatients
          this.ltfuCumulative.mapToLtfuDto2(data);
          this.showPreloader = false;
          return this.ltfuCumulative;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });
    }
  }


  // ROUTES TO RESPECTIVE REPORT AND LIST
  routeToTotal() {
    if (this.patientStage == 'Presumptive') {
      this.router.navigate(['/patient/patient-lists/' + this.stage.PRESUMPTIVE]);
    }

    else if (this.patientStage == 'In-Care') {
      this.router.navigate(['/patient/patient-lists/' + this.stage.IN_CARE]);

    }
    else {
      this.router.navigate(['/patient/patient-lists/' + this.stage.LOST_TO_FOLLOWUP]);
    }
  }


  routeToUssdActivation() {
    if (this.patientStage == 'Presumptive') {
      this.router.navigate(['/reports/ussd-reports/Presumptive'],{ queryParams: { dialled: 'true' } });
    }

    else if (this.patientStage == 'In-Care') {
      this.router.navigate(['/reports/ussd-reports/In-Care'],{ queryParams: { dialled: 'true' } });

    }
    else {
      this.router.navigate(['/reports/ussd-reports/Lost-to-Followup'],{ queryParams: { dialled: 'true' } });
    }
  }


  routeToPositiveResponse() {
    this.router.navigate(['/patient/patient-lists/Presumptive'],{ queryParams: { status: 'POSITIVE_RESPONSE' } });
  }
  routeToEvaluated() {
    this.router.navigate(['/patient/patient-lists/Presumptive'],{ queryParams: { status: 'EVALUATED' } });
  }

  routeToDiagnosed() {
    this.router.navigate(['/patient/patient-lists/Presumptive'],{ queryParams: { status: 'DIAGNOSED' } });
  }

  routeToOnTreatment() {
    this.router.navigate(['/patient/patient-lists/Presumptive'],{ queryParams: { status: 'ON_TREATMENT' } });
  }


  routeToMedicationAdherent() {
    this.router.navigate(['/patient/patient-lists/In-Care'],{ queryParams: { status: 'MEDICATION_ADHERENT' } });
  }

  routeToNonMedicationAdherent() {
    this.router.navigate(['/patient/patient-lists/In-Care'],{ queryParams: { status: 'NON_ADHERENT' } });
  }

  routeToTreatmentCompleted() {
    this.router.navigate(['/patient/patient-lists/In-Care'],{ queryParams: { status: 'TREATMENT_COMPLETED' } });
  }

  routeToPatientContacted() {
    this.router.navigate(['/patient/patient-lists/Lost-to-Followup'],{ queryParams: { status: 'CONTACTED' } });
  }

  routeToPatientEvaluated() {
    this.router.navigate(['/patient/patient-lists/Lost-to-Followup'],{ queryParams: { status: 'EVALUATED' } });
  }

  routeToPatientBackOnTreatment() {
    this.router.navigate(['/patient/patient-lists/Lost-to-Followup'],{ queryParams: { status: 'ON_TREATMENT' } });
  }
  getAllStates() {
    this.states = [];
    this.locationService.getActiveStates()
      .subscribe(data => {
        const stateArray = data;
        if (stateArray.length > 0) {
          stateArray.forEach(s => {
            const sta = new State();
            sta.mapToState(s);
            this.states.push(sta);
          });
        }
      },error1 => {
        this.errorHandler(error1);
      });
  }

  // get Facility for facility admin users.
  public thesState:any=''
  getFacilityInState() {
    if(this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.NATIONAL_LEVEL_ADMIN){


    this.facilities = [];

    this.reportService.getAllStates().subscribe((data)=>{
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (element.name == this.selectedState) {
          this.thesState = element.id
        }
      }
    })
    this.locationService.retrieveFacilityByState(this.thesState)
      .subscribe(data => {
        const facilityArray = data;
        if (facilityArray.length > 0) {
          facilityArray.forEach(f => {
            const facil = new Facility();
            facil.mapToFacility(f);
            this.facilities.push(facil);
          });
        }
      },error1 => {
        this.errorHandler(error1);
      });
       }


  }

  getDashboardStat() {
    this.showPreloader = true;

    // let patientCategory;
    // if (this.patientStage == 'In-Care') {
    //   patientCategory = this.patientTypes.IN_CARE
    // } else if (this.patientStage == 'Lost To Follow Up') {
    //   patientCategory = this.patientTypes.LOST_TO_FOLLOWUP

    // } else if (this.patientStage == 'Presumptive') {
    //   patientCategory = this.patientTypes.PRESUMPTIVE

    // } else {
    //   return;
    // }
    // this.locationService.getDashboardCountByLocation(patientCategory,this.facilityId)
    //   .subscribe(data => {
    //     const dashboardCount = data;
    //     this.showPreloader = false;
    //     if (this.patientStage == 'In-Care') {
    //       this.incareCount = data;
    //     } else if (this.patientStage == 'Lost To Follow Up') {
    //       this.ltfuCount = data;

    //     } else if (this.patientStage == 'Presumptive') {
    //       this.presumptiveCount = data;
    //     } else {
    //       return;
    //     }
    //   },error1 => {
    //     this.errorHandler(error1);
    //   })


      // if(this.stateAdmin){
      //   this.patientService.incareStatePatientList(this.selectedState).subscribe((data)=>{
      //      this.incareCount = data.total
      //   })
      //   this.patientService.lostToFollowUpStatePatientList(this.selectedState).subscribe((data)=>{
      //      this.ltfuCount =data.total
      //   })
      //   this.patientService.presumptiveStatePatientList(this.selectedState).subscribe((data)=>{
      //     this.presumptiveCount =data + this.incareCount
      //     this.showPreloader = false;
      //   })
      // }
      if(this.facilityId){
        this.selectedState=''
      }
         this.getPresumptiveCumulativeReport();
        this.getIncareCumulativeReport();
        this.getLtfuCumulativeReport() ;
        this.getPatientReports();
  }


}
