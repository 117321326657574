import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { GeneralEnum } from '../constant/general-enum.enum';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(public httpClient: HttpClient) { }

  // Get facilities
  public getAllFacilities() {
    const url = GeneralEnum.baseUrl + '/auth/facility/all';

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  // Retrieve facility by state
  public retrieveFacilityByState(stateId: number) {
    const url = GeneralEnum.baseUrl + '/auth/facility/' + stateId;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  // Retrieve facilities for state Admin
  public getFacilitiesForStateAdmin() {
    const url = GeneralEnum.baseUrl + '/auth/facility/state';

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  // Get facilities for facility Admin
  public getFacilitiesForFacilityAdmin() {
    const url = GeneralEnum.baseUrl + '/auth/facility/admin';

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }


  // Get States
  public getAllStates() {
    const url = GeneralEnum.baseUrl + '/auth/state/all';

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  // Get Active States
  public getActiveStates() {
    const url = GeneralEnum.baseUrl + '/auth/state/active';

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  // Get States for State Admin
  public getStatesForStateAdmin() {
    const url = GeneralEnum.baseUrl + '/auth/state/admin';

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  // Get States for State Admin
  public getDashboardCountByLocation(patientType: string,facilityId: string) {
    // const url = GeneralEnum.baseUrl + `/auth/patient/${patientType}/count/${facilityId}`;
    const url = GeneralEnum.baseUrl + `/auth/patient/${patientType}/count?facilityId=${facilityId}`;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  // Error Handler
  public errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
