<div class="main-content">
    <div class="content-body">
      <div class="container-fluid">
        <div class="row page-titles mx-0">
          <div class="col-sm-6 p-md-0">
            <div class="welcome-text">
              <h4 *ngIf="patientType === patientStatus.PRESUMPTIVE">Presumptive Cumulative Reports</h4>
              <h4 *ngIf="patientType === patientStatus.IN_CARE">Incare Cumulative Reports</h4>
              <h4 *ngIf="patientType === patientStatus.LOST_TO_FOLLOWUP">Lost to followup Cumulative Reports</h4>
            </div>
          </div>
        </div>
     <div class="row">
          <div class="col-lg-12">
            <div class="card border-top border-gray-200 pt-md-4">
              <div class="card-header">
                <h5  *ngIf="patientType === patientStatus.PRESUMPTIVE" class="font-weight-semibold opensans-font mt-3 pb-md-4">Collated report for presumptive patients</h5>
                <h5  *ngIf="patientType === patientStatus.IN_CARE" class="font-weight-semibold opensans-font mt-3 pb-md-4">Collated report for incare patients</h5>
                <h5  *ngIf="patientType === patientStatus.LOST_TO_FOLLOWUP" class="font-weight-semibold opensans-font mt-3 pb-md-4">Collated report for lost to followup patients</h5>
              </div>
             <div class="card-body">
                <div *ngIf="showPreloader" class="card-body d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="bg-ddanger text-white rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                      <div class="px-0 px-md-3 py-0 py-md-2">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                          <circle cx="9" cy="7" r="4" />
                          <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                          <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                          <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                        </svg>
                        <h5 class="font-weight-medium mb-0 pt-md-3 text-white">TOTAL PATIENTS</h5>
                        <h2 *ngIf="patientType === patientStatus.PRESUMPTIVE" class="h1 d-flex align-items-center text-white">{{presumptiveCumulative.totalPatients.toLocaleString()}}</h2>
                        <h2 *ngIf="patientType === patientStatus.IN_CARE" class="h1 d-flex align-items-center text-white">{{incareCumulative.totalPatients}}</h2>
                        <h2 *ngIf="patientType === patientStatus.LOST_TO_FOLLOWUP" class="h1 d-flex align-items-center text-white">{{ltfuCumulative.totalPatients}}</h2>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="bg-dark text-white rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                      <div class="px-0 px-md-3 py-0 py-md-2">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                          <circle cx="9" cy="7" r="4" />
                          <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                          <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                          <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                        </svg>
                        <h5 class="font-weight-medium mb-0 pt-md-3 text-white">TOTAL USSD ACTIVATION</h5>
                        <h2 *ngIf="patientType === patientStatus.PRESUMPTIVE" class="h1 text-white d-flex align-items-center">{{presumptiveCumulative.totalUssdActivation}}</h2>
                        <h2 *ngIf="patientType === patientStatus.IN_CARE" class="h1 text-white d-flex align-items-center">{{incareCumulative.totalUssdActivation}}</h2>
                        <h2 *ngIf="patientType === patientStatus.LOST_TO_FOLLOWUP" class="h1 text-white d-flex align-items-center">{{ltfuCumulative.totalUssdActivation}}</h2>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="shadow border border-dark rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                      <div class="px-0 px-md-3 py-0 py-md-2">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                          <circle cx="9" cy="7" r="4" />
                          <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                          <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                          <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                        </svg>
                        <h5 *ngIf="patientType === patientStatus.PRESUMPTIVE" class="font-weight-medium mb-0 pt-md-3">TOTAL POSITIVE RESPONSES</h5>
                        <h5 *ngIf="patientType === patientStatus.IN_CARE" class="font-weight-medium mb-0 pt-md-3">TOTAL ADHERENCE TO MEDICATIONS</h5>
                        <h5 *ngIf="patientType === patientStatus.LOST_TO_FOLLOWUP" class="font-weight-medium mb-0 pt-md-3">TOTAL BARRIERS ELICITED</h5>
                        <h2 *ngIf="patientType === patientStatus.PRESUMPTIVE" class="h1 d-flex align-items-center">{{presumptiveCumulative.totalPositivePresumptiveScreening}}</h2>
                        <h2 *ngIf="patientType === patientStatus.IN_CARE" class="h1 d-flex align-items-center">{{incareCumulative.totalNonAdherenceToMedication}}</h2>
                        <h2 *ngIf="patientType === patientStatus.LOST_TO_FOLLOWUP" class="h1 d-flex align-items-center">{{ltfuCumulative.totalBarriersElicited}}</h2>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="shadow border border-dark rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                      <div class="px-0 px-md-3 py-0 py-md-2">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                          <circle cx="9" cy="7" r="4" />
                          <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                          <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                          <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                        </svg>
                        <h5 *ngIf="patientType === patientStatus.PRESUMPTIVE" class="font-weight-medium mb-0 pt-md-3 text-black">TOTAL EVALUATED</h5>
                        <h5 *ngIf="patientType === patientStatus.IN_CARE" class="font-weight-medium mb-0 pt-md-3">TOTAL NON-ADHERENCE TO MEDICATIONS</h5>
                        <h5 *ngIf="patientType === patientStatus.LOST_TO_FOLLOWUP" class="font-weight-medium mb-0 pt-md-3">TOTAL PATIENTS CONTACTED</h5>
                        <h2 *ngIf="patientType === patientStatus.PRESUMPTIVE" class="h1 text-black d-flex align-items-center">{{presumptiveCumulative.totalEvaluated}}</h2>
                        <h2 *ngIf="patientType === patientStatus.IN_CARE" class="h1 text-black d-flex align-items-center">{{incareCumulative.totalNonAdherenceToMedication}}</h2>
                        <h2 *ngIf="patientType === patientStatus.LOST_TO_FOLLOWUP" class="h1 text-black d-flex align-items-center">{{ltfuCumulative.totalNumbersContacted}}</h2>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="bg-ddanger text-white rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                      <div class="px-0 px-md-3 py-0 py-md-2">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                          <circle cx="9" cy="7" r="4" />
                          <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                          <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                          <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                        </svg>
                        <h5  *ngIf="patientType === patientStatus.PRESUMPTIVE" class="font-weight-medium mb-0 pt-md-3 text-white">TOTAL DIAGNOSED</h5>
                        <h5 *ngIf="patientType === patientStatus.IN_CARE" class="font-weight-medium mb-0 pt-md-3  text-white">TOTAL NON-ADHERENCE BARRIERS</h5>
                        <h5 *ngIf="patientType === patientStatus.LOST_TO_FOLLOWUP" class="font-weight-medium mb-0 pt-md-3  text-white">TOTAL PATIENTS EVALUATED</h5>
                        <h2 *ngIf="patientType === patientStatus.PRESUMPTIVE"  class="h1 text-white d-flex align-items-center">{{presumptiveCumulative.totalDiagnosed}}</h2>
                        <h2 *ngIf="patientType === patientStatus.IN_CARE" class="h1 text-white d-flex align-items-center ">{{incareCumulative.totalNonAdherenceBarriers}}</h2>
                        <!-- TODO -->
                        <h2 *ngIf="patientType === patientStatus.LOST_TO_FOLLOWUP" class="h1 text-white d-flex align-items-center "> 0</h2>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="bg-dark text-white rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                      <div class="px-0 px-md-3 py-0 py-md-2">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                          <circle cx="9" cy="7" r="4" />
                          <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                          <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                          <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                        </svg>
                        <h5  *ngIf="patientType === patientStatus.PRESUMPTIVE || patientType === patientStatus.LOST_TO_FOLLOWUP " class="font-weight-medium mb-0 pt-md-3 text-white">TOTAL PATIENTS ON TREATMENT</h5>
                        <h5  *ngIf="patientType === patientStatus.IN_CARE" class="font-weight-medium mb-0 pt-md-3 text-white">TOTAL TREATMENT COMPLETED PATIENTS</h5>
                        <h2 *ngIf="patientType === patientStatus.PRESUMPTIVE" class="h1 text-white d-flex align-items-center">{{presumptiveCumulative.totalOnTreatment}}</h2>
                        <h2 *ngIf="patientType === patientStatus.IN_CARE" class="h1 text-white d-flex align-items-center">{{incareCumulative.totalTreatmentCompleted}}</h2>
                        <h2 *ngIf="patientType === patientStatus.LOST_TO_FOLLOWUP" class="h1 text-white d-flex align-items-center">{{ltfuCumulative.totalPatientsOnTreatment}}</h2>
                      </div>
                    </div>
                  </div>
                </div>
             </div>
            </div>
         </div>
        </div>
      </div>
    </div>
 </div>
