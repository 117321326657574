import { NgModule } from '@angular/core';
import { RouterModule,Routes } from '@angular/router';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { LoginComponent } from './component/login/login.component';
import { InCareCumulativeReportsComponent } from './component/reports/in-care-patients/in-care-cumulative-reports/in-care-cumulative-reports.component';
import { AuthGuard } from './service/auth/auth.guard';
import { ErrorPageComponent } from './component/error-page/error-page.component';
import { InCareUssdReportsComponent } from './component/reports/in-care-patients/in-care-ussd-reports/in-care-ussd-reports.component';
import { LtfuCumulativeReportsComponent } from './component/reports/ltfu-patients/ltfu-cumulative-reports/ltfu-cumulative-reports.component';
import { LtfuUssdReportsComponent } from './component/reports/ltfu-patients/ltfu-ussd-reports/ltfu-ussd-reports.component';
import { SmsReportsComponent } from './component/reports/sms-reports/sms-reports.component';
import { StateLevelAdminComponent } from './component/setup/user-management/state-level-admin/state-level-admin.component';
import { NationalLevelAdminComponent } from './component/setup/user-management/national-level-admin/national-level-admin.component';
import { FacilityLevelAdminComponent } from './component/setup/user-management/facility-level-admin/facility-level-admin.component';
import { RoleManagementComponent } from './component/setup/role-management/role-management.component';
import { PatientComponent } from './component/patients/patient/patient.component';
import { PatientListComponent } from './component/patients/patient-list/patient-list.component';
import { ChangeForgottenPasswordComponent } from './component/change-password/change-forgotten-password.component';
import { StatePresumptiveUssdReportsComponent } from './component/reports/presumptive-patients/state-presumptive-ussd-reports/state-presumptive-ussd-reports.component';
import { StatePresumptiveCumulativeReportsComponent } from './component/reports/presumptive-patients/state-presumptive-cumulative-reports/state-presumptive-cumulative-reports.component';
import { PresumptiveCumulativeReportsComponent } from './component/reports/presumptive-patients/presumptive-cumulative-reports/presumptive-cumulative-reports.component';
import { PresumptiveUssdReportsComponent } from './component/reports/presumptive-patients/presumptive-ussd-reports/presumptive-ussd-reports.component';
import { StateInCareUssdReportsComponent } from './component/reports/in-care-patients/state-in-care-ussd-reports/state-in-care-ussd-reports.component';
import { StateInCareCumulativeReportsComponent } from './component/reports/in-care-patients/state-in-care-cumulative-reports/state-in-care-cumulative-reports.component';
import { StateLtfuUssdReportsComponent } from './component/reports/ltfu-patients/state-ltfu-ussd-reports/state-ltfu-ussd-reports.component';
import { StateLtfuCumulativeReportsComponent } from './component/reports/ltfu-patients/state-ltfu-cumulative-reports/state-ltfu-cumulative-reports.component';
import { FacilityInCareCumulativeReportsComponent } from './component/reports/in-care-patients/facility-in-care-cumulative-reports/facility-in-care-cumulative-reports.component';
import { FacilityInCareUssdReportsComponent } from './component/reports/in-care-patients/facility-in-care-ussd-reports/facility-in-care-ussd-reports.component';
import { FacilityPresumptiveCumulativeReportsComponent } from './component/reports/presumptive-patients/facility-presumptive-cumulative-reports/facility-presumptive-cumulative-reports.component';
import { FacilityPresumptiveUssdReportsComponent } from './component/reports/presumptive-patients/facility-presumptive-ussd-reports/facility-presumptive-ussd-reports.component';
import { FacilityLtfuCumulativeReportsComponent } from './component/reports/ltfu-patients/facility-ltfu-cumulative-reports/facility-ltfu-cumulative-reports.component';
import { FacilityLtfuUssdReportsComponent } from './component/reports/ltfu-patients/facility-ltfu-ussd-reports/facility-ltfu-ussd-reports.component';
import { UssdReportsComponent } from './component/reports/ussd-reports/ussd-reports.component';
import { PatientListsComponent } from './component/patients/patient-lists/patient-lists.component';
import { CumulativeReportsComponent } from './component/reports/cumulative-reports/cumulative-reports.component';
import { PatientTemplateListComponent } from './component/setup/patient-template-list/patient-template-list.component';
import { HomeComponent } from './component/home/home.component';

const routes: Routes = [
  { path: '',redirectTo: '/home',pathMatch: 'full' },
  { path: 'home',pathMatch: 'full',component: HomeComponent },
  { path: 'login',pathMatch: 'full',component: LoginComponent },
  {
    path: 'dashboard',pathMatch: 'full',component: DashboardComponent,canActivate: [AuthGuard],
  },
  {
    path: 'change-password/:token',pathMatch: 'full',component: ChangeForgottenPasswordComponent,
  },
  /* Presumptive patients */
  {
    path: 'reports/presumptive/ussd-reports',pathMatch: 'full',component: PresumptiveUssdReportsComponent,canActivate: [AuthGuard],
  },
  {
    path: 'reports/presumptive/cumulative-reports',pathMatch: 'full',component: PresumptiveCumulativeReportsComponent,canActivate: [AuthGuard],
  },
  {
    path: 'reports/presumptive/state-cumulative-reports',pathMatch: 'full',component: StatePresumptiveCumulativeReportsComponent,canActivate: [AuthGuard],
  },
  {
    path: 'reports/presumptive/state-ussd-reports',pathMatch: 'full',component: StatePresumptiveUssdReportsComponent,canActivate: [AuthGuard],
  },

  {
    path: 'reports/presumptive/facility-cumulative-reports',pathMatch: 'full',component: FacilityPresumptiveCumulativeReportsComponent,canActivate: [AuthGuard],
  },

  {
    path: 'reports/presumptive/facility-ussd-reports',pathMatch: 'full',component: FacilityPresumptiveUssdReportsComponent,canActivate: [AuthGuard],
  },
  /* In-care patients */
  {
    path: 'reports/in-care/cumulative-reports',pathMatch: 'full',component: InCareCumulativeReportsComponent,canActivate: [AuthGuard],
  },
  {
    path: 'reports/in-care/ussd-reports',pathMatch: 'full',component: InCareUssdReportsComponent,canActivate: [AuthGuard],
  },
  {
    path: 'reports/in-care/state-cumulative-reports',pathMatch: 'full',component: StateInCareCumulativeReportsComponent,canActivate: [AuthGuard],
  },
  {
    path: 'reports/in-care/state-ussd-reports',pathMatch: 'full',component: StateInCareUssdReportsComponent,canActivate: [AuthGuard],
  },
  {
    path: 'reports/in-care/facility-cumulative-reports',pathMatch: 'full',component: FacilityInCareCumulativeReportsComponent,canActivate: [AuthGuard],
  },
  {
    path: 'reports/in-care/facility-ussd-reports',pathMatch: 'full',component: FacilityInCareUssdReportsComponent,canActivate: [AuthGuard],
  },
  /* LFTU patients */
  {
    path: 'reports/ltfu/cumulative-reports',pathMatch: 'full',component: LtfuCumulativeReportsComponent,canActivate: [AuthGuard],
  },
  {
    path: 'reports/ltfu/ussd-reports',pathMatch: 'full',component: LtfuUssdReportsComponent,canActivate: [AuthGuard],
  },
  {
    path: 'reports/ltfu/state-cumulative-reports',pathMatch: 'full',component: StateLtfuCumulativeReportsComponent,canActivate: [AuthGuard],
  },
  {
    path: 'reports/ltfu/state-ussd-reports',pathMatch: 'full',component: StateLtfuUssdReportsComponent,canActivate: [AuthGuard],
  },
  {
    path: 'reports/ltfu/facility-cumulative-reports',pathMatch: 'full',component: FacilityLtfuCumulativeReportsComponent,canActivate: [AuthGuard],
  },
  {
    path: 'reports/ltfu/facility-ussd-reports',pathMatch: 'full',component: FacilityLtfuUssdReportsComponent,canActivate: [AuthGuard],
  },

  /* SMS Report */
  {
    path: 'reports/sms-reports',pathMatch: 'full',component: SmsReportsComponent,canActivate: [AuthGuard],
  },

  // User Management Route
  {
    path: 'setup/user-management/state-level-admin',pathMatch: 'full',component: StateLevelAdminComponent,canActivate: [AuthGuard],
  },
  {
    path: 'setup/user-management/national-level-admin',pathMatch: 'full',component: NationalLevelAdminComponent,canActivate: [AuthGuard],
  },
  {
    path: 'setup/user-management/facility-level-admin',pathMatch: 'full',component: FacilityLevelAdminComponent,canActivate: [AuthGuard],
  },

  /* Role Management */
  {
    path: 'setup/role-management',pathMatch: 'full',component: RoleManagementComponent,canActivate: [AuthGuard],
  },
  {
    path: 'setup/patient-template-list',pathMatch: 'full',component: PatientTemplateListComponent,canActivate: [AuthGuard],
  },

  /* Patients */
  {
    path: 'patient/create-patient',pathMatch: 'full',component: PatientComponent,canActivate: [AuthGuard],
  },
  {
    path: 'patient/patient-list',pathMatch: 'full',component: PatientListComponent,canActivate: [AuthGuard],
  },
  {
    path: 'patient/patient-lists/:patientTypeRoute',pathMatch: 'full',component: PatientListsComponent,canActivate: [AuthGuard],
  },

  /* USSD Reports */
  {
    path: 'reports/ussd-reports/:reportType',pathMatch: 'full',component: UssdReportsComponent,canActivate: [AuthGuard],
  },

  /* CUMULATIVE Reports */
  {
    path: 'reports/cumulative-reports/:patientType',pathMatch: 'full',component: CumulativeReportsComponent,canActivate: [AuthGuard],
  },

  /* Error page */
  { path: '**',pathMatch: 'full',component: ErrorPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
