import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';
import { UserDto } from './../../../../dto/user-dto';
import { GeneralUtil } from './../../../../util/general-util';
import { User } from './../../../../entity/user';
import { UserService } from './../../../../service/user.service';
import { RoleEnum } from './../../../../constant/role-enum';
import { Facility } from './../../../../entity/facility';
import { State } from './../../../../entity/state';
import { Role } from './../../../../entity/role';
import {LocationService} from '../../../../service/location.service';
import {GeneralEnum} from '../../../../constant/general-enum.enum';
import {PaginationDataDto} from '../../../../dto/pagination-data-dto';

declare function successToast(msg): any;
declare function failureToast(msg): any;

@Component({
  selector: 'app-national-level-admin',
  templateUrl: './national-level-admin.component.html',
  styleUrls: ['./national-level-admin.component.css']
})
export class NationalLevelAdminComponent implements OnInit {
  public users: User[] = [];
  public viewUser = new User();
  public showPreloader = false;
  public facilities: Facility[] = [];
  public states: State[] = [];
  public userDto = new UserDto();
  public roles: Role[] = [];
  public selectedFacilities = [];
  public selectedStates = [];
  public paginationData = new PaginationDataDto();  // pagination object
  public currentPage: number;
  public defaultLimit: number;

  @ViewChild('close') close: ElementRef;
  @ViewChild('closeModal') closeModal: ElementRef;

  constructor(public userService: UserService,
              public locationService: LocationService) { }

  ngOnInit(): void {
    this.initializePaginationData();
    this.getNationalAdmin();
    this.getFacilities();
    this.getAllStates();
    this.getRoles();
  }

  numbersOnly(event){
    return GeneralUtil.numbersOnly(event);
  }

  initializePaginationData() {
    this.paginationData.currentPage = 1;
    this.paginationData.defaultLimit = GeneralEnum.paginationLimit;
    this.paginationData.totalSize = 100;
  }

  removeUnderscore(mystring: string): string {
    return GeneralUtil.removeUnderscore(mystring)
  }

  getFacilities() {
    this.facilities = [];
    this.locationService.getAllFacilities()
      .subscribe(data => {
        const facilityArray = data;
        if (facilityArray.length > 0) {
          facilityArray.forEach(f => {
            const fac = new Facility();
            fac.mapToFacility(f);
            this.facilities.push(fac);
          });
        }
      }, error1 => {
        this.errorHandler(error1);
      })
  }

  getAllStates() {
    this.states = [];
    this.showPreloader = true;
    this.locationService.getActiveStates()
      .subscribe(data => {
        this.showPreloader = false;
        const stateArray = data;
        if (stateArray.length > 0) {
          stateArray.forEach(s => {
            const sta = new State();
            sta.mapToState(s);
            this.states.push(sta);
          });
        }
      }, error1 => {
        this.showPreloader = false;
        this.errorHandler(error1);
      });
  }

  getNationalAdmin() {
    this.defaultLimit = this.paginationData.defaultLimit;
    this.currentPage = this.paginationData.currentPage;
    this.showPreloader = true;
    this.userService.getUsers(RoleEnum.NATIONAL_LEVEL_ADMIN, (this.currentPage - 1), this.defaultLimit)
      .subscribe(data => {
        this.showPreloader = false;
        this.users = [];
        this.paginationData.totalSize = data.total;
        if (data.total > 0) {
          const nationalArray = data.entities;
          nationalArray.forEach(r => {
              const state = new User();
              state.mapToUser(r);
              this.users.push(state);
            });
          return this.users;
        }
      }, error1 => {
        this.errorHandler(error1);
      });
  }

  getRoles() {
    this.roles = [];
    this.userService.getAllRoles()
      .subscribe(data => {
        const roleArray = data;
        if (roleArray.length > 0) {
          this.roles = roleArray.filter(role => {
            return role.name == RoleEnum.NATIONAL_LEVEL_ADMIN;
          })
          this.userDto.roleId = this.roles[0].id;
        }
      }, error1 => {
        this.errorHandler(error1);
      })
  }

  createUser() {
    this.showPreloader = true;
    this.userDto.facilityIdsForFacilityAdmin = this.selectedFacilities;
    this.userDto.stateIdsForStateAdmin = this.selectedStates;

    this.userService.createUser(this.userDto)
      .subscribe(data => {
        successToast('User created successfully');
        setTimeout(() => {
          this.showPreloader = false;
          this.userDto = new UserDto();
          this.close.nativeElement.click();
          this.userDto.roleId = this.roles[0].id;

          this.getNationalAdmin();
        }, 1000)
      }, error1 => {
        this.showPreloader = false;
        this.errorHandler(error1);
      });

  }

  getSingleUser(userId: number) {
    this.selectedFacilities = []
    this.selectedStates = [];
    this.userService.getUserById(userId)
      .subscribe(data => {
        this.viewUser = new User();
        setTimeout(() => {
          this.viewUser.mapToUser(data);
        }, 1000)
      }, error1 => {
        this.errorHandler(error1);
      })
  }

  editUser(userId: number) {
    this.selectedFacilities = [];
    this.selectedStates = [];
    this.userService.getUserById(userId)
      .subscribe(data => {
        this.viewUser.mapToUser(data);
      }, error1 => {
        this.errorHandler(error1);
      })
  }

  updateUser() {
    this.showPreloader = true;
    this.userService.updateUser(this.viewUser)
      .subscribe(data => {
        successToast('User updated successfully');
        setTimeout(() => {
          this.showPreloader = false;
          this.closeModal.nativeElement.click();
          this.getNationalAdmin();
        }, 1000)

      }, error1 => {
        this.showPreloader = false;
        this.errorHandler(error1);
      })

  }

  toggleUserStatus(id, status: string) {
    this.showPreloader = true;
    if (status == 'ACTIVE') {
      status = 'INACTIVE'
    } else {
      status = 'ACTIVE'
    }
    this.userService.toggleUserStatus(id, status)
      .subscribe(data => {
        this.showPreloader = false;
        successToast('User status updated successfully');
        this.getNationalAdmin();
      },
        error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        })
  }

  // Error handling
  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
