<div class="main-content">
  <div class="content-body">
    <div class="container-fluid">

      <div class="row page-titles pt-2 mx-0">
        <div class="col-sm-6 p-md-0">
          <div class="welcome-text">
            <h4>Patient List</h4>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card border-top border-gray-200 pt-md-4">
            <div class="input-group w-50 mx-4">
              <input type="tel" maxlength="13" class="form-control" placeholder="Search by phone number" [(ngModel)]="phoneNumber"
                value="{{phoneNumber}}" (keypress)="numbersOnly($event)"
                (keyup.enter)="getPatients()"
                (blur)="getPatients()">
              <div class="input-group-append" style="height: 41px;">
                <button class="btn btn-ddanger" type="button"
                  (click)="getPatients()">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search" width="20" height="20"
                    viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="10" cy="10" r="7" />
                    <line x1="21" y1="21" x2="15" y2="15" />
                  </svg>
                </button>
              </div>
            </div>
            <div class="card-body">
              <div *ngIf="showPreloader" class="card-body d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <div style="text-align: center; margin: 0 ;" *ngIf="patients.length < 1 && showPreloader === false">
                <img src="assets/images/empty-data.png" height="200px">
                <div>NO DATA AVAILABLE</div>
              </div>
              <div class="table-responsive">
                <table class="table table-borderless card-table table-nowrap">
                  <thead *ngIf="patients.length > 0">
                  <tr>
                    <th><strong>#</strong></th>
                    <th><strong>GENDER</strong></th>
                    <th><strong>AGE</strong></th>
                    <th><strong>PHONE NUMBER</strong></th>
                    <th><strong>STATE</strong></th>
                    <th><strong>FACILITY</strong></th>
                    <th><strong>STATUS</strong></th>
                    <th><strong>LAST SMS SENT DATE</strong></th>
                    <th><strong>LAST SMS SENT STATUS</strong></th>
                  </tr>
                  </thead>
                  <tbody *ngFor="let patient of patients; index as i">
                  <tr>
                    <td *ngIf="currentPage === 1"><strong>{{((i + 1))}}</strong></td>
                    <td *ngIf="currentPage > 1 "><strong>{{((i + 1) + (defaultLimit * (currentPage - 1)))}}</strong></td>
                    <td>{{patient.gender ? patient.gender : ''}}</td>
                    <td>{{patient.age ? patient.age : ''}}</td>
                    <td>{{patient.phone ? patient.phone : ''}}</td>
                    <td>{{patient.state ? patient.state.name : ''}}</td>
                    <td>{{patient.facility ? patient.facility.name : ''}}</td>
                    <td>{{ patient.status ? removeUnderscore(patient.status) : ''}}</td>
                    <td>{{patient.lastSmsSentDate}}</td>
                    <td>{{patient.lastSmsSentStatus}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="pagination pagination-centered mt-4" *ngIf="patients.length  > 0">
                <ngb-pagination [collectionSize]="paginationData.totalSize" [(page)]="paginationData.currentPage"
                                (pageChange)="getPatients()"
                                [pageSize]="paginationData.defaultLimit" [maxSize]="5" [rotate]="true" [boundaryLinks]="false">

                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
