import { State } from './state';
import { Facility } from './facility';
import { GeneralUtil } from './../util/general-util';

export class Patient {
  public id: number;
  public firstName: string;
  public lastName: string;
  public gender: string;
  public dob: string;
  public age: string;
  public phone: string;
  public status: string;
  public lga: string;
  public lastSmsSentDate: string;
  public lastSmsSentStatus: string;
  public facility = new Facility();
  public state = new State();

  public mapToPatient(data) {
    if (GeneralUtil.isValidJSON(data)) {
      this.id = data.id
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.gender = data.gender;
      this.dob = data.dob;
      this.age = data.age;
      this.phone = data.phone;
      this.status = data.status;
      this.lga = data.lga;
      data.lastSmsSentDate === 'N/A' ? this.lastSmsSentDate = data.lastSmsSentDate :
        this.lastSmsSentDate = GeneralUtil.convertIonDateTimeToDateTimeString(data.lastSmsSentDate);

      this.lastSmsSentStatus = data.lastSmsSentStatus;
      this.facility.mapToFacility(data.facility);
      this.state.mapToState(data.stateDto);
    }
  }
}
