import { AuthService } from './../../../service/auth/auth.service';
import { LtfuCumulativeDto2 } from './../../../dto/ltfu-cumulative2-dto';
import { InCareCumulativeDto2 } from './../../../dto/in-care-cumulative2-dto';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientStatusEnum } from 'src/app/constant/patient-status-enum';
import { FilterReportsDto } from 'src/app/dto/filter-reports-dto';
import { PresumptiveCumulativeDto } from 'src/app/dto/presumptive-cumulative-dto';
import { PresumptiveCumulativeDto2 } from 'src/app/dto/presumptive-cumulative-reports-2-dto';
import { ReportService } from 'src/app/service/report.service';
import { GeneralUtil } from 'src/app/util/general-util';
import { RoleEnum } from 'src/app/constant/role-enum';

@Component({
  selector: 'app-cumulative-reports',
  templateUrl: './cumulative-reports.component.html',
  styleUrls: ['./cumulative-reports.component.css']
})
export class CumulativeReportsComponent implements OnInit {
  public patientStatus = PatientStatusEnum;
  public report: any;
  public facilityId;
  public patientType: string;
  public showPreloader = false;
  public filterReports = new FilterReportsDto();
  public presumptiveCumulative = new PresumptiveCumulativeDto2();
  public incareCumulative = new InCareCumulativeDto2();
  public ltfuCumulative = new LtfuCumulativeDto2();
  public role = RoleEnum;


  constructor( public route: ActivatedRoute,
    public reportService: ReportService,
    public authService: AuthService) { }

  ngOnInit(): void {
   this.report = this.route.snapshot.params.patientType;
   this.report = this.route.params.subscribe((params) => {
   this.patientType = params.patientType;
   this.getCumulativeReports();
});

}
  getCumulativeReports() {
    if (this.patientType === this.patientStatus.PRESUMPTIVE) {
      this.getCumulativeReportPresumptive();
    } else if (this.patientType === this.patientStatus.IN_CARE) {
      this.getCumulativeReportIncare();
    } else {
      this.getCumulativeReportLtfu();
    }
  }

  // PRESUMPTIVE PATIENTS
  getCumulativeReportPresumptive() {
    this.showPreloader = true;
    /* For Facility Admin */
    if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.FACILITY_LEVEL_ADMIN) {
      this.reportService.getCumulativeReportObj1Facility()
        .subscribe((data) => {
          this.presumptiveCumulative.mapToPresumptiveCumulative2(data);
          this.showPreloader = false;
          return this.presumptiveCumulative;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });
    }
    //  For state admin
    else if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.STATE_LEVEL_ADMIN) {
      this.reportService.getCumulativeReportObj1State()
        .subscribe((data) => {
          this.presumptiveCumulative.mapToPresumptiveCumulative2(data);
          this.showPreloader = false;
          return this.presumptiveCumulative;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });
    }
    else {
      this.reportService.getCumulativeReportForObjective1()
        .subscribe((data) => {
          this.presumptiveCumulative.mapToPresumptiveCumulative2(data);
          this.showPreloader = false;
          return this.presumptiveCumulative;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });
    }

  }


// INCARE PATIENTS
  getCumulativeReportIncare() {
    this.showPreloader = true;
    /* For Facility Admin */
    if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.FACILITY_LEVEL_ADMIN) {
      this.reportService.getCumulativeReportObj2Facility()
        .subscribe((data) => {
          this.incareCumulative.mapToInCareCumulativeDto2(data);
          this.showPreloader = false;
          return this.incareCumulative;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });
    }

    //  For state admin
    else if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.STATE_LEVEL_ADMIN) {
      this.reportService.getCumulativeReportObj2State()
        .subscribe((data) => {
          this.incareCumulative.mapToInCareCumulativeDto2(data);
          this.showPreloader = false;
          return this.incareCumulative;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });
    }

    else {
      this.reportService.getCumulativeReportForObjective2()
        .subscribe((data) => {
          this.incareCumulative.mapToInCareCumulativeDto2(data);
          this.showPreloader = false;
          return this.incareCumulative;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });
    }
  }

  // LTFU PATIENTS
  getCumulativeReportLtfu() {
    this.showPreloader = true;
    /* For Facility Admin */
    if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.FACILITY_LEVEL_ADMIN) {
      this.reportService.getCumulativeReportObj3Facility()
        .subscribe((data) => {
          this.ltfuCumulative.mapToLtfuDto2(data);
          this.showPreloader = false;
          return this.ltfuCumulative;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });
    }
    //  For state admin
    else if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.STATE_LEVEL_ADMIN) {
      this.reportService.getCumulativeReportObj3State()
        .subscribe((data) => {
          this.ltfuCumulative.mapToLtfuDto2(data);
          this.showPreloader = false;
          return this.ltfuCumulative;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });
    }

    else {
      this.reportService.getCumulativeReportForObjective3()
        .subscribe((data) => {
          this.ltfuCumulative.mapToLtfuDto2(data);
          this.showPreloader = false;
          return this.ltfuCumulative;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });
    }
  }


  // Error handling
  public errorHandler(error) {
  return GeneralUtil.errorHandler(error);
  }

}