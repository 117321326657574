import { Component, OnInit } from '@angular/core';
import { GeneralUtil } from './../../../util/general-util';
import { Facility } from './../../../entity/facility';
import { State } from './../../../entity/state';
import { LocationService } from '../../../service/location.service';
import { PatientDto } from './../../../dto/patient-dto';
import { PatientStatusEnum } from './../../../constant/patient-status-enum';
import { PatientService } from './../../../service/patient.service';
import { Router } from '@angular/router';

declare function successToast(msg): any;

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css']
})
export class PatientComponent implements OnInit {
  public facilities: Facility[] = [];
  public states: State[] = [];
  public patient = new PatientDto();
  public status = PatientStatusEnum;
  constructor(
    public locationService: LocationService,
    public patientService: PatientService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.getFacilities();
    this.getAllStates();
  }

  getFacilities() {
    this.facilities = [];
    this.locationService.getAllFacilities()
      .subscribe(data => {
        const facilityArray = data;
        if (facilityArray.length > 0) {
          facilityArray.forEach(f => {
            const fac = new Facility();
            fac.mapToFacility(f);
            this.facilities.push(fac);
          });
        }
      }, error1 => {
        this.errorHandler(error1);
      })
  }

  numbersOnly(event){
    return GeneralUtil.numbersOnly(event);
  }

  getAllStates() {
    this.states = [];

    this.locationService.getActiveStates()
      .subscribe(data => {
        const stateArray = data;
        if (stateArray.length > 0) {
          stateArray.forEach(s => {
            const sta = new State();
            sta.mapToState(s);
            this.states.push(sta);
          });
        }
      }, error1 => {
        this.errorHandler(error1);
      })
  }

  createPatient() {
    this.patient.dob = GeneralUtil.reverseDateFormat(this.patient.dob);
    this.patientService.createPatient(this.patient)
      .subscribe(data => {
        successToast('Patient Registered Successfully');
        setTimeout(() => { this.router.navigate(['/patient/patient-list'])}, 500);
    }, error1 => {
      this.errorHandler(error1);
    })
  }

  removeUnderscore(mystring: string): string {
    return GeneralUtil.removeUnderscore(mystring)
  }

  // Error handling
  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
