<div class="main-content">
  <div class="content-body">
    <div class="container-fluid">

      <div class="row page-titles pt-2 mx-0">
        <div class="col-sm-6 p-md-0">
          <div class="welcome-text">
            <h4>Create Patient</h4>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card border-top border-gray-200 pt-md-4 p-4">
            <form #patientCreation="ngForm" (ngSubmit)="createPatient()">
              <div class="row">
                <div class="col-md-6">
                  <label for="firstname" class="form-label">First Name</label>
                  <input id="firstname" type="text" class="form-control mb-2" name="firstname"
                    [(ngModel)]="patient.firstName">
                </div>
                <div class="col-md-6">
                  <label for="lastname" class="form-label">Last Name</label>
                  <input id="lastname" type="text" class="form-control mb-2" name="lastName"
                    [(ngModel)]="patient.lastName">
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label for="gender" class="form-label">Gender</label>
                  <select name="gender" class="form-select" id="" [(ngModel)]="patient.gender">
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="dob" class="form-label">Date of Birth</label>
                  <input id="dob" type="date" class="form-control mb-2" name="dob" [(ngModel)]="patient.dob">
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label for="phonenumber" class="form-label">Phone Number</label>
                  <input id="phonenumber" type="tel" class="form-control mb-2" name="phoneNumber" max="11"
                         (keypress)="numbersOnly($event)" [(ngModel)]="patient.phone">
                </div>
                <div class="col-md-6">
                  <label class="mb-2">
                    Stage
                  </label>
                  <select class="form-select mb-2" name="stage" [(ngModel)]="patient.status">
                    <option *ngFor="let stat of status | keyvalue" value="{{stat.value}}">{{removeUnderscore(stat.value)}}</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label class="mb-2">
                    State
                  </label>
                  <select class="form-select mb-2" name="state" [(ngModel)]="patient.stateId">
                    <option value=""></option>
                    <option *ngFor="let state of states" value="{{state.id}}">{{state.name}}</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label class="mb-2">
                    LGA
                  </label>
                  <input type="text" class="form-control" id="lga" name="lga" [(ngModel)]="patient.lga">
                </div>
                <div class="col-md-6">
                  <label class="mb-2">
                    Facility
                  </label>
                  <select class="form-select mb-2" name="facility" [(ngModel)]="patient.facilityId">
                    <option value=""></option>
                    <option *ngFor="let facility of facilities" value="{{facility.id}}">{{facility.name}}</option>
                  </select>
                </div>
              </div>
              <div class="text-right mt-4">
                <button type="submit" class="btn btn-ddanger m-1">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-plus"
                    viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round"
                    stroke-linejoin="round" style="width: 25px;">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 11h6m-3 -3v6" />
                  </svg>Create Patient</button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
