<ng-container   *ngIf="nothome">
 <app-header *ngIf="authService.isLoggedIn()"></app-header>
 <app-side-menu *ngIf="authService.isLoggedIn()"></app-side-menu>
</ng-container>
<router-outlet></router-outlet>


<button class="btn btn-outline-danger mx-2" hidden data-bs-toggle="modal"
              data-bs-target="#logoutModal" #idleModal>Sign Out</button>

<div class="modal fade" id="logoutModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">You Have Been Idle!</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" #close>
        </button>
      </div>
      <div class="modal-body">
        <span class="text-danger">{{idleState}}</span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger light" (click)="logout()">Logout</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="reset()">Stay</button>
      </div>
    </div>
  </div>
</div>
