<div class="main-content">
    <div class="content-body">
        <div class="container-fluid">

            <div class="row page-titles pt-2 m-text">
                <div class="col-sm-6 p-md-0">
                    <div class="welcome-text">
                        <h4>National Level Admin</h4>
                    </div>
                </div>
                <div class="col-sm-6 p-md-0">
                    <button type="button" class="btn btn-ddanger m-1" data-bs-toggle="modal"
                            data-bs-target=".bd-example-modal-lg" style="float: right;"><svg
                            xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user-plus"
                            viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round"
                            stroke-linejoin="round" style="width: 25px;">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx="9" cy="7" r="4" />
                            <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                            <path d="M16 11h6m-3 -3v6" />
                        </svg>Create National Level User</button>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card border-top border-gray-200 pt-md-4">
                        <div class="card-header d-flex justify-content-between">
                            <h5 class="font-weight-semibold opensans-font mt-3 pb-md-4">List of Users </h5>
                        </div>
                        <div class="card-body">
                            <div style="text-align: center; margin: 0 ;" *ngIf="users.length < 1 && showPreloader === false">
                                <img src="assets/images/empty-data.png" height="200px">
                                <div>NO DATA AVAILABLE</div>
                            </div>

                            <div class="table-responsive">
                                <table class="table table-borderless card-table table-nowrap">
                                    <thead *ngIf="users.length > 0">
                                        <tr>
                                            <th><strong>#</strong></th>
                                            <th><strong>FIRSTNAME</strong></th>
                                            <th><strong>LASTNAME</strong></th>
                                            <th><strong>PHONE</strong></th>
                                            <th><strong>EMAIL</strong></th>
                                            <th><strong>STATUS</strong></th>
                                            <th style="text-align: center;"><strong>ACTIONS</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let user of users; index as i">
                                            <td *ngIf="currentPage === 1"><strong>{{((i + 1))}}</strong></td>
                                            <td *ngIf="currentPage > 1 "><strong>{{((i + 1) + (defaultLimit * (currentPage - 1)))}}</strong></td>                                            <td>{{user.firstName}}</td>
                                            <td>{{user.lastName}}</td>
                                            <td>{{user.phone}}</td>
                                            <td>{{user.email}}</td>
                                            <td><span class="badge light" [ngClass]="{'badge-success': user.status == 'ACTIVE',
                                                'badge-danger': user.status == 'INACTIVE'}">{{user.status}}</span></td>
                                            <td style="display: flex">

                                                <div class="icon-space" title="View User">
                                                    <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal" (click)="getSingleUser(user.id)"
                                                        class="icon icon-tabler icon-tabler-eye" width="25" height="25"
                                                        viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50"
                                                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <circle cx="12" cy="12" r="2" />
                                                        <path
                                                            d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                                                    </svg>
                                                </div>

                                                <div class="icon-space" title="Edit User">
                                                    <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal"
                                                        data-bs-target=".bd-example-modal-lgL"
                                                        class="icon icon-tabler icon-tabler-edit"
                                                        (click)="editUser(user.id)" width="25" height="25"
                                                        viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50"
                                                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <path
                                                            d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                        <line x1="16" y1="5" x2="19" y2="8" />
                                                    </svg>
                                                </div>

                                                <div class="icon-space" role="button" title="Toggle status" *ngIf="user.status == 'ACTIVE'"  (click)="toggleUserStatus(user.id, user.status)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-toggle-right" width="25" height="25" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <circle cx="16" cy="12" r="2" />
                                                        <rect x="2" y="6" width="20" height="12" rx="6" />
                                                      </svg>
                                                  </div>

                                                  <div class="icon-space" role="button" title="Toggle status" *ngIf="user.status == 'INACTIVE'" (click)="toggleUserStatus(user.id, user.status)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-toggle-left" width="25" height="25" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <circle cx="8" cy="12" r="2" />
                                                        <rect x="2" y="6" width="20" height="12" rx="6" />
                                                      </svg>
                                                  </div>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div *ngIf="showPreloader" class="card-body d-flex justify-content-center">
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>

                                <div class="pagination pagination-centered mt-4" *ngIf="users.length > 0">
                                  <ngb-pagination [collectionSize]="paginationData.totalSize" [(page)]="paginationData.currentPage"
                                                  (pageChange)="getNationalAdmin()"
                                                  [pageSize]="paginationData.defaultLimit" [maxSize]="5" [rotate]="true"
                                                  [boundaryLinks]="false">

                                  </ngb-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <!----------------------Modal to Create User-------------------->
            <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content shadow-dark-80">
                        <form #userForm="ngForm" (ngSubmit)="userForm.form.valid && createUser()">
                            <div class="modal-header border-0 pb-0 align-items-start ps-4">
                                <h5 class="modal-title pt-3" id="create-user">Create user</h5>
                                <button type="button" class="btn btn-icon p-0" #close data-bs-dismiss="modal"
                                    aria-label="Close">
                                    <svg data-name="icons/tabler/close" xmlns="http://www.w3.org/2000/svg" width="16"
                                        height="16" viewBox="0 0 16 16">
                                        <rect data-name="Icons/Tabler/Close background" width="16" height="16"
                                            fill="none"></rect>
                                        <path
                                            d="M.82.1l.058.05L6,5.272,11.122.151A.514.514,0,0,1,11.9.82l-.05.058L6.728,6l5.122,5.122a.514.514,0,0,1-.67.777l-.058-.05L6,6.728.878,11.849A.514.514,0,0,1,.1,11.18l.05-.058L5.272,6,.151.878A.514.514,0,0,1,.75.057Z"
                                            transform="translate(2 2)" fill="#1e1e1e"></path>
                                    </svg>
                                </button>
                            </div>
                            <div class="modal-body pt-2 px-4">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="firstname" class="form-label">First Name</label>
                                        <input id="firstname" type="text" class="form-control" name="firstname"
                                            [(ngModel)]="userDto.firstName" required>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="lastname" class="form-label">Last Name</label>
                                        <input id="lastname" type="text" class="form-control" name="lastName"
                                            [(ngModel)]="userDto.lastName" required>
                                    </div>
                                </div><br>

                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="email" class="form-label">Email</label>
                                        <input id="email" type="email" class="form-control" name="Email"
                                            [(ngModel)]="userDto.email" required>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="phone" class="form-label">Phone</label>
                                        <input id="phone" type="tel" class="form-control" name="Phone" maxlength="11"
                                               (keypress)="numbersOnly($event)" [(ngModel)]="userDto.phone" required>
                                    </div>
                                </div><br>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-light px-2" data-bs-dismiss="modal"><span
                                        class="px-1">Cancel</span></button>
                                <button type="submit" [disabled]="userForm.invalid"
                                    class="btn btn-ddanger px-2 ms-2"><span class="px-1">Create</span></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <!------ Modal To view single user ----------------->
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content shadow-dark-80" *ngIf="viewUser">
                        <div class="modal-header border-0 pb-0 align-items-start ps-4">
                            <h6 class="modal-title pt-3" id="view-user">User Details</h6>
                            <button type="button" class="btn btn-icon p-0" data-bs-dismiss="modal" aria-label="Close">
                                <svg data-name="icons/tabler/close" xmlns="http://www.w3.org/2000/svg" width="16"
                                    height="16" viewBox="0 0 16 16">
                                    <rect data-name="Icons/Tabler/Close background" width="16" height="16" fill="none">
                                    </rect>
                                    <path
                                        d="M.82.1l.058.05L6,5.272,11.122.151A.514.514,0,0,1,11.9.82l-.05.058L6.728,6l5.122,5.122a.514.514,0,0,1-.67.777l-.058-.05L6,6.728.878,11.849A.514.514,0,0,1,.1,11.18l.05-.058L5.272,6,.151.878A.514.514,0,0,1,.75.057Z"
                                        transform="translate(2 2)" fill="#1e1e1e"></path>
                                </svg>
                            </button>
                        </div>
                        <div class="modal-body pt-2 px-4">
                            <ul class="list-group">
                                <li class="bg-gray-100"><span>
                                        <h4 class="heading">{{viewUser.firstName | uppercase}} {{viewUser.lastName |
                                            uppercase}}</h4>
                                    </span></li>
                                <li><span>Phone: <b>{{viewUser.phone}}</b></span></li>
                                <li><span>Email: <b>{{viewUser.email}}</b></span></li>
                                <li><span>Status: <b>{{viewUser.status}}</b></span></li>
                                <li><span>Role: <b>{{removeUnderscore(viewUser.role)}}</b></span></li>
                            </ul>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-ddanger px-2 ms-2" data-bs-dismiss="modal"><span
                                    class="px-1">Close</span></button>
                        </div>
                    </div>
                </div>
            </div>



            <!----------------------Modal to Update User-------------------->
            <div class="modal fade bd-example-modal-lgL" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content shadow-dark-80">

                        <div class="modal-header border-0 pb-0 align-items-start ps-4">
                            <h5 class="modal-title pt-3" id="update-user">Edit Details</h5>
                            <button type="button" class="btn btn-icon p-0" #close data-bs-dismiss="modal" #closeModal
                                aria-label="Close">
                                <svg data-name="icons/tabler/close" xmlns="http://www.w3.org/2000/svg" width="16"
                                    height="16" viewBox="0 0 16 16">
                                    <rect data-name="Icons/Tabler/Close background" width="16" height="16" fill="none">
                                    </rect>
                                    <path
                                        d="M.82.1l.058.05L6,5.272,11.122.151A.514.514,0,0,1,11.9.82l-.05.058L6.728,6l5.122,5.122a.514.514,0,0,1-.67.777l-.058-.05L6,6.728.878,11.849A.514.514,0,0,1,.1,11.18l.05-.058L5.272,6,.151.878A.514.514,0,0,1,.75.057Z"
                                        transform="translate(2 2)" fill="#1e1e1e"></path>
                                </svg>
                            </button>
                        </div>
                        <div class="modal-body pt-2 px-4">
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="Firstname" class="form-label">First Name</label>
                                    <input type="text" class="form-control" name="firstname"
                                        [(ngModel)]="viewUser.firstName" required>
                                </div>
                                <div class="col-md-6">
                                    <label for="lastname" class="form-label">Last Name</label>
                                    <input type="text" class="form-control" name="lastName"
                                        [(ngModel)]="viewUser.lastName" required>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-md-6">
                                    <label for="phone" class="form-label">Phone</label>
                                    <input type="tel" class="form-control" name="Phone" maxlength="11"
                                           (keypress)="numbersOnly($event)" [(ngModel)]="viewUser.phone" required>
                                </div>
                            </div><br>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light px-2" data-bs-dismiss="modal"><span
                                    class="px-1">Cancel</span></button>
                            <button type="submit" (click)="updateUser()" class="btn btn-ddanger px-2 ms-2"><span
                                    class="px-1">Update</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
