import {GeneralUtil} from '../util/general-util';
import {PatientCategoryEnum} from '../constant/patient-category-enum';

export class PresumptiveUssdDto {

  public patientNumber: string;
  public state: string;
  public presumptiveScreening: string;
  public presumptiveScreeningSorter = 0;
  public patientSymptom: string;
  public healthCenterVisited: string;
  public healthCenterVisitedSorter = 0;
  public barrierToHealthCenter: string;
  public timeStamp: any;
  public facility: string;
  public lga: string;
  public gender: string;
  public age: number;
  public contactWithTB: string;

  constructor() { }

  public mapToDto(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.patientNumber = data.patientNumber;
      this.state = data.state;
      switch (data.presumptiveScreening) {
        case 'YES':
          this.presumptiveScreening = 'POSITIVE';
          break;
        case 'NO':
          this.presumptiveScreening = 'NEGATIVE';
          break;
        default:
          this.presumptiveScreening = data.presumptiveScreening;
      }
      if (this.presumptiveScreening === 'POSITIVE') {
        this.presumptiveScreeningSorter = 1;
      }
      if (this.presumptiveScreening === 'NEGATIVE') {
        this.presumptiveScreeningSorter = -1;
      }
      this.healthCenterVisited = data.healthCenterVisited;
      if (this.healthCenterVisited === 'YES') {
        this.healthCenterVisitedSorter = 1;
      }
      if (this.healthCenterVisited === 'NO') {
        this.healthCenterVisitedSorter = -1;
      }
      this.patientSymptom = data.patientSymptom;
      this.barrierToHealthCenter = data.barrierToHealthCenter;
      this.facility = data.facility;
      this.lga = data.lga;
      this.gender = data.gender;
      this.age = data.age;
      this.contactWithTB = data.contactWithTB;
      this.timeStamp =  data.timeStamp === 'Not Dialled' ? data.timeStamp : new Date(data.timeStamp);
      // this.timeStamp = GeneralUtil.convertIonDateTimeToDateTimeString(data.timeStamp);
    }
  }

}
