import { Component, OnInit } from '@angular/core';
import {ReportService} from '../../../../service/report.service';
import {GeneralUtil} from '../../../../util/general-util';
import {PresumptiveUssdDto} from '../../../../dto/presumptive-ussd-dto';
import {State} from '../../../../entity/state';
import {UserService} from '../../../../service/user.service';
import {LocationService} from '../../../../service/location.service';
import {Facility} from '../../../../entity/facility';
import {FilterReportsDto} from '../../../../dto/filter-reports-dto';

@Component({
  selector: 'app-facility-presumptive-ussd-reports',
  templateUrl: './facility-presumptive-ussd-reports.component.html',
  styleUrls: ['./facility-presumptive-ussd-reports.component.css']
})
export class FacilityPresumptiveUssdReportsComponent implements OnInit {
  public showPreloader = false;
  public presumptiveUssdArray: PresumptiveUssdDto[] = [];
  public d = new Date();
  public states: State[] = [];
  public facilities: Facility[] = [];
  public page = 1;
  public pageSize = 30;
  public exporting = false;
  public filterReports = new FilterReportsDto();
  public sortVisited = false;
  public sortScreening = false;

  constructor(
    public reportService: ReportService,
    public userService: UserService,
    public locationService: LocationService,
  ) { }

  ngOnInit(): void {
    this.d.setMonth(this.d.getMonth() - 1);
    this.getussdReportForObjective1(this.filterReports.endDate, this.filterReports.startDate);
    this.getFacilitiesForFacilityAdmin();
  }

  clearFilter(){
    this.filterReports = new FilterReportsDto();
    this.getussdReportForObjective1(this.filterReports.endDate, this.filterReports.startDate);
  }



  sortByVisited() {
    this.sortVisited = !this.sortVisited;
    this.sortVisited === true ?

      GeneralUtil.arraySorter(this.presumptiveUssdArray, 'healthCenterVisitedSorter', 'normal') :
      GeneralUtil.arraySorter(this.presumptiveUssdArray, 'healthCenterVisitedSorter', 'reverse');
  }

  sortByScreening() {
    this.sortScreening = !this.sortScreening;
    this.sortScreening === true ?
      GeneralUtil.arraySorter(this.presumptiveUssdArray, 'presumptiveScreeningSorter', 'normal') :
      GeneralUtil.arraySorter(this.presumptiveUssdArray, 'presumptiveScreeningSorter', 'reverse');
  }

  getFacilitiesForFacilityAdmin() {
    this.facilities = [];
    this.locationService.getFacilitiesForFacilityAdmin()
      .subscribe(data => {
        const facilityArray = data;
        if (facilityArray.length > 0) {
          facilityArray.forEach(f => {
            const facil = new Facility();
            facil.mapToFacility(f);
            this.facilities.push(facil);
          });
        }
        }, error1 => {
        this.errorHandler(error1);
      })
  }


  exportToCsv() {
    this.exporting = true;
    // return GeneralUtil.export2csv('presumptive-ussd-report.csv');
    setTimeout(() => {
      GeneralUtil.export2csv('presumptive-ussd-report.csv');
    }, 200);
    setTimeout(() => {
      this.exporting = false;
    }, 300);
  }

  getussdReportForObjective1(endDate, startDate) {
    this.showPreloader = true;
    this.reportService.facilityUssdReportForObjective1(100, 0, endDate, startDate,
      this.filterReports.phoneNumber, this.filterReports.selectedFacility, this.filterReports.ussdActivated)
      .subscribe((data) => {
          this.showPreloader = false;
          this.presumptiveUssdArray = [];
          const tempPresumptiveUssdArray = data;
          if (tempPresumptiveUssdArray !== null && tempPresumptiveUssdArray.length > 0) {
            tempPresumptiveUssdArray.forEach(p => {
              const presumptiveUssd = new PresumptiveUssdDto();
              presumptiveUssd.mapToDto(p);
              this.presumptiveUssdArray.push(presumptiveUssd);
            });
          }
          // arrange the USSD by Latest sent
          GeneralUtil.arraySorter(this.presumptiveUssdArray, 'timeStamp', 'normal');
          // remove timezone from ussd timestamps
          this.presumptiveUssdArray.forEach(ussd => {
            ussd.timeStamp = GeneralUtil.removeTimezoneFromDateString(ussd.timeStamp);
          });
          return this.presumptiveUssdArray;
        },
        (error) => {
          this.showPreloader = false;
          this.errorHandler(error);
        });
  }

  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }

}
