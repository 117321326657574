import {GeneralUtil} from '../util/general-util';
import {StageStatsDto} from './stage-stats-dto';

export class UssdStatDto {

  public totalObj1Activation: number;
  public totalPositivePresumptiveScreening: number;
  public totalNegativePresumptiveScreening: number;
  public totalObj2Activation: number;
  public totalPositiveFeedBack: number;
  public totalNegativeFeedBack: number;
  public totalUssdActivation: number;
  public totalUssdInactivation: number;
  public totalBarriers: number;

  constructor() {}

  public mapToUssdStats(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.totalObj1Activation = data.totalObj1Activation;
      this.totalPositivePresumptiveScreening = data.totalPositivePresumptiveScreening;
      this.totalNegativePresumptiveScreening = data.totalNegativePresumptiveScreening;
      this.totalObj2Activation = data.totalObj2Activation;
      this.totalPositiveFeedBack = data.totalPositiveFeedBack;
      this.totalNegativeFeedBack = data.totalNegativeFeedBack;
      this.totalUssdActivation = data.totalUssdActivation;
      this.totalUssdInactivation = data.totalUssdInactivation;
      this.totalBarriers = data.totalBarriers;
    }
  }
}
