<div class="main-content">
  <div class="content-body">
    <div class="container-fluid">

      <div class="row page-titles pt-2 mx-0">
        <div class="col-sm-6 p-md-0">
          <div class="welcome-text">
            <h4>Role Management</h4>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card border-top border-gray-200 pt-md-4">
            <div class="card-header d-flex justify-content-between">
              <h5 class="font-weight-semibold opensans-font mt-3 pb-md-4">List of Roles</h5>
            </div>
            <div class="card-body">
              <div style="text-align: center; margin: 0 ;" *ngIf="roleArray.length < 1 && showPreloader === false">
                <img src="assets/images/empty-data.png" height="200px">
                <div>NO DATA AVAILABLE</div>
              </div>

              <div class="table-responsive">
                <table class="table table-borderless card-table table-nowrap">
                  <thead *ngIf="roleArray.length > 0">
                  <tr>
                    <th><strong>#</strong></th>
                    <th><strong>NAME</strong></th>
                    <th><strong>STATUS</strong></th>
                    <th><strong>ACTION</strong></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let role of roleArray; index as i">
                    <td><strong>{{i + 1}}</strong></td>
                    <td>{{role.name}}</td>
                    <td>{{role.status}}</td>
                    <td role="button" (click)="toggleStatus(role.id)">
                      <svg title="Toggle Status" *ngIf="role.status === 'INACTIVE'" xmlns="http://www.w3.org/2000/svg"
                           class="icon icon-tabler icon-tabler-toggle-left" width="20" height="20" viewBox="0 0 24 24"
                           stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <circle cx="8" cy="12" r="2" />
                        <rect x="2" y="6" width="20" height="12" rx="6" />
                      </svg>
                      <svg title="Toggle Status" *ngIf="role.status === 'ACTIVE'" xmlns="http://www.w3.org/2000/svg"
                           class="icon icon-tabler icon-tabler-toggle-right" width="20" height="20" viewBox="0 0 24 24"
                           stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <circle cx="16" cy="12" r="2" />
                        <rect x="2" y="6" width="20" height="12" rx="6" />
                      </svg>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div *ngIf="showPreloader" class="card-body d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
