import { GeneralUtil } from '../util/general-util';

export class LtfuDashboardDto {

  public contacted = 0;
  public evaluated = 0;
  public onTreatment = 0;
  public barriersElicited = 0;

  constructor() {}

  public mapToDashboardStats(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.contacted = data.contacted;
      this.evaluated = data.evaluated;
      this.onTreatment = data.onTreatment;
      this.barriersElicited = data.barriersElicited;
    }
  }
}
