import {GeneralUtil} from '../util/general-util';

export class RoleDto {

  public id: number;
  public name: string;
  public status: string;

  constructor() { }

  public mapToDto(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.id = data.id;
      this.name = GeneralUtil.removeUnderscore(data.name);
      this.status = data.status;
    }
  }

}
