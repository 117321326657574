<div class="main-content">
    <div class="content-body">
        <div class="container-fluid">

            <div class="row page-titles pt-2 mx-0">
                <div class="col-sm-6 p-md-0">
                    <div class="welcome-text">
                        <h4>Lost To Follow Up USSD Reports</h4>
                    </div>
                </div>
            </div>
            <div class="row mx-0">
                <div class="form-group col-md-2 my-3 p-2">
                    <label for="facilities" class="form-label pl-2">Facility</label>
                    <div id="facilities">
                        <select name="facility" class="form-select" id="facilities1"
                            [(ngModel)]="filterDto.selectedFacility" required>
                            <option value="" selected>All Facilities</option>
                            <option *ngFor="let facility of facilities" value="{{facility.id}}">{{facility.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group col-md-3 my-3 p-2">
                    <label for="start-date-input" class="form-label pl-2">Start Date</label>
                    <div>
                        <input [(ngModel)]="filterDto.startDate" class="form-control" type="date"
                            value="{{filterDto.startDate}}" id="start-date-input">
                    </div>
                </div>
                <div class="form-group col-md-5 my-3 p-2">
                    <label for="end-date-input" class="form-label pl-2">End Date</label>
                    <div class="d-flex">
                        <input [(ngModel)]="filterDto.endDate" min="{{filterDto.startDate}}" max="{{filterDto.maxDate}}"
                            class="form-control mr-3" type="date" value="{{filterDto.endDate}}" id="end-date-input">
                        <a (click)="getussdReportForObjective3(this.filterDto.endDate, this.filterDto.startDate)"
                            class="btn btn-ddanger text-white transition-3d-hover hover-shadow-danger  mr-1 px-3">Filter</a>
                        <a (click)="clearFilter()" class="btn btn-outline-danger
                   transition-3d-hover hover-shadow-danger">Clear Filter</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2 px-3">
            <div class="col-lg-12">
                <div class="card border-top border-gray-200 pt-md-4">
                    <div class="card-header d-flex justify-content-between">
                        <h5 class="font-weight-semibold opensans-font mt-3 pb-md-4">Routine Report</h5>
                        <div class="input-group w-50">
                            <input type="tel" maxlength="13" class="form-control" placeholder="Search by phone number"
                                [(ngModel)]="filterDto.phoneNumber" value="{{filterDto.phoneNumber}}" (keypress)="numbersOnly($event)"
                                (keyup.enter)="getussdReportForObjective3('', '')"
                                (blur)="getussdReportForObjective3('', '')">
                            <div class="input-group-append" style="height: 41px;">
                                <button class="btn btn-ddanger" type="button"
                                    (click)="getussdReportForObjective3('', '')">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search"
                                        width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff"
                                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <circle cx="10" cy="10" r="7" />
                                        <line x1="21" y1="21" x2="15" y2="15" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <a (click)="exportToCsv()"
                            class="btn btn-outline-danger mr-2 transition-3d-hover hover-shadow-danger">Export CSV</a>
                    </div>
                    <div class="card-body">
                        <div style="text-align: center; margin: 0 ;"
                            *ngIf="ltfuUssdArray.length < 1 && showPreloader === false">
                            <img src="assets/images/empty-data.png" height="200px">
                            <div>NO DATA AVAILABLE</div>
                        </div>

                        <div class="table-responsive">
                            <div *ngIf="showPreloader" class="card-body d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            <table class="table table-borderless card-table table-nowrap">
                                <thead *ngIf="ltfuUssdArray.length > 0">
                                    <tr>
                                        <th><strong>#</strong></th>
                                        <th><strong>DATE DIALLED</strong></th>
                                        <th><strong>PHONE NUMBER</strong></th>
                                        <th><strong>STATE</strong></th>
                                        <th><strong>AGE</strong></th>
                                        <th><strong>GENDER</strong></th>
                                        <th><strong>FACILITY</strong></th>
                                        <th><strong>LGA</strong></th>
                                        <th><strong>REASON FOR NOT VISITING HEALTH CENTER</strong></th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="exporting === false">
                                    <tr
                                        *ngFor="let ussdArray of ltfuUssdArray | slice: (page-1) * pageSize : page * pageSize; index as i">
                                        <td><strong>{{i + 1}}</strong></td>
                                        <td>{{ussdArray.timeStamp}}</td>
                                        <td>{{ussdArray.patientPhoneNumber}}</td>
                                        <td>{{ussdArray.state}}</td>
                                        <td>{{ussdArray.age}}</td>
                                        <td>{{ussdArray.gender}}</td>
                                        <td>{{ussdArray.facility}}</td>
                                        <td>{{ussdArray.lga}}</td>
                                        <td>{{ussdArray.reason}}</td>
                                    </tr>
                                </tbody>

                                <tbody *ngIf="exporting === true">
                                    <tr *ngFor="let ussdArray of ltfuUssdArray; index as i">
                                        <td><strong>{{i + 1}}</strong></td>
                                        <td>{{ussdArray.timeStamp}}</td>
                                        <td>{{ussdArray.patientPhoneNumber}}</td>
                                        <td>{{ussdArray.state}}</td>
                                        <td>{{ussdArray.age}}</td>
                                        <td>{{ussdArray.gender}}</td>
                                        <td>{{ussdArray.facility}}</td>
                                        <td>{{ussdArray.lga}}</td>
                                        <td>{{ussdArray.reason}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="exporting === false" class="mt-4">
                            <ngb-pagination (pageChange)="page = $event" [(page)]="page" [pageSize]="pageSize"
                                [collectionSize]="ltfuUssdArray.length">
                            </ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
