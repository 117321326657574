<div class="main-content">
  <div class="content-body">
    <div class="container-fluid">

      <div class="row page-titles pt-2 mx-0">
        <div class="col-sm-6 p-md-0">
          <div class="welcome-text">
            <h4>SMS Reports</h4>
          </div>
        </div>
      </div>

      <div class="row mx-0">

        <div class="form-group col-md-3 mb-3 p-2">
          <label for="start-date-input" class="form-label pl-2">Start Date</label>
          <div>
            <input [(ngModel)]="filterDto.startDate" class="form-control" type="date" value="{{filterDto.startDate}}" id="start-date-input">
          </div>
        </div>

        <div class="form-group col-md-6 mb-3 p-2">
          <label for="end-date-input" class="form-label pl-2">End Date</label>
          <div class="d-flex flex-row">
            <input [(ngModel)]="filterDto.endDate" min="{{filterDto.startDate}}" class="form-control mr-4" type="date"
                   value="{{filterDto.endDate}}" id="end-date-input">
            <a (click)="getSmsReport()"
               class="btn btn-ddanger text-white transition-3d-hover hover-shadow-danger mr-1 px-5">Filter</a>
            <a (click)="clearFilter()" class="btn btn-outline-danger ml-2
                transition-3d-hover hover-shadow-danger">Clear Filter</a>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card border-top border-gray-200 pt-md-4">
            <div class="card-header d-flex justify-content-between">
              <h5 class="font-weight-semibold opensans-font mt-3 pb-md-4">Broadcast Message Report</h5>
              <a (click)="exportToCsv()" class="btn btn-outline-danger mr-2 transition-3d-hover hover-shadow-danger">Export CSV</a>
            </div>
            <div class="card-body">
              <div style="text-align: center; margin: 0 ;" *ngIf="smsArray.length < 1 && showPreloader === false">
                <img src="assets/images/empty-data.png" height="200px">
                <div>NO DATA AVAILABLE</div>
              </div>


              <div class="table-responsive">
                <div *ngIf="showPreloader" class="card-body d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <table class="table table-borderless card-table table-nowrap">
                  <thead *ngIf="smsArray.length > 0">
                  <tr>
                    <th><strong>#</strong></th>
                    <th><strong>CATEGORY</strong></th>
                    <th class="w-50"><strong>TEXT MESSAGE</strong></th>
                    <th class="w-50"><strong>DATE LAST PUBLISHED</strong></th>
                    <th><strong>SMS CODE</strong></th>
                    <th><strong>SUCCESSFUL</strong></th>
                    <th><strong>FAILED</strong></th>
                    <th><strong>PENDING</strong></th>
                    <th><strong>TOTAL</strong></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let sms of smsArray; index as i">
                    <td><strong>{{i + 1}}</strong></td>
                    <td>{{sms.category}}</td>
                    <td *ngIf="exporting == false"><div *ngFor="let smsText of sms.formattedTextMessage">{{smsText}}</div></td>
                    <td *ngIf="exporting == true">{{sms.textMessage}}</td>
                    <td>{{sms.dateLastPublished}}</td>
                    <td>{{sms.smsCode}}</td>
                    <td>{{sms.successful}}</td>
                    <td>{{sms.failed}}</td>
                    <td>{{sms.pending}}</td>
                    <td>{{sms.total}}</td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
