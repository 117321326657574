import {GeneralUtil} from '../util/general-util';

export class PresumptiveCumulativeDto {
  public totalSmsBroadCastSent: number;
  public totalSmsBroadCastSuccessful: number;
  public totalSmsBroadCastPending: number;
  public totalSmsBroadcastFailed: number;
  public totalPositivePresumptiveScreening: number;
  public totalUssdActivation: number;

  constructor() { }

  public mapToDto(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.totalSmsBroadCastSent = data.totalSmsBroadCastSent.toLocaleString();
      this.totalSmsBroadCastSuccessful = data.totalSmsBroadCastSuccessful.toLocaleString();
      this.totalSmsBroadCastPending = data.totalSmsBroadCastPending.toLocaleString();
      this.totalSmsBroadcastFailed = data.totalSmsBroadcastFailed.toLocaleString();
      this.totalPositivePresumptiveScreening = data.totalPositivePresumptiveScreening.toLocaleString();
      this.totalUssdActivation = data.totalUssdActivation.toLocaleString();
    }
  }


}
