import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {GeneralUtil} from '../../util/general-util';
import {AuthService} from '../../service/auth/auth.service';
import {LoginDto} from '../../dto/login-dto';
import {ForgotPasswordDto} from '../../dto/forgot-password-dto';

declare function successToast(msg): any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public loginDto = new LoginDto();
  public forgotPasswordDto = new ForgotPasswordDto();
  public showPreloader = false;
  @ViewChild('close') close: ElementRef;

  constructor(public authService: AuthService, public router: Router) {
  }

  ngOnInit() {
    this.userLoginCheck();
  }

  login() {
    this.showPreloader = true;
    this.authService.loginUser(this.loginDto)
      .subscribe((data) => {
          this.showPreloader = false;
          successToast('Login Successful');
          this.authService.resetUserDetails();
          this.authService.setUserDetails(data);
          this.router.navigate(['/dashboard']);
          return;
        },

        (error) => {
          this.showPreloader = false;
          this.errorHandler(error);
        }
      );
  }

  forgotPassword() {
    this.showPreloader = true;
    this.authService.sendForgotPasswordEmail(this.forgotPasswordDto)
      .subscribe((data) => {
          this.showPreloader = false;
          successToast(`Check ${data.usernameOrEmail} for a reset password link`);
          this.close.nativeElement.click();
          return;
        },

        (error) => {
          this.showPreloader = false;
          this.errorHandler(error);
        }
      );
  }

  // check if user is already logged in
  public userLoginCheck() {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/dashboard']);
    }
  }

  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }
}
