import { Component, OnInit } from '@angular/core';
import {PresumptiveCumulativeDto} from '../../../../dto/presumptive-cumulative-dto';
import {ReportService} from '../../../../service/report.service';
import {GeneralUtil} from '../../../../util/general-util';
import {State} from '../../../../entity/state';
import {UserService} from '../../../../service/user.service';
import {LocationService} from '../../../../service/location.service';
import { FilterReportsDto } from '../../../../dto/filter-reports-dto';
import { Facility } from './../../../../entity/facility';

@Component({
  selector: 'app-state-presumptive-cumulative-reports',
  templateUrl: './state-presumptive-cumulative-reports.component.html',
  styleUrls: ['./state-presumptive-cumulative-reports.component.css']
})
export class StatePresumptiveCumulativeReportsComponent implements OnInit {
  public showPreloader = false;
  public presumptiveCumulative = new PresumptiveCumulativeDto();
  public states: State[] = [];
  public facilities: Facility[] = [];
  public filterReports = new FilterReportsDto();

  constructor(public reportService: ReportService,
              public userService: UserService,
              public locationService: LocationService) { }

  ngOnInit(): void {
    this.getStateCumulativeReportForObjective1(this.filterReports.endDate, this.filterReports.startDate);
    this.getStatesForStateAdmin();
  }

  clearFilter(){
    this.filterReports = new FilterReportsDto();
    this.getStateCumulativeReportForObjective1(this.filterReports.endDate, this.filterReports.startDate);
  }

  getFacilitiesByState(stateId) {
    this.facilities = [];
    this.locationService.retrieveFacilityByState(stateId)
      .subscribe(data => {
        const facilityArray = data;
        if (facilityArray.length > 0) {
          facilityArray.forEach(f => {
            const fac = new Facility();
            fac.mapToFacility(f);
            this.facilities.push(fac);
          });
        }
      }, error1 => {
        this.errorHandler(error1);
      });
  }

  changeFacility() {
    if (this.filterReports.selectedState === '') {
      return;
    } else {
      let filteredStateId;
      this.states.forEach(s => {
        if (s.name === this.filterReports.selectedState) {
          filteredStateId = s.id;
        }
      });

      this.getFacilitiesByState(filteredStateId);
    }

  }

  getStatesForStateAdmin() {
    this.states = [];
    this.locationService.getStatesForStateAdmin()
      .subscribe(data => {
        const stateArray = data;
        if (stateArray.length > 0) {
          stateArray.forEach(s => {
            const sta = new State();
            sta.mapToState(s);
            this.states.push(sta);
          });
        }
      }, error1 => {
        this.errorHandler(error1);
      });
  }


  getStateCumulativeReportForObjective1(endDate, startDate) {
    this.showPreloader = true;
    this.reportService.stateCumulativeReportForObjective1(endDate, startDate, this.filterReports.selectedState,
      this.filterReports.selectedFacility)
      .subscribe((data) => {
        this.showPreloader = false;
          return this.presumptiveCumulative.mapToDto(data);
        },
        (error) => {
          this.showPreloader = false;
          this.errorHandler(error);
        });
  }

   // Error handling
   public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }

}
