import { Component, OnInit } from '@angular/core';
import {ReportService} from '../../../../service/report.service';
import {GeneralUtil} from '../../../../util/general-util';
import {LtfuCumulativeDto} from '../../../../dto/ltfu-cumulative-dto';
import {State} from '../../../../entity/state';
import {UserService} from '../../../../service/user.service';
import {LocationService} from '../../../../service/location.service';
import {FilterReportsDto} from '../../../../dto/filter-reports-dto';

@Component({
  selector: 'app-ltfu-cumulative-reports',
  templateUrl: './ltfu-cumulative-reports.component.html',
  styleUrls: ['./ltfu-cumulative-reports.component.css']
})
export class LtfuCumulativeReportsComponent implements OnInit {

  public filterDto = new FilterReportsDto();
  public showPreloader = false;
  public ltfuCumulative = new LtfuCumulativeDto();
  public states: State[] = [];

  constructor(
    public reportService: ReportService,
    public userService: UserService,
    public locationService: LocationService
  ) { }


  ngOnInit(): void {
    this.getCumulativeReportForObjective3(this.filterDto.endDate, this.filterDto.startDate);
    this.getAllStates();
  }

  clearFilter(){
    this.filterDto = new FilterReportsDto();
    this.getCumulativeReportForObjective3(this.filterDto.endDate, this.filterDto.startDate);
  }

  getAllStates() {
    this.states = [];
    this.locationService.getActiveStates()
      .subscribe(data => {
        const stateArray = data;
        if (stateArray.length > 0) {
          stateArray.forEach(s => {
            const sta = new State();
            sta.mapToState(s);
            this.states.push(sta);
          });
        }
      }, error1 => {
        this.errorHandler(error1);
      });
  }

  getCumulativeReportForObjective3(endDate, startDate) {
    this.showPreloader = true;
    this.reportService.cumulativeReportForObjective3(endDate, startDate, this.filterDto.selectedState)
      .subscribe((data) => {
          this.showPreloader = false;
          return this.ltfuCumulative.mapToDto(data);
        },
        (error) => {
          this.showPreloader = false;
          this.errorHandler(error);
        });
  }

  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }

}
