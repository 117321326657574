export class PatientDto {
  public firstName: string;
  public lastName: string;
  public gender: string;
  public dob: string;
  public facilityId: number;
  public phone: string;
  public stateId: number;
  public status: string;
  public lga: string;
}

