import { GeneralUtil } from 'src/app/util/general-util';
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {GeneralEnum} from '../constant/general-enum.enum';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ReportService {
  constructor(public httpClient: HttpClient) {
  }

  // Objective 1
  public cumulativeReportForObjective1(endDate, startDate, selectedState) {
    const url = GeneralEnum.baseUrl + '/auth/obj1/report/cumulative?endDate=' + endDate +
      '&startDate=' + startDate + '&state=' + selectedState;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  // Objective 1 (version 2)
  public getCumulativeReportForObjective1(stateName?:any,facilityId?:any ) {
    let url
    if (stateName) {

       url = `${GeneralEnum.baseUrl}/auth/obj1/report/cumulative?state=${stateName} `
    } else if(facilityId){
      url = `${GeneralEnum.baseUrl}/auth/obj1/report/cumulative?facilityId=${facilityId} `

    }else{
      url = `${GeneralEnum.baseUrl}/auth/obj1/report/cumulative `

    }
    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  // Objective 2 (version 2)
  public getCumulativeReportForObjective2(stateName?:any,facilityId?:any) {

    let url
    if (stateName) {

       url = `${GeneralEnum.baseUrl}/auth/obj2/report/cumulative?state=${stateName} `
    }
    else if(facilityId){
      url = `${GeneralEnum.baseUrl}/auth/obj2/report/cumulative?facilityId=${facilityId} `

    }else{
      url = `${GeneralEnum.baseUrl}/auth/obj2/report/cumulative `

    }
    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  // Objective 3 (version 2)
  public getCumulativeReportForObjective3(stateName?:any,facilityId?:any) {
    let url
    if (stateName) {


       url = `${GeneralEnum.baseUrl}/auth/obj3/report/cumulative?state=${stateName} `

    }
    else if(facilityId){
      url = `${GeneralEnum.baseUrl}/auth/obj3/report/cumulative?facilityId=${facilityId} `

    }else{
      url = `${GeneralEnum.baseUrl}/auth/obj3/report/cumulative `

    }
    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  // cumulative report for objective 1 state admin(version2)
  public getCumulativeReportObj1State(stateName?:any) {
    const url = `${GeneralEnum.baseUrl}/auth/obj1/report/cumulative/state?state=${stateName}`;
    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

   // cumulative report for objective 2 state admin(version2)
   public getCumulativeReportObj2State(stateName?:any) {
    const url = `${GeneralEnum.baseUrl}/auth/obj2/report/cumulative/state?state=${stateName}`;
    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

   // cumulative report for objective 3 state admin(version2)
   public getCumulativeReportObj3State(stateName?:any) {
    const url = `${GeneralEnum.baseUrl}/auth/obj3/report/cumulative/state?state=${stateName}`;
    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  // cumulative report for objective 1 facility admin(version2)
  public getCumulativeReportObj1Facility(facilitiId?:any) {
    const url = `${GeneralEnum.baseUrl}/auth/obj1/report/cumulative/facility?facilityId=${facilitiId}`;
    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  //cumulative report for objective 2 facility admin(version2)
  public getCumulativeReportObj2Facility(facilitiId?:any) {
    const url = `${GeneralEnum.baseUrl}/auth/obj2/report/cumulative/facility?facilityId=${facilitiId}`;
   return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  //cumulative report for objective 3 facility admin(version2)
  public getCumulativeReportObj3Facility(facilitiId?:any) {
    const url = GeneralEnum.baseUrl + '/auth/obj3/report/cumulative/facility?facilityId='+facilitiId;
    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

 public stateCumulativeReportForObjective1(endDate, startDate, selectedState, facilityId) {
    const url = GeneralEnum.baseUrl + '/auth/obj1/report/cumulative/state?endDate=' + endDate +
      '&startDate=' + startDate + '&state=' + selectedState + '&facilityId=' + facilityId;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public facilityCumulativeReportForObjective1(endDate, startDate, facilityId) {
    const url = GeneralEnum.baseUrl + '/auth/obj1/report/cumulative/facility?endDate=' + endDate +
      '&startDate=' + startDate + '&state=' + '&facilityId=' + facilityId;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public ussdReportForObjective1(limit, page, endDate, startDate, selectedState, ussdActivated, phoneNumber, facilityId) {
    const endDateTime = GeneralUtil.convertDateTimeToDateTimeString(endDate);


    const startDateTime = GeneralUtil.convertDateTimeToDateTimeString(startDate);


    const url = GeneralEnum.baseUrl + '/auth/obj1/report/ussd?limit=' + limit + '&page=' + page + '&endDate=' + endDateTime +
        '&startDate=' + startDateTime + '&state=' + selectedState + '&phoneNumber=' + phoneNumber + '&facilityId=' + facilityId + '&ussdActivated=' + ussdActivated;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public stateUssdReportForObjective1(limit, page, endDate, startDate, selectedState, phoneNumber, facilityId, ussdActivated) {
    const endDateTime = GeneralUtil.convertDateTimeToDateTimeString(endDate);
    const startDateTime = GeneralUtil.convertDateTimeToDateTimeString(startDate);

    const url = GeneralEnum.baseUrl + '/auth/obj1/report/ussd/state?limit=' + limit + '&page=' + page + '&endDate=' + endDateTime +
        '&startDate=' + startDateTime + '&state=' + selectedState + '&phoneNumber=' + phoneNumber + '&facilityId=' + facilityId + '&ussdActivated=' + ussdActivated;;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public facilityUssdReportForObjective1(limit, page, endDate, startDate, phoneNumber, facilityId,  ussdActivated) {
    const endDateTime = GeneralUtil.convertDateTimeToDateTimeString(endDate);
    const startDateTime = GeneralUtil.convertDateTimeToDateTimeString(startDate);

    const url = GeneralEnum.baseUrl + '/auth/obj1/report/ussd/facility?limit=' + limit + '&page=' + page + '&endDate=' + endDateTime +
        '&startDate=' + startDateTime + '&phoneNumber=' + phoneNumber + '&facilityId=' + facilityId + '&ussdActivated=' + ussdActivated;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  // Objective 2
  public ussdReportForObjective2(limit, page, endDate, startDate, selectedState, ussdActivated, phoneNumber, facilityId) {
    const endDateTime = GeneralUtil.convertDateTimeToDateTimeString(endDate);
    const startDateTime = GeneralUtil.convertDateTimeToDateTimeString(startDate);

    const url = GeneralEnum.baseUrl + '/auth/obj2/report/ussd?limit=' + limit + '&page=' + page + '&endDate=' + endDateTime +
        '&startDate=' + startDateTime + '&state=' + selectedState + '&phoneNumber=' + phoneNumber + '&facilityId=' + facilityId+ '&ussdActivated=' + ussdActivated;;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public stateUssdReportForObjective2(limit, page, endDate, startDate, selectedState, phoneNumber, facilityId, ussdActivated) {
    const endDateTime = GeneralUtil.convertDateTimeToDateTimeString(endDate);
    const startDateTime = GeneralUtil.convertDateTimeToDateTimeString(startDate);

    const url = GeneralEnum.baseUrl + '/auth/obj2/report/ussd/state?limit=' + limit + '&page=' + page + '&endDate=' + endDateTime +
        '&startDate=' + startDateTime + '&state=' + selectedState + '&phoneNumber=' + phoneNumber + '&facilityId=' + facilityId + '&ussdActivated=' + ussdActivated;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public facilityUssdReportForObjective2(limit, page, endDate, startDate, phoneNumber, facilityId, ussdActivated) {
    const endDateTime = GeneralUtil.convertDateTimeToDateTimeString(endDate);
    const startDateTime = GeneralUtil.convertDateTimeToDateTimeString(startDate);

    const url = GeneralEnum.baseUrl + '/auth/obj2/report/ussd/facility?limit=' + limit + '&page=' + page + '&endDate=' + endDateTime +
        '&startDate=' + startDateTime  + '&phoneNumber=' + phoneNumber + '&facilityId=' + facilityId + '&ussdActivated=' + ussdActivated;;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public cumulativeReportForObjective2(endDate, startDate, selectedState) {
    const url = GeneralEnum.baseUrl + '/auth/obj2/report/cumulative?endDate=' + endDate +
      '&startDate=' + startDate + '&state=' + selectedState;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public stateCumulativeReportForObjective2(endDate, startDate, selectedState, facilityId) {
    const url = GeneralEnum.baseUrl + '/auth/obj2/report/cumulative/state?endDate=' + endDate +
      '&startDate=' + startDate + '&state=' + selectedState + '&facilityId=' + facilityId;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public facilityCumulativeReportForObjective2(endDate, facilityId, startDate) {
    const url = GeneralEnum.baseUrl + '/auth/obj2/report/cumulative/facility?endDate=' + endDate +
    '&facilityId=' + facilityId + '&startDate=' + startDate;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  // Objective 3
  public ussdReportForObjective3(limit, page, endDate, startDate, selectedState, ussdActivated, phoneNumber, facilityId) {
    const endDateTime = GeneralUtil.convertDateTimeToDateTimeString(endDate);
    const startDateTime = GeneralUtil.convertDateTimeToDateTimeString(startDate);

    const url = GeneralEnum.baseUrl + '/auth/obj3/report/ussd?limit=' + limit + '&page=' + page + '&endDate=' + endDateTime +
      '&startDate=' + startDateTime + '&state=' + selectedState + '&phoneNumber=' + phoneNumber + '&facilityId=' + facilityId + '&ussdActivated=' + ussdActivated;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public stateUssdReportForObjective3(limit, page, endDate, startDate, selectedState, phoneNumber, facilityId, ussdActivated) {
    const endDateTime = GeneralUtil.convertDateTimeToDateTimeString(endDate);
    const startDateTime = GeneralUtil.convertDateTimeToDateTimeString(startDate);

    const url = GeneralEnum.baseUrl + '/auth/obj3/report/ussd/state?limit=' + limit + '&page=' + page + '&endDate=' + endDateTime +
      '&startDate=' + startDateTime + '&state=' + selectedState + '&phoneNumber=' + phoneNumber + '&facilityId=' + facilityId + '&ussdActivated=' + ussdActivated;;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public facilityUssdReportForObjective3(limit, page, endDate, startDate, phoneNumber, facilityId, ussdActivated) {
    const endDateTime = GeneralUtil.convertDateTimeToDateTimeString(endDate);
    const startDateTime = GeneralUtil.convertDateTimeToDateTimeString(startDate);

    const url = GeneralEnum.baseUrl + '/auth/obj3/report/ussd/facility?limit=' + limit + '&page=' + page + '&endDate=' + endDateTime +
      '&startDate=' + startDateTime  + '&phoneNumber=' + phoneNumber + '&facilityId=' + facilityId + '&ussdActivated=' + ussdActivated;;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public cumulativeReportForObjective3(endDate, startDate, selectedState) {
    const url = GeneralEnum.baseUrl + '/auth/obj3/report/cumulative?endDate=' + endDate +
      '&startDate=' + startDate + '&state=' + selectedState;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public stateCumulativeReportForObjective3(endDate, startDate, selectedState, facilityId) {
    const url = GeneralEnum.baseUrl + '/auth/obj3/report/cumulative/state?endDate=' + endDate +
      '&startDate=' + startDate + '&state=' + selectedState + '&facilityId=' + facilityId;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public facilityCumulativeReportForObjective3(endDate, startDate, facilityId) {
    const url = GeneralEnum.baseUrl + '/auth/obj3/report/cumulative/facility?endDate=' + endDate +
      '&startDate=' + startDate + '&facilityId=' + facilityId;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public smsReport(startDate, endDate) {
    const url = GeneralEnum.baseUrl + '/auth/sms/report?startDate=' + startDate + '&endDate=' + endDate;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public dashboardStatistic(patientType: string) {
    const url = GeneralEnum.baseUrl + '/auth/dashboard/stats/sms/' + patientType;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public dashboardStatisticForFacilities(patientType: string, facilityId: any) {
    const url = GeneralEnum.baseUrl + '/auth/dashboard/stats/sms/facilities/' + patientType + '?facilityId=' + facilityId;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public dashboardStatisticForState(patientType: string, state: string) {
    const url = GeneralEnum.baseUrl + '/auth/dashboard/stats/sms/states/' + patientType + '?state=' + state;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public ussdStatistic() {
    const url = GeneralEnum.baseUrl + '/auth/ussd/report';

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public  getAllStates() {
    const url = GeneralEnum.baseUrl + '/auth/state/all'

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }
  // Error Handler
  public errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
