export enum GeneralEnum {

    // dev
    //  baseUrl = 'http://143.198.48.197:8082',

    // prod
    baseUrl = 'https://api.kncvsms.org',
     paginationLimit = 50

}
