<div class="main-content">
    <div class="content-body">
      <div class="container-fluid">
        <div class="row page-titles mx-0">
          <div class="col-sm-6 p-md-0">
            <div class="welcome-text">
              <h4>Presumptive Cumulative Reports</h4>
            </div>
          </div>
        </div>
        <div class="row mx-0">
            <div class="form-group col-md-3 mb-3 p-2">
              <label for="facility" class="form-label pl-2">Select Facility</label>
              <div id="facility">
                <select name="facility" class="form-select" id="facility"
                        [(ngModel)]="filterReports.selectedFacility" required>
                  <option value="" selected >All Facilities</option>
                  <option *ngFor="let facility of facilities" value="{{facility.id}}">{{facility.name}}
                  </option>
                </select>
              </div>
            </div>
  
          <div class="form-group col-md-3  mb-3 p-2">
            <label for="start-date-input" class="form-label pl-2">Start Date</label>
            <input [(ngModel)]="filterReports.startDate" class="form-control" type="date" value="{{filterReports.startDate}}"
              id="start-date-input">
          </div>
  
          <div class="form-group col-md-6 mb-3 p-2">
            <label for="end-date-input" class="form-label pl-2">End Date</label>
            <div class="d-flex">
              <input [(ngModel)]="filterReports.endDate" min="{{filterReports.startDate}}" class="form-control mr-4" type="date"
                value="{{filterReports.endDate}}" id="end-date-input">
              <a (click)="getCumulativeReportForObjective1(this.filterReports.endDate, this.filterReports.startDate)"
                class="btn btn-ddanger text-white transition-3d-hover hover-shadow-danger mr-1 px-5">Filter</a>
                <a (click)="clearFilter()" class="btn btn-outline-danger ml-2
                      transition-3d-hover hover-shadow-danger">Clear Filter</a>
            </div>
          </div>
  
        </div>
  
  
        <div class="row">
          <div class="col-lg-12">
            <div class="card border-top border-gray-200 pt-md-4">
              <div class="card-header">
                <h5 class="font-weight-semibold opensans-font mt-3 pb-md-4">Collated Report for Presumptive Patients</h5>
              </div>
  
              <div class="card-body">
                <div *ngIf="showPreloader" class="card-body d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="bg-ddanger text-white rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                      <div class="px-0 px-md-3 py-0 py-md-2">
                        <h5 class="font-weight-medium mb-0 pt-md-3 text-white">TOTAL SENT <div>SMS</div> BROADCAST</h5>
                        <h2 class="h1 d-flex align-items-center text-white">{{presumptiveCumulative.totalSmsBroadCastSent}}</h2>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="bg-dark text-white rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                      <div class="px-0 px-md-3 py-0 py-md-2">
                        <h5 class="font-weight-medium mb-0 pt-md-3 text-white">TOTAL SUCCESSFUL <div>SMS</div> BROADCAST</h5>
                        <h2 class="h1 text-white d-flex align-items-center">{{presumptiveCumulative.totalSmsBroadCastSuccessful}}</h2>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="shadow border border-dark rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                      <div class="px-0 px-md-3 py-0 py-md-2">
                        <h5 class="font-weight-medium mb-0 pt-md-3 pr-5">TOTAL PENDING SMS BROADCAST</h5>
                        <h2 class="h1 d-flex align-items-center">{{presumptiveCumulative.totalSmsBroadCastPending}}</h2>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="shadow border border-dark rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                      <div class="px-0 px-md-3 py-0 py-md-2">
                        <h5 class="font-weight-medium mb-0 pt-md-3 text-black">TOTAL <div>FAILED</div><div>SMS BROADCAST</div></h5>
                        <h2 class="h1 text-black d-flex align-items-center">{{presumptiveCumulative.totalSmsBroadcastFailed}}</h2>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="bg-ddanger text-white rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                      <div class="px-0 px-md-3 py-0 py-md-2">
                        <h5 class="font-weight-medium mb-0 pt-md-3 text-white">TOTAL <div>USSD</div> ACTIVATION</h5>
                        <h2 class="h1 text-white d-flex align-items-center">{{presumptiveCumulative.totalUssdActivation}}</h2>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="bg-dark text-white rounded-12 shadow-dark-80 p-4 px-md-0 px-xl-4 mb-4 card-fixed">
                      <div class="px-0 px-md-3 py-0 py-md-2">
                        <h5 class="font-weight-medium mb-0 pt-md-3 text-white">TOTAL POSITIVE PRESUMPTIVE SCREENING(USSD)</h5>
                        <h2 class="h1 text-white d-flex align-items-center">{{presumptiveCumulative.totalPositivePresumptiveScreening}}</h2>
                      </div>
                    </div>
                  </div>
                </div>
  
              </div>
            </div>
  
          </div>
        </div>
      </div>
    </div>
  
  </div>
  