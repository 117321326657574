export enum RoleEnum {

    SUPER_ADMIN ='SUPER_ADMIN',
    DATA_ANALYST_1 = 'DATA_ANALYST_1',
    NATIONAL_LEVEL_ADMIN ='NATIONAL_LEVEL_ADMIN',
    STATE_LEVEL_ADMIN ='STATE_LEVEL_ADMIN',
    REGIONAL_LEVEL_ADMIN ='REGIONAL_LEVEL_ADMIN',
    FACILITY_LEVEL_ADMIN = 'FACILITY_LEVEL_ADMIN'

}
