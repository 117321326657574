import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {GeneralEnum} from '../../constant/general-enum.enum';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {GeneralUtil} from '../../util/general-util';
import {LoginDto} from '../../dto/login-dto';
import {ChangeForgottenPasswordDto} from '../../dto/change-forgotten-password-dto';
import {ForgotPasswordDto} from '../../dto/forgot-password-dto';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpClient: HttpClient) { }

  public static userDetailsEmail = 'KNCV-USER-DETAILS-EMAIL';
  public static userDetailsUsername = 'KNCV-USER-DETAILS-USERNAME';
  public static userDetailsFullName = 'KNCV-USER-DETAILS-FULL-NAME';
  public static userDetailsToken = 'KNCV-USER-DETAILS-TOKEN';
  public static userDetailsPermissions = 'KNCV-USER-DETAILS-PERMISSIONS';
  public static userDetailsIsFirstTimeUser = 'KNCV-USER-DETAILS-IS-FIRST-TIME-USER';
  public static userDetailsRole = 'KNCV-USER-DETAILS-ROLE';


    // reset user details
    public resetUserDetails() {
      localStorage.removeItem(AuthService.userDetailsEmail);
      localStorage.removeItem(AuthService.userDetailsFullName);
      localStorage.removeItem(AuthService.userDetailsToken);
      localStorage.removeItem(AuthService.userDetailsRole);
    }

    // Login user
    loginUser(loginDto: LoginDto) {

      const url = GeneralEnum.baseUrl + '/login';
      return this.httpClient.post<any>(url, loginDto)
        .pipe(catchError(this.errorHandler));
    }

    // Change Forgotten Password
    changeForgottenPassword(changePasswordDto: ChangeForgottenPasswordDto) {

      const url = GeneralEnum.baseUrl + '/users/password';
      return this.httpClient.post<any>(url, changePasswordDto)
        .pipe(catchError(this.errorHandler));
    }

    // Send Forgot Password Email
    sendForgotPasswordEmail(forgotPasswordDto: ForgotPasswordDto) {

      const url = GeneralEnum.baseUrl + '/forgot/password';
      return this.httpClient.post<any>(url, forgotPasswordDto)
        .pipe(catchError(this.errorHandler));
    }

    // Confirm if user is logged in
    isLoggedIn() {
      return !!localStorage.getItem(AuthService.userDetailsToken);
    }

    // Get Token value
    getToken() {
      return localStorage.getItem(AuthService.userDetailsToken);
    }

    // Get Authorization Token
    getAuthToken() {
      if (this.getToken() == null) {
        return 'Bearer ';
      } else {
        return 'Bearer ' + this.getToken();
      }
    }

    // after login, set userdetails
    public setUserDetails(data: any) {
      localStorage.setItem(AuthService.userDetailsEmail, data.email);
      localStorage.setItem(AuthService.userDetailsFullName, data.fullName);
      localStorage.setItem(AuthService.userDetailsToken, data.token);
      localStorage.setItem(AuthService.userDetailsRole, data.roles[0].name);
   }

    public getUserDetailItem(userDetailItem: string): string {
      const userDetail = localStorage.getItem(userDetailItem);
      if (!GeneralUtil.isValidString(userDetail)) {
        return null;
      }
      return userDetail;
    }

    public setOneUserDetail(userDetailItem: string, value: string) {
      const userDetail = localStorage.getItem(userDetailItem);

      if (GeneralUtil.isValidString(userDetail)) {
        localStorage.setItem(userDetailItem, value);
      }
    }

    // Error Handler
    errorHandler(error: HttpErrorResponse) {
      return throwError(error);
    }
}
