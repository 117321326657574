import { State } from './state';
import { GeneralUtil } from './../util/general-util';
import { Facility } from './facility';
 
 
export class User{
    public id: number;
    public firstName: string;
    public lastName: string;
    public phone: string;
    public email: string;
    public status: string;
    public role: string = '';
    public facilityAdminFacilities: Facility[]= [];
    public stateAdminStates: State[]=[];
    public facilityIdsForFacilityAdmin= [];
    public stateIdsForStateAdmin = [];
    public useraccountId : number;
    


    public mapToUser(data: any) {
        if (GeneralUtil.isValidJSON(data)) {
        this.id = data.id;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.phone = data.phone;
        this.email = data.email;
        this.status = data.status;
        this.facilityIdsForFacilityAdmin = data.facilityAdminFacilities;
        this.stateIdsForStateAdmin = data.stateAdminStates;
        this.role = data.role;
        this.useraccountId = data.id;
        // get facilities
        if (data.facilityAdminFacilities != null && data.facilityAdminFacilities.length > 0) {
            const facilities = data.facilityAdminFacilities;
            facilities.forEach((facility) => {
                const facil = new Facility;
                facil.mapToFacility(facility);
                this.facilityAdminFacilities.push(facil)
            })
        }

        // get states
        if (data.stateAdminStates != null && data.stateAdminStates.length > 0) {
            const state = data.stateAdminStates;
            state.forEach((states) => {
                const st = new State;
                st.mapToState(states);
                this.stateAdminStates.push(st)
            })
        }
        
        }
    }

  
}
