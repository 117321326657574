import {GeneralUtil} from '../util/general-util';

export class LtfuUssdDto {

  public patientNumber: string;
  public state: string;
  public reason: string;
  public age: string;
  public gender: string;
  public facility: string;
  public lga: string;
  public timeStamp: any;

  constructor() { }

  public mapToDto(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.patientNumber = data.patientPhoneNumber;
      this.state = data.state;
      this.reason = data.reason;
      this.age = data.age;
      this.gender = data.gender;
      this.facility = data.facility;
      this.lga = data.lga;
      this.timeStamp = data.timeStamp === 'Not Dialled' ? data.timeStamp : new Date(data.timeStamp);
      // this.timeStamp = GeneralUtil.convertIonDateTimeToDateTimeString(data.timeStamp);
    }
  }

}
