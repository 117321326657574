<div class="main-content">
    <div class="content-body">
        <div class="container-fluid">
            <div class="row page-titles pt-2 mx-0">
                <div class="col-sm-6 p-md-0">
                    <div class="welcome-text">
                        <h4 *ngIf="patientTypeRoute === patientStatus.PRESUMPTIVE">Presumptive
                            Patients</h4>
                        <h4 *ngIf="patientTypeRoute === patientStatus.IN_CARE">Incare Patients</h4>
                        <h4 *ngIf="patientTypeRoute === patientStatus.LOST_TO_FOLLOWUP">Lost To
                            Follow Up Patients</h4>
                    </div>
                </div>
            </div>
            <div class="row mx-0">
                <div class="form-group col-md-3 mb-3 p-2">
                    <label for="state" class="form-label pl-2">Phone number</label>
                    <div id="phone">
                        <input type="tel" maxlength="13" class="form-control"
                            placeholder="Enter number.." [(ngModel)]="filterPatientDto.phonenumber"
                            (keypress)="numbersOnly($event)" (keyup.enter)="getPatientList()">
                    </div>
                </div>
                <div class="form-group col-md-3 mb-3 p-2"
                    *ngIf="patientTypeRoute === patientStatus.PRESUMPTIVE">
                    <label for="status" class="form-label pl-2">Status</label>
                    <div id="status" (change)="getPatientList()">
                        <select name="stage" class="form-select" id="stage"
                            [(ngModel)]="filterPatientDto.stage">
                            <option value="" selected>All</option>
                            <option value="POSITIVE_RESPONSE">Positive response</option>
                            <option value="EVALUATED">Evaluated</option>
                            <option value="DIAGNOSED">Diagnosed</option>
                            <option value="ON_TREATMENT">Placed on treatment</option>
                        </select>
                    </div>
                </div>
                <div class="form-group col-md-3 mb-3 p-2"
                    *ngIf="patientTypeRoute === patientStatus.IN_CARE">
                    <label for="status" class="form-label pl-2">Status</label>
                    <div id="status" (change)="getPatientList()">
                        <select name="stage" class="form-select" id="stage"
                            [(ngModel)]="filterPatientDto.stage">
                            <option value="" selected>All</option>
                            <option value="MEDICATION_ADHERENT">Medication adherent</option>
                            <option value="NON_ADHERENT">Medication Non-adherent</option>
                            <option value="AT_RISK">At risk</option>
                            <option value="TREATMENT_COMPLETED">Treatment completed</option>
                        </select>
                    </div>
                </div>
                <div class="form-group col-md-3 mb-3 p-2"
                    *ngIf="patientTypeRoute === patientStatus.LOST_TO_FOLLOWUP">
                    <label for="status" class="form-label pl-2">Status</label>
                    <div id="status" (change)="getPatientList()">
                        <select name="stage" class="form-select" id="stage"
                            [(ngModel)]="filterPatientDto.stage">
                            <option value="" selected>All</option>
                            <option value="CONTACTED">Contacted</option>
                            <option value="EVALUATED">Evaluated</option>
                            <option value="ON_TREATMENT">Back on treatment</option>
                        </select>
                    </div>
                </div>

                <div *ngIf="authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE')
                === 'NATIONAL_LEVEL_ADMIN' || authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE')
                === 'SUPER_ADMIN' || authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE')
                === 'STATE_LEVEL_ADMIN'" class="form-group col-md-3 mb-3 p-2">
                    <label for="start-date-input" class="form-label pl-2">State</label>
                    <select name="states" class="form-select" id="states"
                        (change)="getPatientList()" [(ngModel)]="filterPatientDto.state" required>
                        <option value="" selected>All States</option>
                        <option *ngFor="let state of states" value="{{state.name}}">{{state.name}}
                        </option>
                    </select>
                </div>
                <div *ngIf="authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE')
                === 'FACILITY_LEVEL_ADMIN'" class="form-group col-md-3 mb-3 p-2">
                    <label for="start-date-input" class="form-label pl-2">Facility</label>
                    <select name="facility" class="form-select" (change)="getPatientList()"
                        id="facilities1" [(ngModel)]="filterPatientDto.selectedFacility" required>
                        <option value="" selected>All Facilities</option>
                        <option *ngFor="let facility of facilities" value="{{facility.id}}">
                            {{facility.name}}
                        </option>
                    </select>
                </div>

                <div class="form-group col-md-3 mb-3 p-2 filter">
                    <div class="d-flex mt-4">
                        <div class="mx-1">
                            <a (click)="getPatientList()"
                                class="btn btn-sm bg-dark text-white ">Filter</a>
                        </div>
                        <a (click)="clearFilter()" class="btn btn-sm btn-danger text-white">Clear
                            Filter</a>
                    </div>
                </div>
            </div>
            <table class="table table-striped">
                <div *ngIf="showPreloader " class="spinner-border">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <div style="text-align: center; margin: 0 ;"
                    *ngIf="patients.length < 1 && showPreloader === false">
                    <img src="assets/images/empty-data.png" height="300px">
                    <div>No patient found, click clear filter to refresh.</div>
                </div>
                <thead *ngIf="patients.length > 0">
                    <tr>
                        <th scope="col">S/N</th>
                        <th scope="col">Phone number</th>
                        <th scope="col">State</th>
                        <th scope="col">LGA</th>
                        <th scope="col">Status</th>
                        <ng-container
                            *ngIf="userRole == 'SUPER_ADMIN' || userRole == 'FACILITY_LEVEL_ADMIN'">
                            <th scope="col">Update status</th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody *ngFor="let patient of patients; index as i">
                    <tr>
                        <td *ngIf="currentPage === 1"><strong>{{((i + 1))}}.</strong></td>
                        <td *ngIf="currentPage > 1 "><strong>{{((i + 1) + (limit * (currentPage -
                                1)))}}.</strong></td>
                        <td>{{patient.phone ? patient.phone : 'nil'}}</td>
                        <td>{{patient.state.name}}</td>
                        <td>{{patient.lga}}</td>
                        <td
                            *ngIf="patient.status == 'ON_TREATMENT'  && patientTypeRoute == patientStatus.PRESUMPTIVE">
                            <span class="badge light"
                                [ngClass]="{'badge-light': 'Placed on treatment'}"> Placed on
                                treatment</span>
                        </td>
                        <td
                            *ngIf="patient.status == 'ON_TREATMENT' && patientTypeRoute == patientStatus.IN_CARE ">
                            <span class="badge light" [ngClass]="{'badge-light': 'On treatment'}">On
                                treatment</span>
                        </td>
                        <!-- <td *ngIf="patient.status == 'NON_ADHERENT' && patientTypeRoute == patientStatus.IN_CARE "><span class="badge light" [ngClass]="{'badge-light': 'On treatment'}">Medication non-adherent</span></td> -->
                        <!-- <td *ngIf="patient.status != 'NON_ADHERENT' && patientTypeRoute == patientStatus.IN_CARE "><span class="badge light" [ngClass]="{'badge-light': 'On treatment'}">Medication non-adherent</span></td> -->
                        <td
                            *ngIf="patient.status == 'ON_TREATMENT' && patientTypeRoute == patientStatus.LOST_TO_FOLLOWUP ">
                            <span class="badge light"
                                [ngClass]="{'badge-light': 'Back on tretment'}"> Back on
                                treatment</span>
                        </td>
                        <td *ngIf="patient.status != 'ON_TREATMENT' "><span class="badge light"
                                [ngClass]="{'badge-light': patient.status  ? patient.status : patient.status}">{{removeUnderscore(patient.status)
                                | titlecase}}</span></td>
                        <ng-container
                            *ngIf="userRole == 'SUPER_ADMIN' || userRole == 'FACILITY_LEVEL_ADMIN'">
                            <td>
                                <div title="update status" class="update-status"
                                    (click)="getSinglePatient(patient)">
                                    <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="modal"
                                        data-bs-target="#changeStatus"
                                        class="icon icon-tabler icon-tabler-exchange" width="24"
                                        height="24" viewBox="0 0 24 24" stroke-width="1"
                                        stroke="#000" fill="none" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <circle cx="5" cy="18" r="2" />
                                        <circle cx="19" cy="6" r="2" />
                                        <path d="M19 8v5a5 5 0 0 1 -5 5h-3l3 -3m0 6l-3 -3" />
                                        <path d="M5 16v-5a5 5 0 0 1 5 -5h3l-3 -3m0 6l3 -3" />
                                    </svg>
                                </div>
                            </td>
                        </ng-container>

                    </tr>
                </tbody>
            </table>
        </div>
        <div class="pagination pagination-centered" *ngIf="patients.length  > 0">
            <ngb-pagination [collectionSize]="paginationData.totalSize"
                [(page)]="paginationData.currentPage" (pageChange)="getPatientList()"
                [pageSize]="paginationData.defaultLimit" [maxSize]="5" [rotate]="true"
                [boundaryLinks]="false">
            </ngb-pagination>
        </div>
    </div>
</div>

<div class="modal fade" id="changeStatus">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">Update status for<b> {{singlePatient.phone}}</b> </h6>
                <button type="button" class="btn btn-icon p-0" #close data-bs-dismiss="modal"
                    aria-label="Close">
                    <svg data-name="icons/tabler/close" xmlns="http://www.w3.org/2000/svg"
                        width="16" height="16" viewBox="0 0 16 16">
                        <rect data-name="Icons/Tabler/Close background" width="16" height="16"
                            fill="none"></rect>
                        <path
                            d="M.82.1l.058.05L6,5.272,11.122.151A.514.514,0,0,1,11.9.82l-.05.058L6.728,6l5.122,5.122a.514.514,0,0,1-.67.777l-.058-.05L6,6.728.878,11.849A.514.514,0,0,1,.1,11.18l.05-.058L5.272,6,.151.878A.514.514,0,0,1,.75.057Z"
                            transform="translate(2 2)" fill="#1e1e1e"></path>
                    </svg>
                </button>

            </div>
            <div class="modal-body">
                <small class="mx-5"><b>--Select status--</b></small>
                <div *ngIf="patientTypeRoute === patientStatus.PRESUMPTIVE" class="  mx-5">
                    <select class="custom-select form-select form-control input-dropdown"
                        aria-expanded="false" aria-label="Default select example" name="status"
                        #status="ngModel" [(ngModel)]="selectedStatus">
                        <option *ngFor="let list of presumptiveList" value="{{list.value}}"
                            [selected]="list.value == singlePatient.status">
                            {{ list.name }}
                        </option>
                    </select>


                </div>

                <div *ngIf="patientTypeRoute === patientStatus.IN_CARE"
                    class="card overflow-hidden mx-5">
                    <select class="custom-select form-select form-control input-dropdown"
                        aria-expanded="false" aria-label="Default select example" name="status"
                        #status="ngModel" [(ngModel)]="selectedStatus">
                        <option *ngFor="let list of incareList" value="{{list.value}}"
                            [selected]="list.value == singlePatient.status">
                            {{ list.name }}
                        </option>
                    </select>
                </div>
                <div *ngIf="patientTypeRoute === patientStatus.LOST_TO_FOLLOWUP"
                    class="card overflow-hidden mx-5">
                    <select class="custom-select form-select form-control input-dropdown"
                        aria-expanded="false" aria-label="Default select example" name="status"
                        #status="ngModel" [(ngModel)]="selectedStatus">
                        <option *ngFor="let list of ltfuList" value="{{list.value}}"
                            [selected]="list.value == singlePatient.status">
                            {{ list.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="modal-footer">
                <div *ngIf="confirmText" class="alert alert-warning" role="alert"><small
                        class="text-dark"><b>Confirm!</b></small>
                    <button type="button" (click)="resetValue()" class="btn btn-icon p-0"
                        style="float: right;" #close data-bs-dismiss="modal" aria-label="Close">
                        <svg data-name="icons/tabler/close" xmlns="http://www.w3.org/2000/svg"
                            width="16" height="16" viewBox="0 0 16 16">
                            <rect data-name="Icons/Tabler/Close background" width="16" height="16"
                                fill="none"></rect>
                            <path
                                d="M.82.1l.058.05L6,5.272,11.122.151A.514.514,0,0,1,11.9.82l-.05.058L6.728,6l5.122,5.122a.514.514,0,0,1-.67.777l-.058-.05L6,6.728.878,11.849A.514.514,0,0,1,.1,11.18l.05-.058L5.272,6,.151.878A.514.514,0,0,1,.75.057Z"
                                transform="translate(2 2)" fill="#1e1e1e"></path>
                        </svg>
                    </button><br>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        fill="currentColor" class="bi bi-exclamation-circle-fill"
                        viewBox="0 0 16 16">
                        <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                    </svg>
                    <small> Are you sure you want to update this patient status to
                        {{removeUnderscore(selectedStatus)}} ? <a (click)="changeStatus()"
                            class="text-dark ml-2 confirm"><b>Yes</b></a> </small>
                    <div *ngIf="loader" class="loader-border">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

                <button type="button" class="btn btn-sm btn-danger light" [disabled]="update"
                    (click)="checkUpdate()">Update</button>
            </div>
        </div>
    </div>
</div>