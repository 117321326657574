import { Component, OnInit } from '@angular/core';
import {GeneralUtil} from '../../../util/general-util';
import {ReportService} from '../../../service/report.service';
import {SmsDto} from '../../../dto/sms-dto';
import {FilterReportsDto} from '../../../dto/filter-reports-dto';

@Component({
  selector: 'app-sms-reports',
  templateUrl: './sms-reports.component.html',
  styleUrls: ['./sms-reports.component.css']
})
export class SmsReportsComponent implements OnInit {

  public showPreloader = false;
  public smsArray: SmsDto[] = [];
  public d = new Date();
  public exporting = false;
  public filterDto = new FilterReportsDto();

  constructor(
    public reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.d.setMonth(this.d.getMonth() - 1);
    this.getSmsReport();
  }

  clearFilter(){
    this.filterDto = new FilterReportsDto();
    this.getSmsReport();
  }

  exportToCsv() {
    this.exporting = true;
    setTimeout(() => {
      GeneralUtil.export2csv('sms-reports.csv');
    }, 200);
    setTimeout(() => {
      this.exporting = false;
    }, 300);
  }

  getSmsReport() {
      this.showPreloader = true;
      this.reportService.smsReport(this.filterDto.startDate, this.filterDto.endDate)
        .subscribe((data) => {
            this.showPreloader = false;
            this.smsArray = [];
            const tempSmsArray = data;
            if (tempSmsArray !== null && tempSmsArray.length > 0) {
              tempSmsArray.forEach(p => {
                const sms = new SmsDto();
                sms.mapToDto(p);
                this.smsArray.push(sms);
              });
            }
            // remove timezone from ussd timestamps
            this.smsArray.forEach(sms => {
              sms.dateLastPublished = GeneralUtil.removeTimezoneFromDateString(sms.dateLastPublished);
            });
            return this.smsArray;
          },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });

  }

  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }

}
