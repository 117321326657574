import { GeneralUtil } from '../util/general-util';

export class InCareDashboardDto {

  public onTreatment = 0;
  public medicationAdherent = 0;
  public atRisk = 0;
  public nonMedicalAdherent = 0;
  public treatmentComplete = 0;

  constructor() {}

  public mapToDashboardStats(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.onTreatment = data.onTreatment;
      this.medicationAdherent = data.medicationAdherent;
      this.atRisk = data.atRisk;
      this.nonMedicalAdherent = data.nonMedicalAdherent;
      this.treatmentComplete = data.treatmentComplete;
    }
  }
}
