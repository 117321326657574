import { GeneralUtil } from '../util/general-util';

export class FilterReportsDto {
  // set end date as today for datetime input
  // public endDate = GeneralUtil.convertDateTimeFormatToDateTimeString(
  //   new Date().toJSON()
  // );
  public endDate =  '';
  // set start date as three months from today for datetime input
  // public startDate = GeneralUtil.convertDateTimeFormatToDateTimeString(
  //   GeneralUtil.getPreviousMonthDateTime(new Date().toJSON().split('T'), 3)
  // );
  public startDate = ''
  public maxDate;
  public selectedState = '';
  public selectedFacility = '';
  public phoneNumber = '';
  public ussdActivated ='' ;
}
