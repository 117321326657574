import { GeneralUtil } from "../util/general-util";

export class PresumptiveCumulativeDto2 {
  public totalPatients: number;
  public totalOnTreatment: number;
  public totalEvaluated: number;
  public totalDiagnosed: number;
  public totalPositivePresumptiveScreening: number;
  public totalUssdActivation: number;
  constructor() { }

  public mapToPresumptiveCumulative2(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
        this.totalPatients = data.totalPatients;
        this.totalOnTreatment = data.totalOnTreatment.toLocaleString();
        this.totalEvaluated = data.totalEvaluated.toLocaleString();
        this.totalDiagnosed = data.totalDiagnosed.toLocaleString();
        this.totalPositivePresumptiveScreening = data.totalPositivePresumptiveScreening.toLocaleString();
        this.totalUssdActivation = data.totalUssdActivation.toLocaleString();
    }
}

}