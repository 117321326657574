import {GeneralUtil} from '../util/general-util';
import {PatientCategoryEnum} from '../constant/patient-category-enum';

export class SmsDto {

  public textMessage: any;
  public formattedTextMessage: any;
  public smsCode: string;
  public category: string;
  public successful: number;
  public failed: number;
  public pending: number;
  public total: number;
  public dateLastPublished: any;

  constructor() { }

  public mapToDto(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.textMessage = GeneralUtil.removeCommaFromString(GeneralUtil.stringFromLineBreaks(data.textMessage));
      this.formattedTextMessage = GeneralUtil.textFormatter(data.textMessage);
      this.smsCode = data.smsCode;
      this.successful = data.successful;
      this.failed = data.failed;
      this.pending = data.pending;
      this.total = data.total;
      this.dateLastPublished = new Date (data.dateLastPublished);
      switch (data.category) {
        case 'PATIENT_ENROLMENT':
          this.category = PatientCategoryEnum.PATIENT_ENROLMENT;
          break;
        case 'TRACKING_PATIENT_IN_CARE':
          this.category = PatientCategoryEnum.TRACKING_PATIENT_IN_CARE;
          break;
        case 'TRACKING_LOST_TO_FOLLOWUP':
          this.category = PatientCategoryEnum.TRACKING_LOST_TO_FOLLOWUP;
          break;
      }
    }
  }

}
