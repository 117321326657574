import { filter } from 'rxjs/operators';
import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute,Router,NavigationEnd } from '@angular/router';

import { PatientStatusEnum } from 'src/app/constant/patient-status-enum';
import { InCareUssdDto } from 'src/app/dto/in-care-ussd-dto';
import { LtfuUssdDto } from 'src/app/dto/ltfu-ussd-dto';
import { AuthService } from 'src/app/service/auth/auth.service';
import { PaginationDataDto } from 'src/app/dto/pagination-data-dto';
import { GeneralEnum } from 'src/app/constant/general-enum.enum';

import { RoleEnum } from '../../../constant/role-enum';
import { FilterReportsDto } from '../../../dto/filter-reports-dto';
import { PresumptiveUssdDto } from '../../../dto/presumptive-ussd-dto';
import { Facility } from '../../../entity/facility';
import { State } from '../../../entity/state';
import { LocationService } from '../../../service/location.service';
import { ReportService } from '../../../service/report.service';
import { UserService } from '../../../service/user.service';
import { GeneralUtil } from '../../../util/general-util';

@Component({
  selector: 'app-ussd-reports',
  templateUrl: './ussd-reports.component.html',
  styleUrls: ['./ussd-reports.component.css'],
})
export class UssdReportsComponent implements OnInit,OnDestroy {
  public report: any;
  public reportType: PatientStatusEnum;
  public patientStatus = PatientStatusEnum;
  public role = RoleEnum;
  public showPreloader = false;
  public ussdReports = [];
  public d = new Date();
  public states: State[] = [];
  public facilities: Facility[] = [];
  public page = 1;
  public pageSize = 30;
  public exporting = false;
  public filterReports = new FilterReportsDto();
  public sortVisited = false;
  public sortScreening = false;
  public sortAdherence = false;
  public paginationData = new PaginationDataDto();  // pagination object
  public currentPage: number;
  public limit: number;
  public queryStatus = '';

  constructor(
    public route: ActivatedRoute,
    public reportService: ReportService,
    public userService: UserService,
    public locationService: LocationService,
    public authService: AuthService,private router: Router
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((data) => {
      if (data.dialled !== '') {
        this.queryStatus = data.dialled;
      }

    })
    this.initializePaginationData();
    this.report = this.route.snapshot.params.reportType;

    /* When logged in user is State Admin */
    if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.STATE_LEVEL_ADMIN) {
      this.getStatesForStateAdmin();

      /* When logged in user is Facility Admin */
    } else if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.FACILITY_LEVEL_ADMIN) {
      this.getFacilitiesForFacilityAdmin();

      /* When logged in user is Super/National Admin */
    } else {
      this.getAllStates();
    }

    /* Hooking into route observable to listen to route changes and update reports*/
    this.report = this.route.params.subscribe((params) => {
      this.reportType = params.reportType;

      this.getUssdReports();

    });

  }

  /* Get USSD report based on patient type */
  getUssdReports() {
    this.reportType === this.patientStatus.PRESUMPTIVE ? this.getUssdReportForObjective1() :
      this.reportType === this.patientStatus.IN_CARE ? this.getUssdReportForObjective2() :
        this.getUssdReportForObjective3();
  }


  /* Presumptive Patients */
  getUssdReportForObjective1() {
    this.showPreloader = true;
    if (this.queryStatus) {
      this.filterReports.ussdActivated = this.queryStatus;
      this.queryStatus = ''
    }
    this.limit = this.paginationData.defaultLimit;
    this.currentPage = this.paginationData.currentPage;

    /* For State Admin */
    if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.STATE_LEVEL_ADMIN) {
      this.reportService.stateUssdReportForObjective1(
        this.limit,
        (this.currentPage - 1),
        this.filterReports.endDate,
        this.filterReports.startDate,
        this.filterReports.selectedState,
        this.filterReports.phoneNumber,
        this.filterReports.selectedFacility,
        this.filterReports.ussdActivated)
        .subscribe((data) => {
          this.showPreloader = false;
          this.ussdReports = [];
          this.paginationData.totalSize = data.total;
          const tempPresumptiveUssdArray = data.entities;
          if (
            tempPresumptiveUssdArray !== null &&
            tempPresumptiveUssdArray.length > 0
          ) {
            tempPresumptiveUssdArray.forEach((p) => {
              const presumptiveUssd = new PresumptiveUssdDto();
              presumptiveUssd.mapToDto(p);
              this.ussdReports.push(presumptiveUssd);
            });
          }
          // arrange the USSD by Latest sent
          GeneralUtil.arraySorter(this.ussdReports,'timeStamp','normal');
          // remove timezone from ussd timestamps
          this.ussdReports.forEach((ussd) => {
            ussd.timeStamp = GeneralUtil.removeTimezoneFromDateString(
              ussd.timeStamp
            );
          });
          return this.ussdReports;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          }
        );

      /* For Facility Admin */
    } else if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.FACILITY_LEVEL_ADMIN) {
      this.reportService.facilityUssdReportForObjective1(
        this.limit,
        (this.currentPage - 1),
        this.filterReports.endDate,
        this.filterReports.startDate,
        this.filterReports.phoneNumber,
        this.filterReports.selectedFacility,
        this.filterReports.ussdActivated,
      )
        .subscribe((data) => {
          this.showPreloader = false;
          this.ussdReports = [];
          this.paginationData.totalSize = data.total;
          const tempPresumptiveUssdArray = data.entities;
          if (
            tempPresumptiveUssdArray !== null &&
            tempPresumptiveUssdArray.length > 0
          ) {
            tempPresumptiveUssdArray.forEach((p) => {
              const presumptiveUssd = new PresumptiveUssdDto();
              presumptiveUssd.mapToDto(p);
              this.ussdReports.push(presumptiveUssd);
            });
          }
          // arrange the USSD by Latest sent
          GeneralUtil.arraySorter(this.ussdReports,'timeStamp','normal');
          // remove timezone from ussd timestamps
          this.ussdReports.forEach((ussd) => {
            ussd.timeStamp = GeneralUtil.removeTimezoneFromDateString(
              ussd.timeStamp
            );
          });
          return this.ussdReports;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });

      /* For Super/National Admin */
    } else {
      this.reportService
        .ussdReportForObjective1(
          this.limit,
          (this.currentPage - 1),
          this.filterReports.endDate,
          this.filterReports.startDate,
          this.filterReports.selectedState,
          this.filterReports.ussdActivated,
          this.filterReports.phoneNumber,
          this.filterReports.selectedFacility
        )
        .subscribe((data) => {



          this.showPreloader = false;
          this.ussdReports = [];
          this.paginationData.totalSize = data.total;
          const tempPresumptiveUssdArray = data.entities;
          if (
            tempPresumptiveUssdArray !== null &&
            tempPresumptiveUssdArray.length > 0
          ) {
            tempPresumptiveUssdArray.forEach((p) => {
              const presumptiveUssd = new PresumptiveUssdDto();
              presumptiveUssd.mapToDto(p);
              this.ussdReports.push(presumptiveUssd);
            });
          }
          // arrange the USSD by Latest sent
          GeneralUtil.arraySorter(this.ussdReports,'timeStamp','normal');
          // remove timezone from ussd timestamps
          this.ussdReports.forEach((ussd) => {
            ussd.timeStamp = GeneralUtil.removeTimezoneFromDateString(
              ussd.timeStamp
            );
          });
          return this.ussdReports;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          }
        );
    }

  }

  /* In-care Patients */
  getUssdReportForObjective2() {
    this.showPreloader = true;
    if (this.queryStatus) {
      this.filterReports.ussdActivated = this.queryStatus;
      this.queryStatus = ''
    }
    this.limit = this.paginationData.defaultLimit;
    this.currentPage = this.paginationData.currentPage;

    /* For State Admin */
    if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.STATE_LEVEL_ADMIN) {
      this.reportService
        .stateUssdReportForObjective2(
          this.limit,
          (this.currentPage - 1),
          this.filterReports.endDate,
          this.filterReports.startDate,
          this.filterReports.selectedState,
          this.filterReports.phoneNumber,
          this.filterReports.selectedFacility,
          this.filterReports.ussdActivated
        )
        .subscribe((data) => {
          this.showPreloader = false;
          this.ussdReports = [];
          this.paginationData.totalSize = data.total;
          const tempInCareUssdArray = data.entities;
          if (tempInCareUssdArray !== null && tempInCareUssdArray.length > 0) {
            tempInCareUssdArray.forEach((p) => {
              const inCareUssd = new InCareUssdDto();
              inCareUssd.mapToDto(p);
              this.ussdReports.push(inCareUssd);
            });
          }
          // arrange the USSD by Latest sent
          GeneralUtil.arraySorter(this.ussdReports,'timeStamp','normal');

          // remove timezone from ussd timestamps
          this.ussdReports.forEach((ussd) => {
            ussd.timeStamp = GeneralUtil.removeTimezoneFromDateString(
              ussd.timeStamp
            );
          });
          return this.ussdReports;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });

      /* For Facility Admin */
    } else if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.FACILITY_LEVEL_ADMIN) {
      this.reportService.facilityUssdReportForObjective2(
        this.limit,
        (this.currentPage - 1),
        this.filterReports.endDate,
        this.filterReports.startDate,
        this.filterReports.phoneNumber,
        this.filterReports.selectedFacility,
        this.filterReports.ussdActivated
      )
        .subscribe((data) => {
          this.showPreloader = false;
          this.ussdReports = [];
          this.paginationData.totalSize = data.total;
          const tempInCareUssdArray = data.entities;
          if (tempInCareUssdArray !== null && tempInCareUssdArray.length > 0) {
            tempInCareUssdArray.forEach((p) => {
              const inCareUssd = new InCareUssdDto();
              inCareUssd.mapToDto(p);
              this.ussdReports.push(inCareUssd);
            });
          }
          // arrange the USSD by Latest sent
          GeneralUtil.arraySorter(this.ussdReports,'timeStamp','normal');

          // remove timezone from ussd timestamps
          this.ussdReports.forEach((ussd) => {
            ussd.timeStamp = GeneralUtil.removeTimezoneFromDateString(
              ussd.timeStamp
            );
          });
          return this.ussdReports;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });

      /* For Super/National Admin */
    } else {
      this.reportService
        .ussdReportForObjective2(
          this.limit,
          (this.currentPage - 1),
          this.filterReports.endDate,
          this.filterReports.startDate,
          this.filterReports.selectedState,
          this.filterReports.ussdActivated,
          this.filterReports.phoneNumber,
          this.filterReports.selectedFacility
        )
        .subscribe((data) => {
          console.log(data);
          
          this.showPreloader = false;
          this.ussdReports = [];
          this.paginationData.totalSize = data.total;
          const tempInCareUssdArray = data.entities;
          if (tempInCareUssdArray !== null && tempInCareUssdArray.length > 0) {
            tempInCareUssdArray.forEach((p) => {
              const inCareUssd = new InCareUssdDto();
              inCareUssd.mapToDto(p);
              this.ussdReports.push(inCareUssd);
            });
          }
          // arrange the USSD by Latest sent
          GeneralUtil.arraySorter(this.ussdReports,'timeStamp','normal');

          // remove timezone from ussd timestamps
          this.ussdReports.forEach((ussd) => {
            ussd.timeStamp = GeneralUtil.removeTimezoneFromDateString(
              ussd.timeStamp
            );
          });
          return this.ussdReports;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });
    }

  }

  /* Lost to Follow up Patients */
  getUssdReportForObjective3() {
    this.showPreloader = true;
    if (this.queryStatus) {
      this.filterReports.ussdActivated = this.queryStatus;
      this.queryStatus = ''
    }
    this.limit = this.paginationData.defaultLimit;
    this.currentPage = this.paginationData.currentPage;

    /* For State Admin */
    if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.STATE_LEVEL_ADMIN) {
      this.reportService
        .stateUssdReportForObjective3(
          this.limit,
          (this.currentPage - 1),
          this.filterReports.endDate,
          this.filterReports.startDate,
          this.filterReports.selectedState,
          this.filterReports.phoneNumber,
          this.filterReports.selectedFacility,
          this.filterReports.ussdActivated
        )
        .subscribe((data) => {
          this.showPreloader = false;
          this.ussdReports = [];
          this.paginationData.totalSize = data.total;
          const tempLtfuUssdArray = data.entities;
          if (tempLtfuUssdArray !== null && tempLtfuUssdArray.length > 0) {
            tempLtfuUssdArray.forEach((p) => {
              const ltfuUssd = new LtfuUssdDto();
              ltfuUssd.mapToDto(p);
              this.ussdReports.push(ltfuUssd);
            });
          }
          // arrange the USSD by Latest sent
          GeneralUtil.arraySorter(this.ussdReports,'timeStamp','normal');

          // remove timezone from ussd timestamps
          this.ussdReports.forEach((ussd) => {
            ussd.timeStamp = GeneralUtil.removeTimezoneFromDateString(
              ussd.timeStamp
            );
          });
          return this.ussdReports;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });

      /* For Facility Admin */
    } else if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.FACILITY_LEVEL_ADMIN) {
      this.reportService
        .facilityUssdReportForObjective3(
          this.limit,
          (this.currentPage - 1),
          this.filterReports.endDate,
          this.filterReports.startDate,
          this.filterReports.phoneNumber,
          this.filterReports.selectedFacility,
          this.filterReports.ussdActivated
        )
        .subscribe((data) => {
          this.showPreloader = false;
          this.ussdReports = [];
          this.paginationData.totalSize = data.total;
          const tempLtfuUssdArray = data.entities;
          if (tempLtfuUssdArray !== null && tempLtfuUssdArray.length > 0) {
            tempLtfuUssdArray.forEach((p) => {
              const ltfuUssd = new LtfuUssdDto();
              ltfuUssd.mapToDto(p);
              this.ussdReports.push(ltfuUssd);
            });
          }
          // arrange the USSD by Latest sent
          GeneralUtil.arraySorter(this.ussdReports,'timeStamp','normal');

          // remove timezone from ussd timestamps
          this.ussdReports.forEach((ussd) => {
            ussd.timeStamp = GeneralUtil.removeTimezoneFromDateString(
              ussd.timeStamp
            );
          });
          return this.ussdReports;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });

      /* For Super/National Admin */
    } else {
      this.reportService
        .ussdReportForObjective3(
          this.limit,
          (this.currentPage - 1),
          this.filterReports.endDate,
          this.filterReports.startDate,
          this.filterReports.selectedState,
          this.filterReports.ussdActivated,
          this.filterReports.phoneNumber,
          this.filterReports.selectedFacility
        )
        .subscribe((data) => {
          this.showPreloader = false;
          this.ussdReports = [];
          this.paginationData.totalSize = data.total;
          const tempLtfuUssdArray = data.entities;
          if (tempLtfuUssdArray !== null && tempLtfuUssdArray.length > 0) {
            tempLtfuUssdArray.forEach((p) => {
              const ltfuUssd = new LtfuUssdDto();
              ltfuUssd.mapToDto(p);
              this.ussdReports.push(ltfuUssd);
            });
          }
          // arrange the USSD by Latest sent
          GeneralUtil.arraySorter(this.ussdReports,'timeStamp','normal');

          // remove timezone from ussd timestamps
          this.ussdReports.forEach((ussd) => {
            ussd.timeStamp = GeneralUtil.removeTimezoneFromDateString(
              ussd.timeStamp
            );
          });
          return this.ussdReports;
        },
          (error) => {
            this.showPreloader = false;
            this.errorHandler(error);
          });
    }

  }

  /* States Assigned to State Admin */
  getStatesForStateAdmin() {
    this.states = [];
    this.locationService.getStatesForStateAdmin()
      .subscribe(data => {
        const stateArray = data;
        if (stateArray.length > 0) {
          stateArray.forEach(s => {
            const sta = new State();
            sta.mapToState(s);
            this.states.push(sta);
          });
        }
      },error1 => {
        this.errorHandler(error1);
      });
  }

  getAllStates() {
    this.states = [];
    this.locationService.getActiveStates().subscribe(
      (data) => {
        const stateArray = data;
        if (stateArray.length > 0) {
          stateArray.forEach((s) => {
            const sta = new State();
            sta.mapToState(s);
            this.states.push(sta);
          });
        }
      },
      (error1) => {
        this.errorHandler(error1);
      }
    );
  }

  /* Facilities Assigned to Facility Admin */
  getFacilitiesForFacilityAdmin() {
    this.facilities = [];
    this.locationService.getFacilitiesForFacilityAdmin()
      .subscribe(data => {
        const facilityArray = data;
        if (facilityArray.length > 0) {
          facilityArray.forEach(f => {
            const facil = new Facility();
            facil.mapToFacility(f);
            this.facilities.push(facil);
          });
        }
      },error1 => {
        this.errorHandler(error1);
      })
  }

  getFacilitiesByState(stateId) {
    this.facilities = [];
    this.locationService.retrieveFacilityByState(stateId).subscribe(
      (data) => {
        const facilityArray = data;
        if (facilityArray.length > 0) {
          facilityArray.forEach((f) => {
            const fac = new Facility();
            fac.mapToFacility(f);
            this.facilities.push(fac);
          });
        }
      },
      (error1) => {
        this.errorHandler(error1);
      }
    );
  }

  changeFacility() {
    if (this.filterReports.selectedState === '') {
      return;
    } else {
      let filteredStateId;
      this.states.forEach((s) => {
        if (s.name === this.filterReports.selectedState) {
          filteredStateId = s.id;
        }
      });

      this.getFacilitiesByState(filteredStateId);
    }
  }

  sortByVisited() {
    this.sortVisited = !this.sortVisited;
    this.sortVisited === true
      ? GeneralUtil.arraySorter(
        this.ussdReports,
        'healthCenterVisitedSorter',
        'normal'
      )
      : GeneralUtil.arraySorter(
        this.ussdReports,
        'healthCenterVisitedSorter',
        'reverse'
      );
  }

  sortByScreening() {
    this.sortScreening = !this.sortScreening;
    this.sortScreening === true
      ? GeneralUtil.arraySorter(
        this.ussdReports,
        'presumptiveScreeningSorter',
        'normal'
      )
      : GeneralUtil.arraySorter(
        this.ussdReports,
        'presumptiveScreeningSorter',
        'reverse'
      );
  }

  sortByAdherence() {
    this.sortAdherence = !this.sortAdherence;
    this.sortAdherence === true
      ? GeneralUtil.arraySorter(
        this.ussdReports,
        'feedbackSorter',
        'normal'
      )
      : GeneralUtil.arraySorter(
        this.ussdReports,
        'feedbackSorter',
        'reverse'
      );
  }

  exportToCsv() {
    // this.exporting = true;
    setTimeout(() => {
      GeneralUtil.export2csv(`${this.reportType}-ussd-report.csv`);
    },200);
    setTimeout(() => {
      // this.exporting = false;
    },300);
  }

  clearFilter() {
    this.filterReports = new FilterReportsDto();
    this.getUssdReportForObjective1();
  }

  removeHyphen(mystring: string): string {
    return GeneralUtil.removeHyphen(mystring);
  }

  /*********PAGINATION FUNCTIONS***************/
  initializePaginationData() {
    this.paginationData.currentPage = 1;
    this.paginationData.defaultLimit = GeneralEnum.paginationLimit;
    this.paginationData.totalSize = 100;
  }


  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }

  ngOnDestroy() {
    /* unsubscribing from route observable to avoid memory leaks */
    this.report.unsubscribe();
  }
}
