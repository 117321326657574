import { GeneralUtil } from '../util/general-util';

export class PresumptiveDashboardDto {

  public positiveResponse = 0;
  public evaluated = 0;
  public diagnosed = 0;
  public onTreatment = 0;

  constructor() {}

  public mapToDashboardStats(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.positiveResponse = data.positiveResponse;
      this.evaluated = data.evaluated;
      this.diagnosed = data.diagnosed;
      this.onTreatment = data.onTreatment;
    }
  }
}
