import {GeneralUtil} from '../util/general-util';

export class InCareUssdDto {

  public feedback: string;
  public feedbackSorter = 0;
  public patientNumber: string;
  public barrier: string;
  public state: string;
  public lga: string;
  public facility: string;
  public gender: string;
  public age: number;
  public timeStamp: any;
  public duration: string;

  constructor() { }

  public mapToDto(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.patientNumber = data.patientNumber;
      this.feedback = data.feedback;
      this.barrier = data.barrier;
      if (data.feedback === 'YES') {
        this.feedbackSorter = 1;
      }
      if (data.feedback === 'NO') {
      this.feedbackSorter = -1;
        }
      this.state = data.state;
      this.lga = data.lga;
      this.facility = data.facility;
      this.gender = data.gender;
      this.age = data.age;
      this.duration = data.duration;
      this.timeStamp = data.timeStamp === 'Not Dialled' ? data.timeStamp : new Date(data.timeStamp);
    }
  }

}
