import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../service/auth/auth.service';
import {ChangeForgottenPasswordDto} from '../../dto/change-forgotten-password-dto';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {GeneralUtil} from '../../util/general-util';

declare function successToast(msg): any;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-forgotten-password.component.html',
  styleUrls: ['./change-forgotten-password.component.css']
})
export class ChangeForgottenPasswordComponent implements OnInit {

  public showPreloader = false;
  public forgotPasswordDto = new ChangeForgottenPasswordDto();

  constructor(
    public route: ActivatedRoute,
    public authService: AuthService,
    public router: Router) {
  }

  ngOnInit(): void {
    this.authService.resetUserDetails();
    // get token from route
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.forgotPasswordDto.token = params.get('token');
    });
  }

  changePassword() {
    this.showPreloader = true;
    this.authService.changeForgottenPassword(this.forgotPasswordDto)
      .subscribe((data) => {
          this.showPreloader = false;
          successToast('Password Successfully Changed');
          this.router.navigate(['/login']);
          return;
        },

        (error) => {
          this.showPreloader = false;
          this.errorHandler(error);
        }
      );
  }


  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }
}
