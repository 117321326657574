<div class="main-content">
  <div class="content-body">
    <div class="container-fluid">

      <div class="row page-titles mx-0">
        <div class="col-sm-12 p-md-0">
          <div class="welcome-text">
            <h4 *ngIf="reportType == 'Presumptive'"> General Population Ussd Reports</h4>
            <h4 *ngIf="reportType == 'In-Care' || reportType == 'Lost-to-Followup'">{{removeHyphen(reportType)}} USSD Reports</h4>
          </div>
        </div>
      </div>
      <div class="row mx-0">
        <div *ngIf="authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE')
        === 'NATIONAL_LEVEL_ADMIN' || authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE')
        === 'SUPER_ADMIN' || authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE')
        === 'STATE_LEVEL_ADMIN'" class="form-group col-md-4 my-3 p-2">
          <label for="state" class="form-label pl-2">State</label>
          <div id="state">
            <select name="states" class="form-select" id="states"
                    [(ngModel)]="filterReports.selectedState"  (change)="changeFacility()"  required>
              <option value="" selected >All States</option>
              <option *ngFor="let state of states" value="{{state.name}}">{{state.name}}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group col-md-4 my-3 p-2">
          <label for="facilities" class="form-label pl-2">Facility</label>
          <div id="facilities">
            <select name="facility" class="form-select" id="facilities1"
                    [(ngModel)]="filterReports.selectedFacility" required>
              <option value="" selected >All Facilities</option>
              <option *ngFor="let facility of facilities" value="{{facility.id}}">{{facility.name}}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group col-md-4 my-3 p-2">
          <label for="ussd" class="form-label pl-2">Ussd Activated</label>
          <div id="ussd"  (change)="getUssdReports()">
            <select name="ussd" class="form-select" id="ussd"
                    [(ngModel)]="filterReports.ussdActivated" required>
              <option value="" selected >All</option>
              <option value=true> Activated</option>
              <option value =false> Not Activated </option>
            </select>
          </div>
        </div>

        <div class="form-group col-md-4 my-3 p-2">
          <label for="start-date-input" class="form-label pl-2">Start Date</label>
          <div>
            <input [(ngModel)]="filterReports.startDate" class="form-control" type="datetime-local" value={{filterReports.startDate}} id="start-date-input" step="1">
          </div>
        </div>

        <div class="form-group col-md-4 my-3 p-2">
          <label for="end-date-input" class="form-label pl-2">End Date</label>
          <div class="d-flex">
            <input [(ngModel)]="filterReports.endDate" min="{{filterReports.startDate}}" max="{{filterReports.maxDate}}" class="form-control mr-3"
                   type="datetime-local" value="{{filterReports.endDate}}" step="1" id="end-date-input">
            <a (click)="getUssdReports()" class="btn btn-ddanger text-white
                   transition-3d-hover hover-shadow-danger mr-1 px-2">Filter</a>
            <a (click)="clearFilter()" class="btn btn-outline-danger
                   transition-3d-hover hover-shadow-danger px-1">Clear Filter</a>
          </div>
        </div>

      </div>

      <div class="row mt-2">
        <div class="col-lg-12">
          <div class="card border-top border-gray-200 pt-md-4">
            <div class="card-header d-flex justify-content-between">
              <h5 class="font-weight-semibold opensans-font mt-3 pb-md-4">Routine Report</h5>
              <div class="input-group w-50">
                <input type="search" class="form-control" placeholder="Search by phone number" [(ngModel)]="filterReports.phoneNumber"
                       value="{{filterReports.phoneNumber}}"
                       (keyup.enter)="getUssdReports()"
                       (blur)="getUssdReports()">
                <div class="input-group-append" style="height: 41px;">
                  <button class="btn btn-ddanger" type="button"
                          (click)="getUssdReports()">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search" width="20" height="20"
                         viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="10" cy="10" r="7" />
                      <line x1="21" y1="21" x2="15" y2="15" />
                    </svg>
                  </button>
                </div>
              </div>
              <a (click)="exportToCsv()" class="btn btn-outline-danger mr-2
                            transition-3d-hover hover-shadow-danger">Export CSV</a>
            </div>
            <div class="card-body">
              <div style="text-align: center; margin: 0 ;" *ngIf="ussdReports.length < 1
                            && showPreloader === false">
                <img src="assets/images/empty-data.png" height="200px">
                <div>NO DATA AVAILABLE</div>
              </div>

              <div class="table-responsive">
                <div *ngIf="showPreloader" class="card-body d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <table class="table table-borderless card-table table-nowrap">
                  <thead *ngIf="ussdReports.length > 0">
                  <tr>
                    <th><strong>#</strong></th>
                    <th><strong>DATE DIALLED</strong></th>
                    <th><strong>PHONE NUMBER</strong></th>
                    <!-- <th><strong>GENDER</strong></th>
                    <th><strong>AGE</strong></th> -->
                    <th><strong>STATE</strong></th>
                    <th><strong>LGA</strong></th>
                    <th><strong>FACILITY</strong></th>
                    <th *ngIf="reportType === patientStatus.PRESUMPTIVE"><strong>CONTACT WITH TB</strong></th>
                    <th *ngIf="reportType === patientStatus.IN_CARE" (click)="sortByAdherence()">
                      <svg *ngIf="sortAdherence" xmlns="http://www.w3.org/2000/svg"
                           class="icon icon-tabler icon-tabler-caret-up" width="20" height="20" viewBox="0 0 24 24"
                           stroke-width="1.5" stroke="#dc3545" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M18 15l-6 -6l-6 6h12" />
                      </svg>
                      <svg *ngIf="!sortAdherence" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-down" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#dc3545" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M18 15l-6 -6l-6 6h12" transform="rotate(180 12 12)" />
                      </svg>
                      <strong>ADHERENCE TO MEDICATION?</strong></th>
                      <th *ngIf="reportType === patientStatus.IN_CARE"><strong>DURATION OF MEDICATION NON-ADHERENCE </strong></th>
                    <th *ngIf="reportType === patientStatus.IN_CARE"><strong>BARRIERS TO ADHERENCE</strong></th>
                    <th *ngIf="reportType === patientStatus.PRESUMPTIVE"
                      (click)="sortByScreening()">
                      <svg *ngIf="sortScreening" xmlns="http://www.w3.org/2000/svg"
                           class="icon icon-tabler icon-tabler-caret-up" width="20" height="20" viewBox="0 0 24 24"
                           stroke-width="1.5" stroke="#dc3545" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M18 15l-6 -6l-6 6h12"/>
                      </svg>
                      <svg *ngIf="!sortScreening" xmlns="http://www.w3.org/2000/svg"
                           class="icon icon-tabler icon-tabler-caret-down" width="20" height="20" viewBox="0 0 24 24"
                           stroke-width="1.5" stroke="#dc3545" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M18 15l-6 -6l-6 6h12" transform="rotate(180 12 12)"/>
                      </svg>
                      <strong>PRESUMPTIVE SCREENING?</strong></th>
                    <th *ngIf="reportType === patientStatus.PRESUMPTIVE"><strong>SYMPTOMS</strong></th>
                    <!-- <th *ngIf="reportType === patientStatus.PRESUMPTIVE"
                      (click)="sortByVisited()">
                      <svg *ngIf="sortVisited" xmlns="http://www.w3.org/2000/svg"
                           class="icon icon-tabler icon-tabler-caret-up" width="20" height="20" viewBox="0 0 24 24"
                           stroke-width="1.5" stroke="#dc3545" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M18 15l-6 -6l-6 6h12"/>
                      </svg>
                      <svg *ngIf="!sortVisited" xmlns="http://www.w3.org/2000/svg"
                           class="icon icon-tabler icon-tabler-caret-down" width="20" height="20" viewBox="0 0 24 24"
                           stroke-width="1.5" stroke="#dc3545" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M18 15l-6 -6l-6 6h12" transform="rotate(180 12 12)"/>
                      </svg>
                      <strong>VISITED HEALTH CENTER?</strong></th> -->
                    <th *ngIf="reportType === patientStatus.LOST_TO_FOLLOWUP"><strong>REASON FOR NOT VISITING HEALTH CENTER</strong></th>
                  </tr>
                  </thead>
                  <tbody *ngIf="exporting === false">
                  <tr *ngFor="let ussdArray of ussdReports; index as i">
                    <td *ngIf="currentPage === 1"><strong>{{((i + 1))}}</strong></td>
                    <td *ngIf="currentPage > 1 "><strong>{{((i + 1) + (limit * (currentPage - 1)))}}</strong></td>
                    <td>{{ussdArray.timeStamp}}</td>
                    <td>{{ussdArray.patientNumber}}</td>
                    <!-- <td>{{ussdArray.gender}}</td>
                    <td>{{ussdArray.age}}</td> -->
                    <td>{{ussdArray.state}}</td>
                    <td>{{ussdArray.lga}}</td>
                    <td>{{ussdArray.facility}}</td>
                    <td *ngIf="reportType === patientStatus.PRESUMPTIVE">{{ussdArray.contactWithTB}}</td>
                    <td *ngIf="reportType === patientStatus.IN_CARE">{{ussdArray.feedback}}</td>
                    <td *ngIf="reportType === patientStatus.IN_CARE">{{ussdArray.duration}}</td>
                    <td *ngIf="reportType === patientStatus.IN_CARE">{{ussdArray.barrier}}</td>
                    <td *ngIf="reportType === patientStatus.PRESUMPTIVE">{{ussdArray.presumptiveScreening}}</td>
                    <td *ngIf="reportType === patientStatus.PRESUMPTIVE">{{ussdArray.patientSymptom}}</td>
                    <!-- <td *ngIf="reportType === patientStatus.PRESUMPTIVE">{{ussdArray.healthCenterVisited}}</td> -->
                    <td *ngIf="reportType === patientStatus.LOST_TO_FOLLOWUP">{{ussdArray.reason}}</td>
                  </tr>
                  </tbody>

                  <tbody *ngIf="exporting === true">
                  <tr *ngFor="let ussdArray of ussdReports; index as i">
                    <td *ngIf="currentPage === 1"><strong>{{((i + 1))}}</strong></td>
                    <td *ngIf="currentPage > 1 "><strong>{{((i + 1) + (limit * (currentPage - 1)))}}</strong></td>
                    <td>{{ussdArray.timeStamp}}</td>
                    <td>{{ussdArray.patientNumber}}</td>
                    <td>{{ussdArray.gender}}</td>
                    <td>{{ussdArray.age}}</td>
                    <td>{{ussdArray.state}}</td>
                    <td>{{ussdArray.lga}}</td>
                    <td>{{ussdArray.facility}}</td>
                    <td *ngIf="reportType === patientStatus.PRESUMPTIVE">{{ussdArray.contactWithTB}}</td>
                    <td *ngIf="reportType === patientStatus.IN_CARE">{{ussdArray.feedback}}</td>
                    <td *ngIf="reportType === patientStatus.IN_CARE">{{ussdArray.barrier}}</td>
                    <td *ngIf="reportType === patientStatus.PRESUMPTIVE">{{ussdArray.presumptiveScreening}}</td>
                    <td *ngIf="reportType === patientStatus.PRESUMPTIVE">{{ussdArray.patientSymptom}}</td>
                    <!-- <td *ngIf="reportType === patientStatus.PRESUMPTIVE">{{ussdArray.healthCenterVisited}}</td> -->
                    <td *ngIf="reportType === patientStatus.LOST_TO_FOLLOWUP">{{ussdArray.reason}}</td>
                  </tr>
                  </tbody>
                </table>

              </div>
              <div class="mt-4" *ngIf="exporting === false && ussdReports.length > 0">
                <ngb-pagination [collectionSize]="paginationData.totalSize" [(page)]="paginationData.currentPage"
                  (pageChange)="getUssdReports()"
                  [pageSize]="paginationData.defaultLimit"
                  [maxSize]="5" [rotate]="true" [boundaryLinks]="false">
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
