import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../../../service/report.service';
import { GeneralUtil } from '../../../../util/general-util';
import { PresumptiveUssdDto } from '../../../../dto/presumptive-ussd-dto';
import { State } from '../../../../entity/state';
import { UserService } from '../../../../service/user.service';
import { LocationService } from '../../../../service/location.service';
import { Facility } from '../../../../entity/facility';
import { FilterReportsDto } from '../../../../dto/filter-reports-dto';

@Component({
  selector: 'app-state-presumptive-ussd-reports',
  templateUrl: './state-presumptive-ussd-reports.component.html',
  styleUrls: ['./state-presumptive-ussd-reports.component.css']
})
export class StatePresumptiveUssdReportsComponent implements OnInit {
  public showPreloader = false;
  public presumptiveUssdArray: PresumptiveUssdDto[] = [];
  public d = new Date();
  public states: State[] = [];
  public facilities: Facility[] = [];
  public page = 1;
  public pageSize = 30;
  public exporting = false;
  public filterReports = new FilterReportsDto();
  public sortVisited = false;
  public sortScreening = false;

  constructor(public reportService: ReportService,
              public userService: UserService,
              public locationService: LocationService) { }

  ngOnInit(): void {
    this.d.setMonth(this.d.getMonth() - 1);
    this.getussdReportForObjective1(this.filterReports.endDate, this.filterReports.startDate);
    this.getStatesForStateAdmin();
  }

  clearFilter() {
    this.filterReports = new FilterReportsDto();
    this.getussdReportForObjective1(this.filterReports.endDate, this.filterReports.startDate);
  }

  getStatesForStateAdmin() {
    this.states = [];
    this.locationService.getStatesForStateAdmin()
      .subscribe(data => {
        const stateArray = data;
        if (stateArray.length > 0) {
          stateArray.forEach(s => {
            const sta = new State();
            sta.mapToState(s);
            this.states.push(sta);
          });
        }
      }, error1 => {
        this.errorHandler(error1);
      });
  }

  sortByVisited() {
    this.sortVisited = !this.sortVisited;
    this.sortVisited === true ?

      GeneralUtil.arraySorter(this.presumptiveUssdArray, 'healthCenterVisitedSorter', 'normal') :
      GeneralUtil.arraySorter(this.presumptiveUssdArray, 'healthCenterVisitedSorter', 'reverse');
  }

  sortByScreening() {
    this.sortScreening = !this.sortScreening;
    this.sortScreening === true ?
      GeneralUtil.arraySorter(this.presumptiveUssdArray, 'presumptiveScreeningSorter', 'normal') :
      GeneralUtil.arraySorter(this.presumptiveUssdArray, 'presumptiveScreeningSorter', 'reverse');
  }

  getFacilitiesByState(stateId) {
    this.facilities = [];
    this.locationService.retrieveFacilityByState(stateId)
      .subscribe(data => {
        const facilityArray = data;
        if (facilityArray.length > 0) {
          facilityArray.forEach(f => {
            const fac = new Facility();
            fac.mapToFacility(f);
            this.facilities.push(fac);
          });
        }
      }, error1 => {
        this.errorHandler(error1);
      });
  }

  changeFacility() {
    if (this.filterReports.selectedState === '') {
      return;
    } else {
      let filteredStateId;
      this.states.forEach(s => {
        if (s.name === this.filterReports.selectedState) {
          filteredStateId = s.id;
        }
      });

      this.getFacilitiesByState(filteredStateId);
    }

  }

  exportToCsv() {
    this.exporting = true;
    // return GeneralUtil.export2csv('presumptive-ussd-report.csv');
    setTimeout(() => {
      GeneralUtil.export2csv('presumptive-ussd-report.csv');
    }, 200);
    setTimeout(() => {
      this.exporting = false;
    }, 300);
  }

  getussdReportForObjective1(endDate, startDate) {

    this.showPreloader = true;
    this.reportService.stateUssdReportForObjective1(100, 0, endDate, startDate, this.filterReports.selectedState,
      this.filterReports.phoneNumber, this.filterReports.selectedFacility, this.filterReports.ussdActivated)
      .subscribe((data) => {
        this.showPreloader = false;
        this.presumptiveUssdArray = [];
        const tempPresumptiveUssdArray = data;
        if (tempPresumptiveUssdArray !== null && tempPresumptiveUssdArray.length > 0) {
          tempPresumptiveUssdArray.forEach(p => {
            const presumptiveUssd = new PresumptiveUssdDto();
            presumptiveUssd.mapToDto(p);
            this.presumptiveUssdArray.push(presumptiveUssd);
          });
        }
        // arrange the USSD by Latest sent
        GeneralUtil.arraySorter(this.presumptiveUssdArray, 'timeStamp', 'normal');
        // remove timezone from ussd timestamps
        this.presumptiveUssdArray.forEach(ussd => {
          ussd.timeStamp = GeneralUtil.removeTimezoneFromDateString(ussd.timeStamp);
        });
        return this.presumptiveUssdArray;
      },
        (error) => {
          this.showPreloader = false;
          this.errorHandler(error);
        });
  }

  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }


}
