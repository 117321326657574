import { Component, OnInit } from '@angular/core';
import {ReportService} from '../../../../service/report.service';
import {GeneralUtil} from '../../../../util/general-util';
import {InCareCumulativeDto} from '../../../../dto/in-care-cumulative-dto';
import {State} from '../../../../entity/state';
import {UserService} from '../../../../service/user.service';
import {LocationService} from '../../../../service/location.service';
import {FilterReportsDto} from '../../../../dto/filter-reports-dto';

declare function successToast(msg): any;

@Component({
  selector: 'app-cumulative-reports',
  templateUrl: './in-care-cumulative-reports.component.html',
  styleUrls: ['./in-care-cumulative-reports.component.css']
})
export class InCareCumulativeReportsComponent implements OnInit {

  public showPreloader = false;
  public inCareCumulativeDto = new InCareCumulativeDto();
  public filterReports = new FilterReportsDto();
  public states: State[] = [];

  constructor(
    public reportService: ReportService,
    public userService: UserService,
    public locationService: LocationService
  ) { }

  ngOnInit(): void {
    this.getCumulativeReportForObjective2(this.filterReports.endDate, this.filterReports.startDate);
    this.getAllStates();
  }

  getAllStates() {
    this.states = [];
    this.locationService.getActiveStates()
      .subscribe(data => {
        const stateArray = data;
        if (stateArray.length > 0) {
          stateArray.forEach(s => {
            const sta = new State();
            sta.mapToState(s);
            this.states.push(sta);
          });
        }
      }, error1 => {
        this.errorHandler(error1);
      });
  }

  clearFilter(){
    this.filterReports = new FilterReportsDto();
    this.getCumulativeReportForObjective2(this.filterReports.endDate, this.filterReports.startDate);
  }

  getCumulativeReportForObjective2(endDate, startDate) {
    this.showPreloader = true;
    this.reportService.cumulativeReportForObjective2(endDate, startDate, this.filterReports.selectedState)
      .subscribe((data) => {
          this.showPreloader = false;
          return this.inCareCumulativeDto.mapToDto(data);
        },
        (error) => {
          this.showPreloader = false;
          this.errorHandler(error);
        });
  }

  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }

}
