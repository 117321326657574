import {
  Component,
  OnInit,
} from '@angular/core';

import { PaginationDataDto } from '../../../dto/pagination-data-dto';
import { Patient } from '../../../entity/patient';
import { PatientService } from '../../../service/patient.service';
import { GeneralUtil } from '../../../util/general-util';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css']
})
export class PatientListComponent implements OnInit {
  public patients: Patient[] = [];
  public showPreloader = false;
  public paginationData = new PaginationDataDto();
  public currentPage: number;
  public defaultLimit: number;
  public phoneNumber = '';

  constructor(public patientService: PatientService) { }

  ngOnInit(): void {
    this.initializePaginationData();
    this.getPatients();
  }

  numbersOnly(event){
    return GeneralUtil.numbersOnly(event);
  }

  getPatients() {
    this.showPreloader = true;
    this.defaultLimit = this.paginationData.defaultLimit;
    this.currentPage = this.paginationData.currentPage;

    this.patientService.getPatients(this.defaultLimit, (this.currentPage - 1), this.phoneNumber)
      .subscribe(data => {
        this.showPreloader = false;
        this.patients = [];
        this.paginationData.totalSize = data.total;
        if (data.total > 0) {
          const patientArray = data.entities;
          patientArray.forEach(p => {
            const patient = new Patient();
            patient.mapToPatient(p);
            this.patients.push(patient);
          });
        }
      }, error1 => {
        this.showPreloader = false;
        this.errorHandler(error1);
      });
  }

  initializePaginationData() {
    this.paginationData.currentPage = 1;
    this.paginationData.defaultLimit = 50;
    this.paginationData.totalSize = 100;
  }

  removeUnderscore(mystring: string): string {
    return GeneralUtil.removeUnderscore(mystring);
  }

   // Error handling
   errorHandler(error) {
     return GeneralUtil.errorHandler(error);
  }
}
