import {GeneralUtil} from '../util/general-util';

export class StageStatsDto {

  public totalSmsBroadCast = 0;
  public totalSmsSuccessful = 0;
  public totalSmsPending = 0;
  public totalSmsFailed = 0;
  public patientCount = 0;

  constructor() {}

  public mapToStats(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.totalSmsBroadCast = data.totalSmsBroadCast;
      this.totalSmsSuccessful = data.totalSmsSuccessful;
      this.totalSmsPending = data.totalSmsPending;
      this.totalSmsFailed = data.totalSmsFailed;
      this.patientCount = data.patientCount;
    }
  }
}
