import { PatientType } from '../../../constant/patient-type.enum';
import { Component,ElementRef,OnInit,ViewChild } from '@angular/core';
import { PatientStatusEnum } from 'src/app/constant/patient-status-enum';
import { FilterReportsDto } from 'src/app/dto/filter-reports-dto';
import { PaginationDataDto } from 'src/app/dto/pagination-data-dto';
import { Patient } from 'src/app/entity/patient';
import { State } from 'src/app/entity/state';
import { LocationService } from 'src/app/service/location.service';
import { PatientService } from 'src/app/service/patient.service';
import { GeneralUtil } from 'src/app/util/general-util';
import { GeneralEnum } from 'src/app/constant/general-enum.enum';
import { ActivatedRoute } from '@angular/router';
import { FilterPatientDto } from 'src/app/dto/filter-patient-dto';
import { AuthService } from 'src/app/service/auth/auth.service';
import { RoleEnum } from 'src/app/constant/role-enum';
import { Facility } from 'src/app/entity/facility';


declare function successToast(msg): any;
declare function failureToast(msg): any;

@Component({
  selector: 'app-patient-lists',
  templateUrl: './patient-lists.component.html',
  styleUrls: ['./patient-lists.component.css']
})
export class PatientListsComponent implements OnInit {
  public patientStatus = PatientStatusEnum;
  public patientType = PatientType;
  public updatePatient = new Patient();
  public patients: Patient[] = [];
  public singlePatient = new Patient();
  public states: State[] = [];
  public filterReports = new FilterReportsDto();
  public showPreloader = false;
  public loader = false;
  public currentPage: number;
  public limit: number;
  public paginationData = new PaginationDataDto();
  public filterPatientDto = new FilterPatientDto();
  public filterPatientDtoPatient = new FilterPatientDto();
  public defaultLimit: number;
  public report: any;
  public patientTypeRoute: string;
  public role = RoleEnum;
  public facilities: Facility[] = [];
  public id: number;
  public confirmText = false;
  public update = false;
  public selectedStatus: string = '';
  @ViewChild('close') close: ElementRef;
  public queryStatus = '';
  public userRole: string;

  @ViewChild('closeModal') closeModal: ElementRef;
  public presumptiveList = [
    { name: 'Evaluated',value: 'EVALUATED' },
    { name: 'Diagnosed',value: 'DIAGNOSED' },
    { name: 'Placed on treatment',value: 'ON_TREATMENT' },

  ];

  public incareList = [
    { name: 'Medication adherent',value: 'MEDICATION_ADHERENT' },
    { name: 'Medication non-adherent',value: 'NON_ADHERENT' },
    { name: 'At risk to lost to followup',value: 'AT_RISK' },
    { name: 'Treatment completed',value: 'TREATMENT_COMPLETED' },

  ];

  public ltfuList = [
    { name: 'Contacted',value: 'CONTACTED' },
    { name: 'Evaluated',value: 'EVALUATED' },
    { name: 'Back on treatment',value: 'ON_TREATMENT' },

  ];

  constructor(public patientService: PatientService,
    public locationService: LocationService,
    public route: ActivatedRoute,
    public authService: AuthService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((data) => {

      this.userRole = this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE')
      if (data.status !== '') {
        this.queryStatus = data.status;

      }

    })
    this.initializePaginationData();
    this.report = this.route.snapshot.params.patientTypeRoute;
    /* When logged in user is State Admin */
    if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.STATE_LEVEL_ADMIN) {
      this.getStatesForStateAdmin();
    }
    /* When logged in user is Facility Admin */
    else if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.FACILITY_LEVEL_ADMIN) {
      this.getFacilitiesForFacilityAdmin();
      /* When logged in user is Super/National Admin */
    }
    else {
      this.getAllStates();
    }

    /* Hooking into route observable to listen to route changes and update reports*/
    this.report = this.route.params.subscribe((params) => {
      this.patientTypeRoute = params.patientTypeRoute;

      this.getPatientList();


    });

  }

  removeUnderscore(mystring: string): string {
    return GeneralUtil.removeUnderscore(mystring)
  }

  numbersOnly(event) {
    return GeneralUtil.numbersOnly(event);
  }

  /* Get States Assigned to State Admin */
  getStatesForStateAdmin() {
    this.states = [];
    this.locationService.getStatesForStateAdmin()
      .subscribe(data => {
        const stateArray = data;
        if (stateArray.length > 0) {
          stateArray.forEach(s => {
            const sta = new State();
            sta.mapToState(s);
            this.states.push(sta);
          });
        }
      },error1 => {
        this.errorHandler(error1);
      });
  }

  getPatientList() {
    if (this.patientTypeRoute === this.patientStatus.PRESUMPTIVE) {
      this.getPatientsPresumptive();
    } else if (this.patientTypeRoute === this.patientStatus.IN_CARE) {
      this.getPatientsIncare();
    } else {
      this.getPatientsLtfu();
    }

  }

  initializePaginationData() {
    this.paginationData.currentPage = 1;
    this.paginationData.defaultLimit = GeneralEnum.paginationLimit;
    this.paginationData.totalSize = 100;
  }

  getAllStates() {
    this.states = [];
    this.locationService.getActiveStates()
      .subscribe(data => {
        const stateArray = data;
        if (stateArray.length > 0) {
          stateArray.forEach(s => {
            const sta = new State();
            sta.mapToState(s);
            this.states.push(sta);
          });
        }
      },error1 => {
        this.errorHandler(error1);
      });
  }


  // PRESUMPTIVE PATIENTS
  getPatientsPresumptive() {
    this.showPreloader = true;
    if (this.queryStatus) {
      this.filterPatientDto.stage = this.queryStatus;
      this.queryStatus = '';
    }
    /* For State Admin */
    if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.STATE_LEVEL_ADMIN) {
      this.limit = this.paginationData.defaultLimit;
      this.currentPage = this.paginationData.currentPage;
      this.patientService.getPatientListForStateAdmin(this.limit,(this.currentPage - 1),this.patientType.PRESUMPTIVE,this.filterPatientDto.phonenumber,
        this.filterPatientDto.stage,this.filterPatientDto.state)
        .subscribe(data => {
          this.showPreloader = false;
          this.patients = [];
          this.paginationData.totalSize = data.total;
          if (data.total > 0) {
            const patientArray = data.entities;
            patientArray.forEach(p => {
              const patient = new Patient();
              patient.mapToPatient(p);
              this.patients.push(patient);
            });
          }
        },error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
    }

    /* For Facility Admin */
    else if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.FACILITY_LEVEL_ADMIN) {
      this.limit = this.paginationData.defaultLimit;
      this.currentPage = this.paginationData.currentPage;
      this.patientService.getPatientListForFacilityAdmin(this.limit,(this.currentPage - 1),this.patientType.PRESUMPTIVE,this.filterPatientDto.selectedFacility,this.filterPatientDto.phonenumber,
        this.filterPatientDto.stage,this.filterPatientDto.state)
        .subscribe(data => {
          this.showPreloader = false;
          this.patients = [];
          this.paginationData.totalSize = data.total;
          if (data.total > 0) {
            const patientArray = data.entities;
            patientArray.forEach(p => {
              const patient = new Patient();
              patient.mapToPatient(p);
              this.patients.push(patient);
            });
          }
        },error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });

    }
    else {
      //  For super/national admin users
      this.limit = this.paginationData.defaultLimit;
      this.currentPage = this.paginationData.currentPage;
      this.patientService.getPatientList(this.limit,(this.currentPage - 1),this.filterPatientDto.phonenumber,
        this.patientType.PRESUMPTIVE,this.filterPatientDto.stage,this.filterPatientDto.state)
        .subscribe(data => {
          this.showPreloader = false;
          this.patients = [];
          this.paginationData.totalSize = data.total;
          if (data.total > 0) {
            const patientArray = data.entities;
            patientArray.forEach(p => {
              const patient = new Patient();
              patient.mapToPatient(p);
              this.patients.push(patient);


            });
          }
        },error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
    }
    // this.filterPatientDto.stage = '';
  }


  // get Facility for facility admin users.
  getFacilitiesForFacilityAdmin() {
    this.facilities = [];
    this.locationService.getFacilitiesForFacilityAdmin()
      .subscribe(data => {
        const facilityArray = data;
        if (facilityArray.length > 0) {
          facilityArray.forEach(f => {
            const facil = new Facility();
            facil.mapToFacility(f);
            this.facilities.push(facil);
          });
        }
      },error1 => {
        this.errorHandler(error1);
      })
  }

  clearFilter() {
    this.filterPatientDto = new FilterPatientDto();
    switch (this.patientTypeRoute) {
      case this.patientStatus.PRESUMPTIVE:
        this.getPatientsPresumptive();
        break;
      case this.patientStatus.IN_CARE:
        this.getPatientsIncare();
        break;
      case this.patientStatus.LOST_TO_FOLLOWUP:
        this.getPatientsLtfu();
        break;
      default:
    }
  }

  //INCARE PATIENTS
  getPatientsIncare() {
    this.showPreloader = true;
    if (this.queryStatus) {
      this.filterPatientDto.stage = this.queryStatus;
      this.queryStatus = '';
    }
    /* For State Admin */
    if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.STATE_LEVEL_ADMIN) {
      this.limit = this.paginationData.defaultLimit;
      this.currentPage = this.paginationData.currentPage;
      this.patientService.getPatientListForStateAdmin(this.limit,(this.currentPage - 1),this.patientType.IN_CARE,this.filterPatientDto.phonenumber,
        this.filterPatientDto.stage,this.filterPatientDto.state)
        .subscribe(data => {
          this.showPreloader = false;
          this.patients = [];
          this.paginationData.totalSize = data.total;
          if (data.total > 0) {
            const patientArray = data.entities;
            patientArray.forEach(p => {
              const patient = new Patient();
              patient.mapToPatient(p);
              this.patients.push(patient);
            });
          }
        },error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
    }
    /* For Facility Admin */
    else if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.FACILITY_LEVEL_ADMIN) {
      this.limit = this.paginationData.defaultLimit;
      this.currentPage = this.paginationData.currentPage;
      this.patientService.getPatientListForFacilityAdmin(this.limit,(this.currentPage - 1),this.patientType.IN_CARE,this.filterPatientDto.selectedFacility,this.filterPatientDto.phonenumber,
        this.filterPatientDto.stage,this.filterPatientDto.state)
        .subscribe(data => {

          this.showPreloader = false;
          this.patients = [];
          this.paginationData.totalSize = data.total;
          if (data.total > 0) {
            const patientArray = data.entities;
            patientArray.forEach(p => {
              const patient = new Patient();
              patient.mapToPatient(p);
              this.patients.push(patient);
            });
          }
        },error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });

    }
    else {
      /*super/national admin */
      this.limit = this.paginationData.defaultLimit;
      this.currentPage = this.paginationData.currentPage;
      this.patientService.getPatientList(this.limit,(this.currentPage - 1),this.filterPatientDto.phonenumber,
        this.patientType.IN_CARE,this.filterPatientDto.stage,this.filterPatientDto.state)
        .subscribe(data => {
          this.showPreloader = false;
          this.patients = [];
          this.paginationData.totalSize = data.total;
          if (data.total > 0) {
            const patientArray = data.entities;
            patientArray.forEach(p => {
              const patient = new Patient();
              patient.mapToPatient(p);
              this.patients.push(patient);
            });
          }
        },error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
    }
  }

  // LTFU PATIENTS
  getPatientsLtfu() {
    this.showPreloader = true;
    if (this.queryStatus) {
      this.filterPatientDto.stage = this.queryStatus;
      this.queryStatus = '';
    }
    /* For State Admin */
    if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.STATE_LEVEL_ADMIN) {
      this.limit = this.paginationData.defaultLimit;
      this.currentPage = this.paginationData.currentPage;
      this.patientService.getPatientListForStateAdmin(this.limit,(this.currentPage - 1),this.patientType.LOST_TO_FOLLOWUP,this.filterPatientDto.phonenumber,
        this.filterPatientDto.stage,this.filterPatientDto.state)
        .subscribe(data => {
          this.showPreloader = false;
          this.patients = [];
          this.paginationData.totalSize = data.total;
          if (data.total > 0) {
            const patientArray = data.entities;
            patientArray.forEach(p => {
              const patient = new Patient();
              patient.mapToPatient(p);
              this.patients.push(patient);
            });
          }
        },error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
    }
    /* For Facility Admin */
    else if (this.authService.getUserDetailItem('KNCV-USER-DETAILS-ROLE') === this.role.FACILITY_LEVEL_ADMIN) {
      this.limit = this.paginationData.defaultLimit;
      this.currentPage = this.paginationData.currentPage;
      this.patientService.getPatientListForFacilityAdmin(this.limit,(this.currentPage - 1),this.patientType.LOST_TO_FOLLOWUP,this.filterPatientDto.selectedFacility,this.filterPatientDto.phonenumber,
        this.filterPatientDto.stage,this.filterPatientDto.state)
        .subscribe(data => {
          this.showPreloader = false;
          this.patients = [];
          this.paginationData.totalSize = data.total;
          if (data.total > 0) {
            const patientArray = data.entities;
            patientArray.forEach(p => {
              const patient = new Patient();
              patient.mapToPatient(p);
              this.patients.push(patient);
            });
          }

        },error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });

    }
    else {
      /*super/national admin */
      this.limit = this.paginationData.defaultLimit;
      this.currentPage = this.paginationData.currentPage;
      this.patientService.getPatientList(this.limit,(this.currentPage - 1),this.filterPatientDto.phonenumber,this.patientType.LOST_TO_FOLLOWUP,
        this.filterPatientDto.stage,this.filterPatientDto.state)
        .subscribe(data => {
          this.showPreloader = false;
          this.patients = [];
          this.paginationData.totalSize = data.total;
          if (data.total > 0) {
            const patientArray = data.entities;
            patientArray.forEach(p => {
              const patient = new Patient();
              patient.mapToPatient(p);
              this.patients.push(patient);
              return this.patients
            });
          }
        },error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
    }
  }

  getSinglePatient(patient) {
    this.singlePatient = new Patient();
    this.singlePatient = patient;
    this.id = Number(this.singlePatient.id);
  }

  changeStatus() {
    if (this.userRole == RoleEnum.SUPER_ADMIN || this.userRole == RoleEnum.FACILITY_LEVEL_ADMIN) {
      if (this.patientTypeRoute === this.patientStatus.PRESUMPTIVE) {
        this.changePatientStatusPresumptive();
      } else if (this.patientTypeRoute === this.patientStatus.IN_CARE) {
        this.changePatientStatusIncare();
      } else {
        this.changePatientStatusLtfu();
      }
    }

  }

  changePatientStatusPresumptive() {
    this.loader = true;
    this.patientService.changePatientStatusPresumptive(this.id,this.selectedStatus).subscribe(data => {
      this.loader = false;
      if (this.selectedStatus == 'ON_TREATMENT') {
        successToast('Patient  successfully moved to Incare patient list');

      }
      else {
        successToast('Patient status updated to ' + GeneralUtil.removeUnderscore(this.selectedStatus));
      }

      /*  get a patient and map to new patient */
      this.updatePatient = new Patient();
      this.updatePatient.mapToPatient(data);
      /* find the index of that particular patient, from role array, use the index to
        update the new patient */
      const index = this.patients.findIndex(patientindex => patientindex.id == this.updatePatient.id);
      this.patients[index] = this.updatePatient;
      this.update = false;
      window.location.reload();
      setTimeout(() => {
        this.loader = false;
        this.close.nativeElement.click();
        this.confirmText = false;
      },500)
    },error1 => {
      this.loader = false;
      this.errorHandler(error1);
    });
  }

  changePatientStatusIncare() {
    this.loader = true;
    this.patientService.changePatientStatusIncare(this.id,this.selectedStatus).subscribe(data => {
      this.loader = false;
      if (this.selectedStatus == 'AT_RISK') {
        successToast('Patient  successfully updated to At Risk To Followup');

      }
      else {
        successToast('Patient status updated to ' + GeneralUtil.removeUnderscore(this.selectedStatus));
      }
      /*  get a patient and map to new patient */
      this.updatePatient = new Patient();
      this.updatePatient.mapToPatient(data);
      /* find the index of that particular patient, from role array, use the index to
        update the new patient */
      const index = this.patients.findIndex(patientindex => patientindex.id == this.updatePatient.id);
      this.patients[index] = this.updatePatient;
      this.update = false;
      window.location.reload();
      setTimeout(() => {
        this.loader = false;
        this.close.nativeElement.click();
        this.confirmText = false;


      },500)
    },error1 => {
      this.loader = false;
      this.errorHandler(error1);
    });
  }

  changePatientStatusLtfu() {
    this.loader = true;
    this.patientService.changePatientStatusLtfu(this.id,this.selectedStatus).subscribe(data => {
      this.loader = false;
      if (this.selectedStatus == 'ON_TREATMENT') {
        successToast('Patient  successfully moved to Incare patient list');

      }
      else {
        successToast('Patient status updated to ' + GeneralUtil.removeUnderscore(this.selectedStatus));
      }
      /*  get a patient and map to new patient */
      this.updatePatient = new Patient();
      this.updatePatient.mapToPatient(data);
      /* find the index of that particular patient, from role array, use the index to
        update the new patient */
      const index = this.patients.findIndex(patientindex => patientindex.id == this.updatePatient.id);
      this.patients[index] = this.updatePatient;
      this.update = false;
      window.location.reload();
      setTimeout(() => {
        this.loader = false;
        this.close.nativeElement.click();
        this.close.nativeElement.click();
        this.confirmText = false;
      },500)
    },error1 => {
      this.loader = false;
      this.errorHandler(error1);
    });
  }

  checkUpdate() {
    if (this.selectedStatus === '') {
      return failureToast('Please select a status');
    }
    this.update = true;
    this.confirmText = true;

  }

  resetValue() {
    this.update = false;
    this.confirmText = false;
  }

  static removeUnderscore(mystring: string): string {
    return mystring.replace(/_/g,' ');
  }

  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }



}
