import { AppService } from './app.service';
import { HeaderComponent } from './component/tags/header/header.component';
import { NavigationEnd,Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from './service/auth/auth.service';
import { filter } from 'rxjs/operators';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public idleState = 'Not started.';
  public timedOut = false;
  public lastPing?: Date = null;
  public onLogin: boolean;;
  title = 'kncv-web';
  nothome = true;

  @ViewChild(HeaderComponent) headerApp: HeaderComponent;
  @ViewChild('idleModal') idleModal: ElementRef;
  @ViewChild('close') closeButton: ElementRef;
  constructor(
    public authService: AuthService,public router: Router,private appService: AppService,private idle: Idle, private keepalive: Keepalive
  ) {
      // sets an idle timeout of 30 minutes.
      idle.setIdle(1800);
      // sets a timeout period of 30 minutes, the user will be considered timed out.
      idle.setTimeout(10);
      // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);


      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd))
        .subscribe((event: any) => {
          if(event.url === '/login') {
            this.onLogin = true;
            idle.stop();
            this.reset();
          } else {
            this.onLogin = false;
            idle.watch();
          }
      } )


    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      idle.watch();
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.logout();
    });


    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';

      this.idleModal.nativeElement.click();
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will logged out in ' + countdown + ' seconds!';
    });

    // sets the ping interval to 10 minutes
    keepalive.interval(600);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.appService.getUserLoggedIn()
    .subscribe(userLoggedIn => {
    if (userLoggedIn) {
      idle.watch();
      this.timedOut = false;
    } else {
      idle.stop();
      this.timedOut = true;
    }
  })

  }
  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      switch (event.url) {
        case '/':
          this.nothome = false;
          break;
        case '/home':
          this.nothome = false;
          break;
        default:
          this.nothome = true;
      }
    });
  }

  reset() {
    this.timedOut = false;
  }

  logout() {
    this.closeButton.nativeElement.click();
    this.authService.resetUserDetails();
    this.appService.setUserLoggedIn(false);
    this.router.navigate(['/login']);
  }

  closeNavigation() {
    this.headerApp.hamburger.nativeElement.click();
  }
}
