import {GeneralUtil} from '../util/general-util';

export class InCareCumulativeDto {
  public totalSmsBroadCastSent: number;
  public totalSmsBroadCastSuccessful: number;
  public totalSmsBroadCastPending: number;
  public totalSmsBroadcastFailed: number;
  public totalUssdActivation: number;
  public totalPositiveFeedback: number;

  constructor() { }

  public mapToDto(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.totalSmsBroadCastSent = data.totalSmsBroadCastSent.toLocaleString();
      this.totalSmsBroadCastSuccessful = data.totalSmsBroadCastSuccessful.toLocaleString();
      this.totalSmsBroadCastPending = data.totalSmsBroadCastPending.toLocaleString();
      this.totalSmsBroadcastFailed = data.totalSmsBroadcastFailed.toLocaleString();
      this.totalUssdActivation = data.totalUssdActivation.toLocaleString();
      this.totalPositiveFeedback = data.totalPositiveFeedback.toLocaleString();
    }
  }


}
