<div class="bg-ddanger signup-header text-center">
  <img src="assets/images/svg/login-kncv-logo1.svg" alt="img">
</div>
<div class="container">
  <div class="simple-login-form rounded-12 shadow-dark-80 bg-white">
    <h2 class="mb-3">Login</h2>
    <form #loginForm="ngForm" (ngSubmit)="login()" class="pt-3" novalidate>
      <div class="mb-4 pb-md-2">
        <label class="form-label form-label-lg" for="Email">Email</label>
        <input [(ngModel)]="loginDto.email" class="form-control form-control-xl" id="Email" name="email"
               placeholder="Email" type="email">
      </div>
      <div class="mb-4 pb-md-2">
        <label class="form-label form-label-lg" for="Password">Password</label>
        <input [(ngModel)]="loginDto.password" class="form-control form-control-xl" id="Password" name="password"
               placeholder="••••••••••••••••" type="password">
      </div>
      <div class="d-grid">
        <button *ngIf="!showPreloader" [disabled]="loginForm.form.invalid" class="btn btn-xl btn-ddanger" type="submit">
          Sign In
        </button>
        <button *ngIf="showPreloader" class="btn btn-xl btn-ddanger" disabled type="button">
          <span aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"></span>
          Loading...
        </button>
      </div>
      <div class="my-3 my-sm-4 d-flex">
        <div class="form-check form-check-sm mb-0">
          <input class="form-check-input" id="gridCheck" type="checkbox">
          <label class="form-check-label small text-gray-600" for="gridCheck">
            Remember me
          </label>
        </div>

        <a data-bs-target="#basicModal" data-bs-toggle="modal" class="small text-gray-600 ms-auto mt-1" href="#">
          Forgot password?</a>
      </div>
    </form>
  </div>
</div>
<!-- Forgot Password Modal -->
<div aria-hidden="true" class="modal fade" id="basicModal" role="dialog" tabindex="-1">
  <div class="modal-dialog" role="document">
    <div class="modal-content shadow-dark-80">
      <div class="modal-header border-0 pb-0 align-items-start ps-4">
        <h5 class="modal-title pt-3">Forgot Your Password?</h5>
        <button #close aria-label="Close" class="btn btn-icon p-0" data-bs-dismiss="modal" type="button">
          <svg data-name="icons/tabler/close" height="16" viewBox="0 0 16 16" width="16"
               xmlns="http://www.w3.org/2000/svg">
            <rect data-name="Icons/Tabler/Close background" fill="none" height="16" width="16"></rect>
            <path
              d="M.82.1l.058.05L6,5.272,11.122.151A.514.514,0,0,1,11.9.82l-.05.058L6.728,6l5.122,5.122a.514.514,0,0,1-.67.777l-.058-.05L6,6.728.878,11.849A.514.514,0,0,1,.1,11.18l.05-.058L5.272,6,.151.878A.514.514,0,0,1,.75.057Z"
              fill="#1e1e1e" transform="translate(2 2)"></path>
          </svg>
        </button>
      </div>
      <div class="modal-body pt-2 px-4">
        <p>Enter your Email to receive a reset password link</p>

        <label class="form-label form-label-lg" for="youremail">Email</label>
        <input [(ngModel)]="forgotPasswordDto.usernameOrEmail" class="form-control form-control-xl" id="youremail" name="youremail"
               placeholder="Your Email" type="email">
        <div *ngIf="showPreloader" class="card-body d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button class="btn btn-light px-2" data-bs-dismiss="modal" type="button"><span class="px-1">Cancel</span>
        </button>
        <button (click)="forgotPassword()" class="btn btn-ddanger px-2 ms-2" type="button"><span class="px-1">Send Email</span>
        </button>
      </div>
    </div>
  </div>
</div>

