<div class="header border-bottom border-gray-200 header-fixed">
  <div class="container-fluid px-0">
    <div class="header-body px-3 px-xxl-5 py-lg-3">
      <div class="row align-items-center">
        <a href="javascript:void(0);" class="muze-hamburger d-block d-lg-none col-auto">
          <img src="assets/images/svg/hamburger1.svg" alt="img">
          <img src="assets/images/svg/close1.svg" style="width:20px;"
               class="menu-close" alt="img">
        </a>
        <a class="navbar-brand mx-auto d-lg-none col-auto px-0" href="#">
          <img src="assets/images/vector.png" alt="KNCV">
        </a>
        <div class="col d-flex flex-column">
          <a class="back-arrow bg-white circle circle-sm shadow border border-gray-200 rounded mb-0"
             href="javascript:void(0);">
            <svg
              height="13" viewBox="0 0 16 16" width="13" xmlns="http://www.w3.org/2000/svg">
              <g data-name="icons/tabler/chevrons-left" transform="translate(0)">
                <rect data-name="Icons/Tabler/Chevrons Left background" fill="none" height="16" width="16"/>
                <path
                  d="M14.468,14.531l-.107-.093-6.4-6.4a.961.961,0,0,1-.094-1.25l.094-.107,6.4-6.4a.96.96,0,0,1,1.451,1.25l-.094.108L10,7.36l5.72,5.721a.961.961,0,0,1,.094,1.25l-.094.107a.96.96,0,0,1-1.25.093Zm-7.68,0-.107-.093-6.4-6.4a.961.961,0,0,1-.093-1.25l.093-.107,6.4-6.4a.96.96,0,0,1,1.45,1.25l-.093.108L2.318,7.36l5.72,5.721a.96.96,0,0,1,.093,1.25l-.093.107a.96.96,0,0,1-1.25.093Z"
                  fill="#6C757D" transform="translate(0 1)"/>
              </g>
            </svg>
          </a>
        </div>
        <div class="col-auto d-flex flex-wrap align-items-center icon-blue-hover ps-0">
          <span><h3>{{greeting}} {{fullname}}<h6>{{role}}</h6></h3></span>

          <div class="dropdown profile-dropdown">
            <a aria-expanded="false" class="avatar avatar-sm avatar-circle ms-4 ms-xxl-5" data-bs-toggle="dropdown"
               href="#" id="dropdownMenuButton">
              <svg class="bi bi-person mt-1" fill="black" height="34" viewBox="0 0 16 16" width="34"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
              </svg>
            </a>
            <ul aria-labelledby="dropdownMenuButton" class="dropdown-menu dropdown-menu-end">
              <li class="pt-2 px-4">
                <span class="fs-16 font-weight-bold text-black-600 Montserrat-font me-2">Options</span>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li>
                <a class="dropdown-item" data-bs-target="#forgotModal" data-bs-toggle="modal">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-key" width="20"
                       height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none"
                       stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <circle cx="8" cy="15" r="4"/>
                    <line x1="10.85" y1="12.15" x2="19" y2="4"/>
                    <line x1="18" y1="5" x2="20" y2="7"/>
                    <line x1="15" y1="8" x2="17" y2="10"/>
                  </svg>
                  <span class="ms-2">Change Password</span></a>
              </li>

              <li>
                <a class="dropdown-item" data-bs-target="#basicModal" data-bs-toggle="modal">
                  <svg class="icon icon-tabler icon-tabler-logout" fill="none"
                       height="20" stroke="#dc3545" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                       viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0h24v24H0z" fill="none" stroke="none"/>
                    <path
                      d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"/>
                    <path d="M7 12h14l-3 -3m0 6l3 -3"/>
                  </svg>
                  <span class="text-danger ms-2">Log out</span></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<-- Logout Modal -->
<div aria-hidden="true" class="modal fade" id="basicModal" role="dialog" tabindex="-1">
  <div class="modal-dialog" role="document">
    <div class="modal-content shadow-dark-80">
      <div class="modal-header border-0 pb-0 align-items-start ps-4">
        <h5 class="modal-title pt-3">Confirm Logout</h5>
        <button aria-label="Close" class="btn btn-icon p-0" data-bs-dismiss="modal" type="button">
          <svg data-name="icons/tabler/close" height="16" viewBox="0 0 16 16" width="16"
               xmlns="http://www.w3.org/2000/svg">
            <rect data-name="Icons/Tabler/Close background" fill="none" height="16" width="16"></rect>
            <path
              d="M.82.1l.058.05L6,5.272,11.122.151A.514.514,0,0,1,11.9.82l-.05.058L6.728,6l5.122,5.122a.514.514,0,0,1-.67.777l-.058-.05L6,6.728.878,11.849A.514.514,0,0,1,.1,11.18l.05-.058L5.272,6,.151.878A.514.514,0,0,1,.75.057Z"
              fill="#1e1e1e" transform="translate(2 2)"></path>
          </svg>
        </button>
      </div>
      <div class="modal-body pt-2 px-4">
        <p>Are you sure you want to logout?</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-light px-2" data-bs-dismiss="modal" type="button"><span class="px-1">Cancel</span>
        </button>
        <button (click)="logout()" class="btn btn-ddanger px-2 ms-2" type="button"><span class="px-1">Logout</span>
        </button>
      </div>
    </div>
  </div>
</div>


<-- Change Password Modal -->
<div aria-hidden="true" class="modal fade" id="forgotModal" role="dialog" tabindex="-1">
  <div class="modal-dialog" role="document">
    <div class="modal-content shadow-dark-80">
      <div class="modal-header border-0 pb-0 align-items-start ps-4">
        <h5 class="modal-title pt-3">Change Password</h5>
        <button #close aria-label="Close" class="btn btn-icon p-0" data-bs-dismiss="modal" type="button">
          <svg data-name="icons/tabler/close" height="16" viewBox="0 0 16 16" width="16"
               xmlns="http://www.w3.org/2000/svg">
            <rect data-name="Icons/Tabler/Close background" fill="none" height="16" width="16"></rect>
            <path
              d="M.82.1l.058.05L6,5.272,11.122.151A.514.514,0,0,1,11.9.82l-.05.058L6.728,6l5.122,5.122a.514.514,0,0,1-.67.777l-.058-.05L6,6.728.878,11.849A.514.514,0,0,1,.1,11.18l.05-.058L5.272,6,.151.878A.514.514,0,0,1,.75.057Z"
              fill="#1e1e1e" transform="translate(2 2)"></path>
          </svg>
        </button>
      </div>
      <form #changePasswordForm="ngForm" (ngSubmit)="changePassword()" class="pt-3" novalidate>
        <div class="modal-body pt-2 px-4">
          <p>Please enter new password and confirm</p>

          <label class="form-label form-label-lg" for="oldpassword">Old Password</label>
          <input [(ngModel)]="changeYourPasswordDto.oldPassword" class="form-control form-control-xl" id="oldpassword"
                 name="oldpassword" required placeholder="Previous Password" type="password">
          <label class="form-label form-label-lg mt-3" for="newpassword">New Password</label>
          <input [(ngModel)]="changeYourPasswordDto.newPassword" class="form-control form-control-xl" id="newpassword"
                 name="newpassword" required placeholder="New password" type="password">
          <label class="form-label form-label-lg mt-3" for="confirmPassword">Confirm Password</label>
          <input [(ngModel)]="changeYourPasswordDto.confirmPassword" class="form-control form-control-xl"
                 id="confirmPassword"
                 name="confirmPassword" required placeholder="Must match with new password" type="password">
          <div *ngIf="showPreloader" class="card-body d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button class="btn btn-light px-2" data-bs-dismiss="modal" type="button"><span class="px-1">Cancel</span>
          </button>
          <button [disabled]="changePasswordForm.form.invalid" class="btn btn-ddanger px-2 ms-2" type="submit"><span
            class="px-1">Change Password</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

