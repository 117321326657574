import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../../../service/report.service';
import { GeneralUtil } from '../../../../util/general-util';
import { LtfuUssdDto } from '../../../../dto/ltfu-ussd-dto';
import { State } from '../../../../entity/state';
import { UserService } from '../../../../service/user.service';
import { LocationService } from '../../../../service/location.service';
import { Facility } from '../../../../entity/facility';
import { FilterReportsDto } from '../../../../dto/filter-reports-dto';


@Component({
  selector: 'app-facility-ltfu-ussd-reports',
  templateUrl: './facility-ltfu-ussd-reports.component.html',
  styleUrls: ['./facility-ltfu-ussd-reports.component.css']
})
export class FacilityLtfuUssdReportsComponent implements OnInit {
  public showPreloader = false;
  public ltfuUssdArray: LtfuUssdDto[] = [];
  public filterDto = new FilterReportsDto();
  public d = new Date();
  public states: State[] = [];
  public facilities: Facility[] = [];
  public page = 1;
  public pageSize = 30;
  public exporting = false;

  constructor(
    public reportService: ReportService,
    public userService: UserService,
    public locationService: LocationService) {
  }

  ngOnInit(): void {
    this.d.setMonth(this.d.getMonth() - 1);
    this.getussdReportForObjective3(this.filterDto.endDate, this.filterDto.startDate);
    this.getFacilitiesForFacilityAdmin();
  }

  clearFilter() {
    this.filterDto = new FilterReportsDto();
    this.getussdReportForObjective3(this.filterDto.endDate, this.filterDto.startDate);
  }

  numbersOnly(event){
    return GeneralUtil.numbersOnly(event);
  }

  getussdReportForObjective3(endDate, startDate) {
    this.showPreloader = true;
    this.reportService.facilityUssdReportForObjective3
      (100, 0, endDate, startDate, this.filterDto.phoneNumber, this.filterDto.selectedFacility
        , this.filterDto.ussdActivated)
      .subscribe((data) => {
        this.showPreloader = false;
        this.ltfuUssdArray = [];
        const tempLtfuUssdArray = data;
        if (tempLtfuUssdArray !== null && tempLtfuUssdArray.length > 0) {
          tempLtfuUssdArray.forEach(p => {
            const ltfuUssd = new LtfuUssdDto();
            ltfuUssd.mapToDto(p);
            this.ltfuUssdArray.push(ltfuUssd);
          });
        }
        // arrange the USSD by Latest sent
        GeneralUtil.arraySorter(this.ltfuUssdArray, 'timeStamp', 'normal');

        // remove timezone from ussd timestamps
        this.ltfuUssdArray.forEach(ussd => {
          ussd.timeStamp = GeneralUtil.removeTimezoneFromDateString(ussd.timeStamp);
        });
        return this.ltfuUssdArray;
      },
        (error) => {
          this.showPreloader = false;
          this.errorHandler(error);
        });
  }

  getFacilitiesForFacilityAdmin() {
    this.facilities = [];
    this.locationService.getFacilitiesForFacilityAdmin()
      .subscribe(data => {
        const facilityArray = data;
        if (facilityArray.length > 0) {
          facilityArray.forEach(f => {
            const facil = new Facility();
            facil.mapToFacility(f);
            this.facilities.push(facil);
          });
        }

      }, error1 => {
        this.errorHandler(error1);
      })
  }

  exportToCsv() {
    this.exporting = true;
    setTimeout(() => {
      GeneralUtil.export2csv('ltfu-ussd-report.csv');
    }, 200);
    setTimeout(() => {
      this.exporting = false;
    }, 300);
  }


  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }
}
