export enum PatientType {
  PRESUMPTIVE = 'presumptive',
  IN_CARE = 'inCare',
  LOST_TO_FOLLOWUP = 'ltfu'
}


export enum PatientTypes {
  PRESUMPTIVE = 'PROSPECTIVE_PATIENT',
  IN_CARE = 'IN_CARE',
  LOST_TO_FOLLOWUP = 'LOST_TO_FOLLOWUP'
}