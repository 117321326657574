<div class="main-content">

  <div class="row g-0 align-items-center bg-white">
    <div class="col-lg-7">
      <div class="bg-white cover-fit d-flex justify-content-center align-items-center px-4 py-5 p-sm-5">
        <img src="assets/images/svg/404-error.svg" alt="Sign Up Cover" class="mt-4 mt-md-0 dark-mode-image">
      </div>
    </div>
    <div class="col-lg-5">
      <div class="px-3 py-4 p-md-5 p-xxl-5 mx-xxl-4">
        <div class="login-form py-2 py-md-0 mx-auto mx-lg-0">
          <h2 class="h1 mb-3 pb-md-1">404</h2>
          <h3 class="mb-3 pb-md-1">Page not found!</h3>
          <form>
            <p class="text-gray-700 pb-md-4 mb-4">Oops, The page you’re looking for does not exist.</p>
            <div class="d-grid pb-3">
              <button [routerLink]="['/dashboard']" type="button" class="btn btn-xl btn-ddanger">Back home</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</div>
