import { Router } from '@angular/router';
import { PatientStageEnum } from 'src/app/constant/patient-stage-enum';
import { PatientService } from 'src/app/service/patient.service';
import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { GeneralUtil } from 'src/app/util/general-util';
 

declare function failureToast(msg): any;
declare function successToast(msg): any;

@Component({
  selector: 'app-patient-template-list',
  templateUrl: './patient-template-list.component.html',
  styleUrls: ['./patient-template-list.component.css'],
})
export class PatientTemplateListComponent implements OnInit {
  @ViewChild('modal') public modal: ElementRef;
  @ViewChild('downloadLink') public downloadLink: ElementRef;
  public selectedTemplate = '';
  public excelLink = '';
  public selectedFile;
  public link = false;
  public showPreloader = false;
  public patientList = [
    // { id: 1,name: 'Presumptive Patients' },
    { id: 2, name: 'In-care Patients' },
    { id: 3, name: 'Lost To Follow Up Patients' },
  ];
  constructor(public patientService: PatientService, public router: Router) {}

  ngOnInit(): void {}
  changeTemplate() {
    this.link = true;
    if (this.selectedTemplate) {
      let line_list = localStorage.getItem('POP-MESSAGE');
      if (line_list == 'OK') {
        return;
      } else {
        this.modal.nativeElement.click();
      }
    }
  }

  popMethod(input) {
    if (input.control.value) {
      localStorage.setItem('POP-MESSAGE', 'OK');
    }
  }
  showListDownload() {
    this.downloadLink.nativeElement.click();
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.selectedFile = file;
    }
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  submitForm() {
    if (this.selectedFile == '') {
      return failureToast('Please choose a file');
    }
    switch (this.selectedTemplate) {
      case '1':
        this.selectedTemplate = PatientStageEnum.PRESUMPTIVE;
        break;
      case '2':
        this.selectedTemplate = PatientStageEnum.IN_CARE;
        break;
      case '3':
        this.selectedTemplate = PatientStageEnum.LOST_TO_FOLLOWUP;
        break;
    }
    this.showPreloader = true;
    const formData = new FormData();
    formData.append('file', this.selectedFile);
    formData.append('patientCategory', this.selectedTemplate);
    this.patientService
      .uploadLineList(formData, this.selectedTemplate)
      .subscribe(
        (data) => {
          setTimeout(() => {
            this.showPreloader = false;
            successToast('Patient list uploaded successfully');
            this.reloadComponent();
          }, 500);
        },
        (error1) => {
          this.showPreloader = false;
          this.errorHandlerFileUpload(error1);
          setTimeout(() => {
          this.reloadComponent();
          },5000)
        }
      );
  }

  // Error handling for file upload
  public errorHandlerFileUpload(error) {
    // return GeneralUtil.errorHandler(error);
    failureToast('Sorry, file failed to upload due to poor network or incorrect format. Please verify and try again.')
  }
  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }
}
