import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './component/login/login.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { SideMenuComponent } from './component/tags/side-menu/side-menu.component';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './component/tags/header/header.component';
import { PresumptiveUssdReportsComponent } from './component/reports/presumptive-patients/presumptive-ussd-reports/presumptive-ussd-reports.component';
import { PresumptiveCumulativeReportsComponent } from './component/reports/presumptive-patients/presumptive-cumulative-reports/presumptive-cumulative-reports.component';
import { InCareCumulativeReportsComponent } from './component/reports/in-care-patients/in-care-cumulative-reports/in-care-cumulative-reports.component';
import { HTTP_INTERCEPTORS,HttpClientModule } from '@angular/common/http';
import { TokenInterceptorService } from './service/auth/token-interceptor.service';
import { AuthService } from './service/auth/auth.service';
import { AuthGuard } from './service/auth/auth.guard';
import { ErrorPageComponent } from './component/error-page/error-page.component';
import { InCareUssdReportsComponent } from './component/reports/in-care-patients/in-care-ussd-reports/in-care-ussd-reports.component';
import { LtfuUssdReportsComponent } from './component/reports/ltfu-patients/ltfu-ussd-reports/ltfu-ussd-reports.component';
import { LtfuCumulativeReportsComponent } from './component/reports/ltfu-patients/ltfu-cumulative-reports/ltfu-cumulative-reports.component';
import { SmsReportsComponent } from './component/reports/sms-reports/sms-reports.component';
import { StateLevelAdminComponent } from './component/setup/user-management/state-level-admin/state-level-admin.component';
import { NationalLevelAdminComponent } from './component/setup/user-management/national-level-admin/national-level-admin.component';
import { FacilityLevelAdminComponent } from './component/setup/user-management/facility-level-admin/facility-level-admin.component';
import { RoleManagementComponent } from './component/setup/role-management/role-management.component';
import { PatientComponent } from './component/patients/patient/patient.component';
import { PatientListComponent } from './component/patients/patient-list/patient-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChangeForgottenPasswordComponent } from './component/change-password/change-forgotten-password.component';
import { StatePresumptiveCumulativeReportsComponent } from './component/reports/presumptive-patients/state-presumptive-cumulative-reports/state-presumptive-cumulative-reports.component';
import { StatePresumptiveUssdReportsComponent } from './component/reports/presumptive-patients/state-presumptive-ussd-reports/state-presumptive-ussd-reports.component';
import { StateInCareCumulativeReportsComponent } from './component/reports/in-care-patients/state-in-care-cumulative-reports/state-in-care-cumulative-reports.component';
import { StateInCareUssdReportsComponent } from './component/reports/in-care-patients/state-in-care-ussd-reports/state-in-care-ussd-reports.component';
import { StateLtfuCumulativeReportsComponent } from './component/reports/ltfu-patients/state-ltfu-cumulative-reports/state-ltfu-cumulative-reports.component';
import { StateLtfuUssdReportsComponent } from './component/reports/ltfu-patients/state-ltfu-ussd-reports/state-ltfu-ussd-reports.component';
import { FacilityInCareCumulativeReportsComponent } from './component/reports/in-care-patients/facility-in-care-cumulative-reports/facility-in-care-cumulative-reports.component';
import { FacilityInCareUssdReportsComponent } from './component/reports/in-care-patients/facility-in-care-ussd-reports/facility-in-care-ussd-reports.component';
import { FacilityPresumptiveCumulativeReportsComponent } from './component/reports/presumptive-patients/facility-presumptive-cumulative-reports/facility-presumptive-cumulative-reports.component';
import { FacilityPresumptiveUssdReportsComponent } from './component/reports/presumptive-patients/facility-presumptive-ussd-reports/facility-presumptive-ussd-reports.component';
import { FacilityLtfuCumulativeReportsComponent } from './component/reports/ltfu-patients/facility-ltfu-cumulative-reports/facility-ltfu-cumulative-reports.component';
import { FacilityLtfuUssdReportsComponent } from './component/reports/ltfu-patients/facility-ltfu-ussd-reports/facility-ltfu-ussd-reports.component';
import { UssdReportsComponent } from './component/reports/ussd-reports/ussd-reports.component';
import { PatientListsComponent } from './component/patients/patient-lists/patient-lists.component';
import { CumulativeReportsComponent } from './component/reports/cumulative-reports/cumulative-reports.component';
import { PatientTemplateListComponent } from './component/setup/patient-template-list/patient-template-list.component';
import { HomeComponent } from './component/home/home.component';
import { CarouselComponent } from './component/carousel/carousel.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    SideMenuComponent,
    HeaderComponent,
    PresumptiveUssdReportsComponent,
    PresumptiveCumulativeReportsComponent,
    InCareCumulativeReportsComponent,
    ErrorPageComponent,
    InCareUssdReportsComponent,
    LtfuUssdReportsComponent,
    LtfuCumulativeReportsComponent,
    SmsReportsComponent,
    StateLevelAdminComponent,
    NationalLevelAdminComponent,
    FacilityLevelAdminComponent,
    RoleManagementComponent,
    PatientComponent,
    PatientListComponent,
    ChangeForgottenPasswordComponent,
    StatePresumptiveCumulativeReportsComponent,
    StatePresumptiveUssdReportsComponent,
    StateInCareCumulativeReportsComponent,
    StateInCareUssdReportsComponent,
    StateLtfuCumulativeReportsComponent,
    StateLtfuUssdReportsComponent,
    FacilityInCareCumulativeReportsComponent,
    FacilityInCareUssdReportsComponent,
    FacilityPresumptiveCumulativeReportsComponent,
    FacilityPresumptiveUssdReportsComponent,
    FacilityLtfuCumulativeReportsComponent,
    FacilityLtfuUssdReportsComponent,
    UssdReportsComponent,
    PatientListsComponent,
    CumulativeReportsComponent,
    PatientTemplateListComponent,
    HomeComponent,
    CarouselComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ChartsModule,
    NgbModule,BrowserAnimationsModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    AuthGuard,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
