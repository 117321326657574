import { AuthService } from './../../service/auth/auth.service';
import { Component,OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public date = new Date().getFullYear();
  public slides = [
    { src: "./assets/images/kncv-1.png" },
    { src: "./assets/images/kncv-2.png" },
    { src: "./assets/images/kncv-3.png" },
  ];
  constructor(public authService: AuthService) { }

  ngOnInit(): void {
  }

}
