import { Component, OnInit } from '@angular/core';
import {RoleService} from '../../../service/role.service';
import {RoleDto} from '../../../dto/role-dto';
import {GeneralUtil} from '../../../util/general-util';

@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.css']
})
export class RoleManagementComponent implements OnInit {

  public showPreloader = false;
  public roleArray: RoleDto[] = [];
  public updatedRole = new RoleDto();

  constructor(
    public roleService: RoleService
  ) { }

  ngOnInit(): void {
    this.getRoles();
  }

  getRoles() {
    this.showPreloader = true;
    this.roleService.getRoles()
      .subscribe((data) => {
          this.showPreloader = false;
          this.roleArray = [];
          const tempRoleArray = data;
          if (tempRoleArray !== null && tempRoleArray.length > 0) {
            tempRoleArray.forEach(p => {
              const role = new RoleDto();
              role.mapToDto(p);
              this.roleArray.push(role);
            });
          }
          return this.roleArray;
        },
        (error) => {
          this.showPreloader = false;
          this.errorHandler(error);
        });

  }

  toggleStatus(id) {
    this.showPreloader = true;
    this.roleService.toggleStatus(id)
      .subscribe((data) => {
          this.showPreloader = false;
          /* Get the new Role from Data response and map it to a role object */
          this.updatedRole = new RoleDto();
          this.updatedRole.mapToDto(data);
          /* find the index of that particular role from the role array
           and use it to replace that role */
          const index = this.roleArray.findIndex(role => role.id === this.updatedRole.id);
          this.roleArray[index] = this.updatedRole;
        },
        (error) => {
          this.showPreloader = false;
          this.errorHandler(error);
        });

  }

  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }

}
