<div class="background-image">
  <div class="content-body">
    <div class="container-fluid">
      <div class="row page-titles mx-0 my-0">
        <div class="col-sm-12 col-md-4 col-lg-4 p-md-0 ">
          <div class="d-flex welcome-text mt-4">
            <div class="img-container">
              <img src="./../../../assets/images/kncv-logo.png" alt="">
            </div>
            <div class="d-flex flex-column content mx-2 mt-4 align-self-center">
              <h2 class="text-center">KNCV Nigeria Tuberculosis Tracker</h2>
              <h5 class="text-center text mt-2">Keep track of patients' USSD records, monitor the
                follow
                up of patients with tuberculosis and visualise key statistical insights. </h5>
              <div class="d-flex justify-content-center mt-4">
                <button class="btn btn-danger px-4" routerLink="/login"
                  *ngIf="!authService.isLoggedIn()">Login</button>
                <button class="btn btn-danger px-4" routerLink="/dashboard"
                  *ngIf="authService.isLoggedIn()">Dashboard</button>
              </div>
            </div>
          </div>
        </div>
        <div class=" col-sm-12 col-md-8 col-lg-8 d-flex p-md-0 carousel">
          <div class="center-carousel">
            <app-carousel [slides]="slides"></app-carousel>
          </div>
        </div>

      </div>
    </div>
    <div class="footer">Copyright &copy; {{date}}
      <a href="https://kncvnigeria.org/">
        KNCV Nigeria
      </a> | Powered by <a href="http://seamhealthgroup.com/">
        Seamhealth
      </a>
    </div>
  </div>
</div>