import { Component, OnInit, ViewChild, ViewChildren, QueryList, ElementRef, } from '@angular/core';
import { UserDto } from './../../../../dto/user-dto';
import { GeneralUtil } from './../../../../util/general-util';
import { User } from './../../../../entity/user';
import { UserService } from './../../../../service/user.service';
import { RoleEnum } from './../../../../constant/role-enum';
import { Facility } from './../../../../entity/facility';
import { State } from './../../../../entity/state';
import { Role } from './../../../../entity/role';
import {LocationService} from '../../../../service/location.service';
import {GeneralEnum} from '../../../../constant/general-enum.enum';
import {PaginationDataDto} from '../../../../dto/pagination-data-dto';


declare function successToast(msg): any;
declare function failureToast(msg): any;

@Component({
  selector: 'app-state-admin',
  templateUrl: './state-level-admin.component.html',
  styleUrls: ['./state-level-admin.component.css']
})
export class StateLevelAdminComponent implements OnInit {
  public users: User[] = [];
  public viewUser = new User();
  public showPreloader = false;
  public facilities: Facility[] = [];
  public states: State[] = [];
  public userDto = new UserDto();
  public role = new Role();
  public selectedStates = [];
  public paginationData = new PaginationDataDto();  // pagination object
  public currentPage: number;
  public defaultLimit: number;

  @ViewChild('close') close: ElementRef;
  @ViewChild('closeModal') closeModal: ElementRef;
  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;

  constructor(public userService: UserService,
              public locationService: LocationService) { }

  ngOnInit(): void {
    this.initializePaginationData();
    this.getStateAdmin();
    this.getFacilities();
    this.getAllStates();
    this.getRoles();
  }

  initializePaginationData() {
    this.paginationData.currentPage = 1;
    this.paginationData.defaultLimit = GeneralEnum.paginationLimit;
    this.paginationData.totalSize = 100;
  }

  removeUnderscore(mystring: string): string {
    return GeneralUtil.removeUnderscore(mystring);
  }

  numbersOnly(event){
    return GeneralUtil.numbersOnly(event);
  }

  getFacilities() {
    this.facilities = [];
    this.locationService.getAllFacilities()
      .subscribe(data => {
        const facilityArray = data;
        if (facilityArray.length > 0) {
          facilityArray.forEach(f => {
            const fac = new Facility();
            fac.mapToFacility(f);
            this.facilities.push(fac);
          });
        }
      }, error1 => {
        this.errorHandler(error1);
      });
  }

  getAllStates() {
    this.states = [];
    this.showPreloader = true;
    this.locationService.getActiveStates()
      .subscribe(data => {
        this.showPreloader = false;
        const stateArray = data;
        if (stateArray.length > 0) {
          stateArray.forEach(s => {
            const sta = new State();
            sta.mapToState(s);
            this.states.push(sta);
          });
        }
      }, error1 => {
        this.showPreloader = false;
        this.errorHandler(error1);
      });
  }

  getStateAdmin() {
    this.defaultLimit = this.paginationData.defaultLimit;
    this.currentPage = this.paginationData.currentPage;
    this.showPreloader = true;
    this.userService.getUsers(RoleEnum.STATE_LEVEL_ADMIN, (this.currentPage - 1), this.defaultLimit)
      .subscribe(data => {
        this.users = [];
        this.showPreloader = false;
        this.paginationData.totalSize = data.total;
        if (data.total > 0) {
        const stateUserArray = data.entities;
        stateUserArray.forEach(r => {
            const state = new User();
            state.mapToUser(r);
            this.users.push(state);
          });
        return this.users;
        }
      }, error1 => {
        this.errorHandler(error1);
      });
  }

  getRoles() {
    this.role = new Role();
    this.userService.getAllRoles()
      .subscribe(data => {
        const roleArray = data;
        if (roleArray.length > 0) {
          this.role = roleArray.find(role => {
            return role.name == RoleEnum.STATE_LEVEL_ADMIN;
          });
          this.userDto.roleId = this.role.id;
        }
      }, error1 => {
        this.errorHandler(error1);
      });
  }

  createUser() {
    if (this.userDto.stateIdsForStateAdmin.length < 1) {
      failureToast('At least one state is required');
    }
     else {
    this.showPreloader = true;
    this.userService.createUser(this.userDto)
      .subscribe(data => {
        successToast('User created successfully');
        setTimeout(() => {
          this.showPreloader = false;
          this.userDto = new UserDto();
          this.close.nativeElement.click();
          this.checkboxes.forEach((element) => {
            element.nativeElement.checked = false;
          });
          this.userDto.roleId = this.role.id;
          this.getStateAdmin();
        }, 1000);
      }, error1 => {
        this.showPreloader = false;
        this.errorHandler(error1);
      });
    }
  }

  // Edit user  details
  editUser(userId: number) {
    this.selectedStates = [];
    this.userService.getUserById(userId)
      .subscribe(data => {
        this.viewUser.mapToUser(data);
        // get states that are checked
        this.viewUser.stateIdsForStateAdmin.forEach(s => {
          if (!this.selectedStates.includes(s.id)) {
            this.selectedStates.push(s.id);
          }
        });
        this.states.forEach(state => this.viewUser.stateIdsForStateAdmin.forEach(s => {
          if (s.id === state.id) {
            state.selected = true;
          }
        }));
      }, error1 => {
        this.errorHandler(error1);
      });
  }


  // Update user information
  updateUser() {
    if (this.viewUser.stateIdsForStateAdmin.length < 1) {
      failureToast('At least one state is required');
    } else {
      this.showPreloader = true;
      this.viewUser.stateIdsForStateAdmin = this.selectedStates;
      this.userService.updateUser(this.viewUser)
        .subscribe(data => {
          successToast('User updated successfully');
          setTimeout(() => {
            this.showPreloader = false;
            this.closeModal.nativeElement.click();
            this.getStateAdmin();
          }, 1000);

        }, error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
    }
  }

  onCheck(event) {
    // selected checkbox
    if (event.checked) {
      if (this.userDto.stateIdsForStateAdmin.includes(Number(event.value)) === false) {
        this.userDto.stateIdsForStateAdmin.push(Number(event.value));
      }
      // unselected checkbox
    } else {
      this.userDto.stateIdsForStateAdmin.forEach(s => {
        if (s == event.value) {
          const index = this.userDto.stateIdsForStateAdmin.indexOf(s);
          if (index > -1) {
            this.userDto.stateIdsForStateAdmin.splice(index, 1);
          }
        }
      });
    }
  }


  onCheckBoxChange(event, state) {
    // selected checkboxes
    if (event.checked) {
      state.selected = true;
      if (this.selectedStates.includes(Number(event.value)) === false) {
        this.selectedStates.push(Number(event.value));
      }
      // unselected checkboxes
    } else {
      state.selected = false;
      this.selectedStates.forEach(s => {
        if (s == event.value) {
          const index = this.selectedStates.indexOf(s);
          if (index > -1) {
            this.selectedStates.splice(index, 1);
          }

        }
      });
    }
 }

  getSingleUser(userId: number) {
    this.selectedStates = [];
    this.userService.getUserById(userId)
      .subscribe(data => {
        this.viewUser = new User();
        setTimeout(() => {
          this.viewUser.mapToUser(data);
        }, 1000);
      }, error1 => {
        this.errorHandler(error1);
      });
  }

  toggleUserStatus(id, status: string) {
    this.showPreloader = true;
    if (status == 'ACTIVE') {
      status = 'INACTIVE';
    } else {
      status = 'ACTIVE';
    }
    this.userService.toggleUserStatus(id, status)
      .subscribe(data => {
        this.showPreloader = false;
        successToast('User status updated successfully');
        this.getStateAdmin();
      },
        error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
  }


 // Error handling
  errorHandler(error) {
    return GeneralUtil.errorHandler(error);
  }
}
