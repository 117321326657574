<div class="bg-ddanger signup-header text-center">
  <img src="assets/images/svg/login-kncv-logo.svg" alt="img">
</div>
<div class="container">
  <div class="simple-login-form rounded-12 shadow-dark-80 bg-white">
    <h2 class="mb-3">Reset Your Password</h2>
    <form #ChangePassword="ngForm" (ngSubmit)="changePassword()" class="pt-3" novalidate>
      <div class="mb-4 pb-md-2">
        <label class="form-label form-label-lg" for="Email">Your Email</label>
        <input [(ngModel)]="forgotPasswordDto.username" class="form-control form-control-xl" id="Email" name="email"
               placeholder="Email" type="email">
      </div>
      <div class="mb-4 pb-md-2">
        <label class="form-label form-label-lg" for="Password">New Password</label>
        <input [(ngModel)]="forgotPasswordDto.newPassword" class="form-control form-control-xl" id="Password" name="password"
               placeholder="••••••••••••••••" type="password" required>
      </div>
      <div class="d-grid">
        <button *ngIf="!showPreloader" [disabled]="ChangePassword.form.invalid" class="btn btn-xl btn-ddanger" type="submit">
          Reset Password
        </button>
        <button *ngIf="showPreloader" class="btn btn-xl btn-ddanger" disabled type="button">
          <span aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"></span>
          Loading...
        </button>
      </div>
    </form>
  </div>
</div>

