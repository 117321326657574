import { Component,Input,OnInit } from '@angular/core';
import { trigger,transition,useAnimation } from "@angular/animations";
import { scaleIn,scaleOut } from './carousel.animations'

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  animations: [
    trigger("carouselAnimation",[
      /* scale */
      transition("void => *",[useAnimation(scaleIn,{ params: { time: '500ms' } })]),
      transition("* => void",[useAnimation(scaleOut,{ params: { time: '500ms' } })]),
    ])
  ]
})
export class CarouselComponent implements OnInit {
  @Input() slides;
  constructor() { }
  timerObj;
  ngOnInit(): void {
    this.automateImage();
  }
  ngOndestroy() {
    clearInterval(this.timerObj);
  }
  currentSlide = 0;

  automateImage() {
    this.timerObj = setInterval(() => {
      const next = this.currentSlide + 1;
      this.currentSlide = next === this.slides.length ? 0 : next;
    },5000)
  }
  onPreviousClick() {
    clearInterval(this.timerObj);
    this.automateImage();
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
  }

  onNextClick() {
    clearInterval(this.timerObj);
    this.automateImage();
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
  }
}
